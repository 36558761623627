import React, {createRef} from "react";

export class Unshrinkable extends React.Component {
  state = {
    minWidth: 0,
    minHeight: 0,
  };

  static defaultProps = {
    width: true,
    height: false,
  };

  constructor() {
    super();

    this.el = createRef();
  }

  componentDidMount() {
    const el = this.el.current;
    if (window.ResizeObserver && el) {
      const resizeObserver = new ResizeObserver(entries => {
        // Wrap in requestAnimationFrame to avoid "ResizeObserver loop limit exceeded" errors as suggested in:
        // https://stackoverflow.com/a/58701523
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          this.preventShrinking();
        });
      });
      resizeObserver.observe(el);
      this.resizeObserver = resizeObserver;
    }

    this.preventShrinking();
  }

  componentWillUnmount() {
    const resizeObserver = this.resizeObserver;
    if (resizeObserver) {
      resizeObserver.disconnect();
    }
  }

  componentDidUpdate() {
    this.preventShrinking();
  }

  preventShrinking() {
    const {width, height} = this.props;
    const {minWidth, minHeight} = this.state;

    const el = this.el.current;
    if (!el) {
      return;
    }

    const {width: currentWidth, height: currentHeight} = el.getBoundingClientRect();
    if (width && currentWidth > minWidth || height && currentHeight > minHeight) {
      this.setState(({minWidth, minHeight}) => ({
        minWidth: Math.max(minWidth, width ? currentWidth : 0),
        minHeight: Math.max(minHeight, height ? currentHeight : 0),
      }));
    }
  }

  render() {
    const {children} = this.props;
    const {minWidth, minHeight} = this.state;

    return (
      <span
        ref={this.el}
        style={{display: 'inline-block', minWidth, minHeight}}
      >
        {children}
      </span>
    );
  }
}
