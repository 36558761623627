import React from "react";
import {NaturalTime} from "../data/time";
import {Unshrinkable} from "../layout/helpers/Unshrinkable";
import {ReadableFileSize} from "../data/number";
import {DecryptedText} from "../data/text";
import {TextDocumentViewer} from "./viewer";
import BulkSelectCheckBox from "./bulk-select-checkbox-view";
import {DocumentRowMenu} from "./menu";
import DocumentDownloadLink from "./document-download-link-redux";
import DocumentStatus from "./document-status-redux";
import DocumentUploaderAvatar from "./document-uploader-avatar-redux";
import DocumentError from "./document-error-redux";
import DocumentProgress from "./document-progress-redux";
import DocumentDescription from "./document-description-redux";
import Skeleton from "@material-ui/lab/Skeleton";

export default class DocumentRow extends React.Component {
  render() {
    const {name, description, showSelect, uploadedAt, uploaderId, documentId, caseId, plaintextSize, isRead, inProgress, isError, isDeleted, cancel, toggle, isSelected, loadingFailed} = this.props;

    if (isDeleted) {
      return null;
    }

    if (documentId === null) {
      return (
        <tr className="show-avatar-labels-on-hover user-badge-outer user-badge-outer-sm" height={40}>
          <td colSpan={7}>
            <Skeleton animation={loadingFailed ? false : undefined}/>
          </td>
        </tr>
      );
    }

    return (
      <tr
        className="show-avatar-labels-on-hover user-badge-outer user-badge-outer-sm"
      >
        {(isSelected || showSelect) ? (
          <td style={{padding: 0}}>
            <BulkSelectCheckBox isSelected={isSelected} onSelect={toggle} onUnselect={toggle}/>
          </td>
        ) : (
          <td className="hoverable test" onClick={toggle} style={{paddingRight: '0px'}}>
            <DocumentUploaderAvatar userId={uploaderId} showText={!isRead} light={isRead}/>
          </td>
        )}
        {(!inProgress || isError) ? (
          <>
            <td className="hoverable" style={{
              wordBreak: 'break-all',
            }} data-text={name} onClick={toggle}>
              <div
                className="visible-xs-inline visible-sm visible-md visible-lg pull-sm-left pull-md-left pull-lg-left"
                style={{width: '100%'}}
              >
                <DocumentDownloadLink {...this.props}/><> </>
                <DocumentDescription {...this.props}/>
                <DocumentError {...this.props} onClick={evt => evt.stopPropagation()}/>
              </div>
            </td>

            <td className="nowrap besides-badge" onClick={toggle}>
              <DocumentStatus documentId={documentId}/>
            </td>
          </>
        ) : (
          <td colSpan="2" className="besides-badge" style={{position: 'relative'}} onClick={toggle}>
            <div>
              <DocumentProgress documentId={documentId} name={name}/>
            </div>
            {description !== undefined ? (
              <>
                <br/>
                <div style={{marginTop: 2, marginBottom: -1}}>
                  <small><DecryptedText text={description}/></small>
                </div>
              </>
            ) : null}
          </td>
        )}

        <td className="nowrap besides-badge hidden-xs" onClick={toggle}>
          <Unshrinkable>
            {isRead ? (
              <ReadableFileSize value={plaintextSize}/>
            ) : (
              <strong><ReadableFileSize value={plaintextSize}/></strong>
            )}
          </Unshrinkable>
        </td>

        <td className="nowrap hidden-xs besides-badge" onClick={toggle}>
          <Unshrinkable>
            {uploadedAt === undefined ? (
              <>-</>
            ) : isRead ? (
              <NaturalTime date={uploadedAt}/>
            ) : (
              <strong><NaturalTime date={uploadedAt}/></strong>
            )}
          </Unshrinkable>
        </td>

        <td className="visible-xs" colSpan={2}/>

        <td style={{padding: 5}} className="text-right nowrap besides-badge">
          {cancel ? (
            <button type="button" className="btn btn-sm btn-danger" onClick={cancel}>
              <span className="glyphicon glyphicon-remove" style={{margin: '-2px'}}/>
            </button>
          ) : (
            <DocumentRowMenu documentId={documentId} caseId={caseId} {...this.props}/>
          )}
          <TextDocumentViewer id={documentId} caseId={caseId}/>
        </td>
      </tr>
    );
  }
}
