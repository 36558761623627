import React from "react";
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faFile as farFaFile,
  faQuestionCircle as farFaQuestionCircle,
  faUser as faFarUser,
  faFrown,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import {
  faFile as fasFaFile,
  faChevronDown,
  faDownload,
  faFileAlt,
  faLockOpen,
  faPrint,
  faReply,
  faSave,
  faSpinner,
  faTimes,
  faUser as faFasUser,
  faUserTie,
  faQuestionCircle as fasFaQuestionCircle,
  faExclamationCircle,
  faInfoCircle,
  faUserFriends,
  faUserPlus,
  faUserMinus,
  faUsers,
  faUsersCog,
  faAddressBook,
  faEye,
  faCheck,
  faBan,
  faLowVision,
  faEnvelope,
  faEnvelopeOpenText,
  faMailBulk,
  faHome,
  faSignOutAlt,
  faPowerOff,
  faFolderOpen,
  faFolderPlus,
  faUserCog,
  faWrench,
  faKey,
  faShieldAlt,
  faBellSlash,
  faCheckDouble,
  faSyncAlt,
  faCaretDown,
  faChevronCircleDown,
} from '@fortawesome/free-solid-svg-icons';

// General icons.
library.add(
  faTimes,
  faChevronDown,
  farFaQuestionCircle,
  fasFaQuestionCircle,
  faExclamationCircle,
  faInfoCircle,
  faSpinner,
  faLockOpen,
  faFrown,
  faTrashAlt,
  faHome,
  faSignOutAlt,
  faPowerOff,
  faKey,
  faSyncAlt,
);

// User icons.
library.add(faFarUser, faFasUser, faUserTie, faUserFriends, faUserPlus, faUserMinus, faAddressBook, faUsers, faUsersCog, faUserCog);

// File-related.
library.add(farFaFile, fasFaFile, faReply, faPrint, faSave, faFileAlt, faDownload);

// Extras.
library.add(faEye, faLowVision, faCheck, faCheckDouble, faBan, faEnvelope, faEnvelopeOpenText, faMailBulk, faFolderOpen, faFolderPlus, faWrench, faShieldAlt, faBellSlash, faCaretDown, faChevronCircleDown);
