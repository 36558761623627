import BulkUploaderDocuments from "./bulk-uploader-documents-view";
import {connect} from "react-redux";
import {bulkListingAction, getListing} from "../../../features/ui/listing";
import {canUpload, isCaseEmpty} from "../../../features/entities/case";
import {isUploadBoxVisibleForCase} from "../../../features/ui/dropzone";
import {isWaitingForDrop} from "../../../features/ui/drag-and-drop";
import {
  addDocumentToSet,
  getDocumentIdsForSet,
  getDraftDocumentIdsForSet,
  getRecentlyUploadedIdsByCaseId,
  getSelectedDocumentIdsForListing
} from "../../../features/entities/document";
import {getProgress} from "../../../features/ui/progress";
import {createDocumentDraftsFromFiles} from "../../../events/document";
import $ from "jquery";

const mapStateToProps = (state, props) => {
  const listingId = 'document-drafts';

  const {currentPage, ordering, count, meta} = getListing(listingId)(state);
  const caseId = {...meta}.caseId || props.caseId;

  const isPermanentlyVisible = isUploadBoxVisibleForCase(caseId)(state);
  const isTemporarilyVisible = isWaitingForDrop(state);
  const isVisible = isPermanentlyVisible || isTemporarilyVisible;

  const recentlyUploadedIds = getRecentlyUploadedIdsByCaseId(caseId)(state);


  const selectedIds = getSelectedDocumentIdsForListing(listingId)(state);

  const isEverythingSelected = (selectedIds.length === count + recentlyUploadedIds.length);

  const bulkProgress = getProgress(bulkListingAction.type, listingId)(state);

  const bulkActionActiveOrRecent = bulkProgress.inProgress || bulkProgress.recent;
  const bulkSuccessful = bulkProgress.success;
  const bulkError = bulkProgress.error;
  const bulkProgressPercentage = bulkProgress.progress / bulkProgress.total;
  const bulkProgressSuccessful = bulkProgress.successful;
  const bulkProgressTotal = bulkProgress.total;

  const draftDocumentIds = getDraftDocumentIdsForSet('bulkUploader')(state);
  const documentIds = getDocumentIdsForSet('bulkUploader')(state);

  return {
    ...meta,
    draftDocumentIds,
    documentIds,
    recentlyUploadedIds,
    caseIsEmpty: isCaseEmpty(caseId)(state),
    canUpload: canUpload(caseId)(state),
    dropzoneIsVisible: isVisible,
    dropzoneIsPermanentlyVisible: isPermanentlyVisible,
    ordering: ordering || [],
    currentPage,
    selectedIds,
    isEverythingSelected,
    bulkActionActiveOrRecent,
    bulkSuccessful,
    bulkError,
    bulkProgressPercentage,
    bulkProgressSuccessful,
    bulkProgressTotal,
  };
}

const mapDispatchToProps = dispatch => {
  const createUploadFilesAction = caseId => files => {
    return createDocumentDraftsFromFiles({files, caseId}, dispatch).then(draftIds => {
      for (const draftId of draftIds) {
        dispatch(addDocumentToSet({setId: 'bulkUploader', documentId: draftId}));
      }

      // Glue code (to be removed soon).
      try {
        $(document).find("[data-documentupload-data]").addBack("[data-documentupload-data]").each(function () {
          $(this).documentupload('fire', 'prepare');
        });
      } catch (e) {
        // Never mind.
      }
    });
  };

  return {
    createUploadFilesAction,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {caseId} = stateProps;
  const {createUploadFilesAction} = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    uploadFiles: createUploadFilesAction(caseId),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(BulkUploaderDocuments);
