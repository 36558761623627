import {put, select, takeEvery} from 'redux-saga/effects';
import {createSimpleAPICall} from "api";
import {fetchCase, getCase, needCase, setCase} from "./case-slice";
import {takeLatestDeep} from "../../../components/effects";

const watchFetchCase = createSimpleAPICall({
  apiName: 'retrieveCase',
  successAction: ({result}) => setCase(result),
});

function* watchNeedCase(action) {
  const {id} = action.payload;
  const myCase = (yield select(getCase(id)));
  if ((!myCase || !myCase.id) && id) {
    yield put(fetchCase({id}));
  }
}

export default function* caseSaga() {
  yield takeEvery(needCase, watchNeedCase);

  yield takeLatestDeep(
    fetchCase,
    action => action.payload.id,
    watchFetchCase,
  );
}
