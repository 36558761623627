import React from 'react';

import {connect} from "react-redux";
import {canUpload} from "../../features/entities/case";
import {hideUploadBox, isUploadBoxVisibleForCase} from "../../features/ui/dropzone";
import {createDocumentDraftsFromFiles} from "../../events/document";
import Dropzone from "./dropzone-view";

import {isWaitingForDrop} from "../../features/ui/drag-and-drop";

export default connect(
  (state, {caseId}) => {
    const userCanUpload = canUpload(caseId)(state);
    const isPermanentlyVisible = isUploadBoxVisibleForCase(caseId)(state);
    const isTemporarilyVisible = isWaitingForDrop(state);
    const isVisible = isPermanentlyVisible || isTemporarilyVisible;
    return {
      isVisible: isVisible && userCanUpload,
      isPermanentlyVisible: isPermanentlyVisible && userCanUpload,
    };
  },
  (dispatch, {caseId, uploadFiles}) => {
    if(uploadFiles === undefined) {
      uploadFiles = files => {
        createDocumentDraftsFromFiles({files, caseId, startUpload: true}, dispatch).then(() => {
          dispatch(hideUploadBox({caseId}));
        });
      }
    }

    return {
      hideDropzone: () => dispatch(hideUploadBox({caseId})),
      uploadFiles,
    };
  },
)(Dropzone);
