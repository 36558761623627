import React from "react";
import {connect} from "react-redux";
import {saveDocument} from "../../features/entities/document";
import DocumentDownloadButton from "./document-download-button-view";

const mapDispatchToProps = (dispatch, {documentId}) => ({
  save: () => dispatch(saveDocument({documentId})),
});

export default connect(
  null,
  mapDispatchToProps,
)(DocumentDownloadButton);
