import {uimanager} from "../ui-manager";
import $ from "jquery";
import {generateHumanReadablePassword} from "../../packages/e2ee/crypto/password-utils";
import {base64ToBytes, bytesToBase64} from "../../packages/binary-data-helpers";
import forge from "node-forge/lib/forge";
import {Parsley} from "../ui-validation";
import {MissingKeyError} from "./errors";
import {crypto_manager} from "./crypto-manager";

uimanager.add(function (el) {
  const $dom_element = $(el);

  // clear state if requested (e.g., during logout)
  if ($dom_element.find('[data-js-security-clear-keys]').addBack('[data-js-security-clear-keys]').length > 0)
    crypto_manager.clear();

  // Handle session key.
  $dom_element.find('body').each(function () {
    let session_key_id = $(this).attr('data-session-key-id');
    if (session_key_id) {
      // Initialize session key if necessary.
      if (!crypto_manager.has_key('session_key'))
        crypto_manager.add_random_key('session_key');

      // Copy session key to appropriate key id if it is not already present.
      if (!crypto_manager.has_key(session_key_id)) {
        crypto_manager.copy_key(session_key_id, 'session_key');
        crypto_manager.clear_generic_session_key();
      }
    }
  });

  // import keys from data attributes
  $dom_element.find('[data-js-security-wrapped-keys]').addBack('[data-js-security-wrapped-keys]').each(
    function () {
      const wrapped_keys = $.parseJSON($(this).attr('data-js-security-wrapped-keys'));
      crypto_manager.process_entity({wrapped_keys});
    });

  // add support for encrypted forms
  $dom_element.find('.js-security-form').addBack('.js-security-form').each(
    function () {
      if ($(this).hasClass('js-security-form-processed')) {
        console.warn("Already processed:", this);
        return;
      }

      const that = this;

      // find submit form
      const submit_form = $(this).find('form')[0];
      if (!submit_form)
        throw "Found js-security-form without embedded <form>";

      // specify helper function that realizes application of security functions
      const apply_security_function = function (js_security_data, field, is_submit) {
        // apply a security function to a value and return a value that is safe for submission

        let value = $(field).val();
        if ($(field).is(':checkbox'))
          value = $(field).is(':checked') ? 'on' : '';

        if ("filter" in js_security_data) {
          // apply filters only during submit so that they cannot destroy base64-encoded ciphertexts
          if (is_submit) {
            // apply filter function to value
            let args = js_security_data.filter;

            if (args['function'] === 'trim') {
              value = value.trim();
            } else if (args['function'] === 'lower') {
              value = value.toLowerCase();
            } else {
              throw "unsupported filter function: " + args['function'];
            }

            $(field).val(value).trigger('change');

            // do NOT return value, as filtering does not imply that it is safe for submit
            return undefined;
          }
        } else if ("substitute" in js_security_data) {
          // use value from another field
          const args = js_security_data.substitute;

          // apply function only during submit if submit_only is set
          if (!is_submit && args.submit_only)
            return undefined;

          // search for field and replace value
          value = $(that).find(':input#' + args['id'] + '[data-js-security]').val();
          if (value === undefined)
            throw "substitute value " + args['id'] + " is undefined";
          $(field).val(value).trigger('change');

          // do NOT return value, as substitute does not imply that it is safe for submit
          return undefined;
        } else if ("initialize_random_password" in js_security_data) {
          // initialize with a random password
          const args = js_security_data.initialize_random_password;

          // apply function only during submit if submit_only is set
          if (!is_submit && args.submit_only)
            return undefined;

          if (args.overwrite || !value) {
            // generate random password
            const random_password = generateHumanReadablePassword(args.entropy_bits, args.group_size);

            // assign password to field
            field.val(random_password).trigger('change');
          }

          // do NOT return it, since generated passwords are definitely not safe for submission!
          return undefined;
        } else if ("plaintext" in js_security_data) {
          // values of plaintext fields are safe for submission
          return value;
        } else if ("ignore" in js_security_data) {
          // values of ignore fields must not be submitted
          return '';
        } else if ("encrypted_key" in js_security_data) {
          // import key from crypto_manager and base64-encode it
          const args = js_security_data.encrypted_key;

          // access_key_id is either a string, undefined, or an {id: field_id} structure referring to the input field that contains the access key id
          let access_key_id = args.access_key_id;
          if (access_key_id === undefined || $.type(access_key_id) === 'string') {
            // nothing to do
          } else if ("id" in access_key_id) {
            // use value of field referenced by id
            const field = $(that).find('#' + access_key_id.id);
            if (field.length === 1) {
              access_key_id = field.val();
            } else
              throw "none or multiple matching fields for id " + access_key_id.id;
          } else
            throw "invalid access_key_id: " + access_key_id.id;

          if (access_key_id === undefined)
            return null;
          else
            return bytesToBase64(crypto_manager.wrap_key(args.key_id, access_key_id));
        } else if ("encrypted_string" in js_security_data) {
          // encrypt string and base64-encode it
          const args = js_security_data.encrypted_string;

          // propagate empty value
          if (args.propagate_empty)
            if (value === undefined || value === '')
              return '';

          return bytesToBase64(crypto_manager.encrypt_string(args.key_id, value));
        } else if ("key_graph" in js_security_data) {
          // key graph is safe for transmission
          value = JSON.stringify(crypto_manager.process_key_graph(JSON.parse(value)));
          return value;
        } else if ("pbkdf2" in js_security_data) {
          // derive key via pbkdf2
          const args = js_security_data.pbkdf2;

          // apply function only during submit if submit_only is set
          if (!is_submit && args.submit_only)
            return undefined;

          // propagate empty value
          if (args.propagate_empty && args.safe_for_submit && (value === undefined || value === ''))
            return '';

          // determine digest function
          let digest_function = undefined;
          if (args.digest_function === 'sha256')
            digest_function = forge.md.sha256.create();
          else
            throw "unsupported digest function: " + args.digest_function;

          // determine salt
          let salt = '';
          if (!$.isArray(args.salt))
            args.salt = [args.salt]; // treat any salt as list of salts that are concatenated
          for (let current_salt of args.salt) {
            if ($.type(current_salt) === 'string') {
              // strings are just appended to the salt
              salt += current_salt;
            } else if ("id" in current_salt) {
              // append value of field referenced by id
              const field = $(that).find('#' + current_salt.id);
              if (field.length === 1) {
                let field_value = field.val();
                if (current_salt.trim)
                  field_value = field_value.trim();
                if (current_salt.lower)
                  field_value = field_value.toLowerCase();
                salt += field_value;
              } else
                throw "none or multiple matching fields for id " + current_salt.id;
            } else
              throw "unsupported salt: " + current_salt;
          }

          // derive key
          const derived_key = forge.pkcs5.pbkdf2(value, salt, args.iterations, args.key_length, digest_function);

          // store key in crypto_manager
          if (args.key_id)
            crypto_manager.add_key(args.key_id, derived_key);

          // return base64-encoded representation of derived key only if explicitly marked safe for submission
          if (args.safe_for_submit)
            return bytesToBase64(derived_key);
        } else {
          throw ["unsupported data-js-security field", js_security_data];
        }
      };

      const handle_initial_form_data = function () {
        try {
          let js_security_data_list = $.parseJSON($(this).attr('data-js-security'));
          if (!$.isArray(js_security_data_list))
            js_security_data_list = [js_security_data_list];

          for (let js_security_data of js_security_data_list) {
            // realize reverse-application of security functions where possible
            if ("encrypted_key" in js_security_data) {
              const args = js_security_data.encrypted_key;

              let value = undefined;

              if (!args.writeonly) {
                // initial data is base64-encoded
                value = base64ToBytes($(this).val());

                // check whether we can decrypt the already-stored encrypted key
                try {
                  if (value) {
                    crypto_manager.add_encrypted_key(args.key_id, value, args.access_key_id);
                    value = crypto_manager.get_key(args.key_id);
                  }
                } catch (e) {
                  console.error(e);
                  value = undefined;
                }
              }

              // if initial data is empty or decryption failed, generate new key if allowed
              if (!value && args.generate) {
                crypto_manager.add_random_key(args.key_id);
              }

              // TODO: the remaining case might be an error we need to handle?
            } else if ("key_graph" in js_security_data) {
              // simply process key graph
              let key_graph = $.parseJSON($(this).val());
              key_graph = crypto_manager.process_key_graph(key_graph, true);
              $(this).val(JSON.stringify(key_graph));
            } else if ("substitute" in js_security_data) {
              // Substitution should only be performed on submit, not on initial form data.
            } else if ("encrypted_string" in js_security_data) {
              const args = js_security_data.encrypted_string;

              if (!args.writeonly) {
                let value = $(this).val();

                if (!value)
                  value = $(this).attr('data-value');

                // initial data that shall be decrypted is always base64-encoded
                if (value !== undefined && value !== '') {
                  let decrypted_value = null;
                  try {
                    let decoded_value = base64ToBytes(value);

                    // try to decrypt the stored value
                    try {
                      decrypted_value = crypto_manager.decrypt_string(args.key_id, decoded_value, true);
                    } catch (e) {
                      console.error(e);
                    }
                  } catch (e) {
                    console.error(e);
                  }

                  // set value
                  if (decrypted_value !== null) {
                    $(this).val(decrypted_value).trigger('change');
                  } else if (args.allow_plaintext) {
                    $(this).val(value).trigger('change');
                  } else {
                    $(this).val('').trigger('change');
                  }
                }
              }
            } else {
              // clear initial values that do not have any meaning, e.g., server-provided values from pbkdf2 fields marked safe for submit
              if ("pbkdf2" in js_security_data) {
                const args = js_security_data.pbkdf2;

                if (args.safe_for_submit && $(this).val() === $(this).attr('data-value')) {
                  $(this).val('').trigger('change');
                }
              }

              // where reverse application is not possible, e.g., for pbkdf2, we fall back to "normal" processing to ensure that, e.g., keys derived via pbkdf2 from other fields are available
              apply_security_function(js_security_data, $(this), false);
            }
          }
        } catch (e) {
          console.error(e);
        }
      };

      // handle initial form data
      $(this).find('#id_key_graph:input[data-js-security]').each(handle_initial_form_data);  // key graph first
      $(this).find(':input[data-js-security]:not(#id_key_graph)').each(handle_initial_form_data); // remaining fields

      // add isnew validator
      Parsley
        .addValidator(
          'isnew',
          {
            validateString: function (value,
                                      forbidden_values) {
              let allowed = true;
              $(that).find(forbidden_values).find('.forbidden-value').each(
                function () {
                  if ($(this).text().toLowerCase() === value.toLowerCase())
                    allowed = false;
                });
              return allowed;
            },
            priority: 256
          }).addMessage('de', 'isnew', 'Dieser Wert ist nicht neu.');

      // add mavodb validator
      Parsley.addValidator('mavodbisnew',
        {
          validateString: function (value, database_and_column) {
            if (!database_and_column) {
              return true;
            }

            // database_and_column syntax:
            // database/column
            // OR
            // database/column/whitelist-selector

            database_and_column = database_and_column.split('/');
            const database = database_and_column[0];
            const column = database_and_column[1];

            // check if value exists in mavodb
            let allowed = true;
            if ($('[data-mavodb=' + database + ']').mavodb('has_column_value', column, value))
              allowed = false;

            // exclude whitelisted values
            const whitelist_selector = database_and_column[2];
            $('body').find(whitelist_selector).find('.whitelist-value').each(
              function () {
                if ($(this).text().toLowerCase() === value.toLowerCase())
                  allowed = true;
              });

            return allowed;
          },
          priority: 256
        }).addMessage('de', 'mavodbisnew', 'Dieser Wert ist nicht neu.');

      // perform immediate re-validation when there are error
      // messages provided by the server
      if ($(this).find('.form-group').hasClass('has-error'))
        Parsley.options.trigger = 'input';

      // bind parsley
      $(this).attr('data-parsley-validate', '1');
      const validator = $(this).parsley();

      // remove any error messages provided by the server
      // during validation
      validator.on('field:validate', function (formInstance) {
        const container = this.$element.closest('.form-group');
        if (!container.hasClass('parsley')) {
          container.find('.help-block').remove();
          container.removeClass('has-success');
          container.removeClass('has-error');
        }
      });

      // handle submit
      $(submit_form).submit(
        function (event) {
          let $form = $(this);
          if ($form.data('submitted') === true) {
            // Abort if we have already submitted.
            console.warn("Abort submit since we have already submitted.");
            return false;
          }

          try {
            // clean any security-related input fields that are inside the form first
            $(this).find(':input[data-js-security]').remove();

            // perform validation
            if (!$(that).attr('data-parsley-validate'))
              validator.destroy();
            else if (!validator.validate()) {
              console.log("Form validation failed.");
              event.stopImmediatePropagation();
              return false;
            }

            // take over data from security-related fields outside the form
            $(that).find(':input[data-js-security]').each(
              function () {
                let js_security_data_list = $.parseJSON($(this).attr('data-js-security'));
                if (!$.isArray(js_security_data_list))
                  js_security_data_list = [js_security_data_list];

                const name = $(this).attr('name');
                let value_safe_for_submit = undefined;

                for (let js_security_data_list_i in js_security_data_list) {
                  const js_security_data = js_security_data_list[js_security_data_list_i];

                  // apply security function in any case...
                  const result = apply_security_function(js_security_data, $(this), true);

                  // ...but use its output only if we have not determined a value that is safe for submission yet
                  if (value_safe_for_submit === undefined)
                    value_safe_for_submit = result;
                }

                // generate hidden input field in submit form, but do not overwrite already existing values
                if (!submit_form.querySelector('input[name="' + name + '"]'))
                  $(submit_form).append($('<input type="hidden" />').attr('name', name).val(value_safe_for_submit)).trigger('change');
              });
          } catch (e) {
            // prevent submit if an error occurs
            console.warn("An error occurred during form submission", e);
            event.preventDefault();
            event.stopImmediatePropagation();

            // re-throw error unless it is an error that we expect to happen
            if (!(e instanceof MissingKeyError))
              throw e;

            console.error(e);

            return false;
          }

          // Disable submit button.
          const submitButton = this.querySelector('button[type="submit"],input[type="submit"]');
          if (submitButton) {
            submitButton.disabled = true;
            submitButton.dataset.submitting = true;
          }

          // simulate submit to dummy form as to support autofill
          try {
            // ensure that Firefox sees the submit by simulating a submit of the virtual form
            $(that).find('.js-security-form-virtual-form').parent().submit();
            // ensure that Chrome sees the submit by removing the virtual form and replacing the history state
            $(that).find('.js-security-form-virtual-form').unwrap();
            history.replaceState({}, document.title, window.location.href);
          } catch (e) {
            console.warn('browser password manager convenience measures failed');
          }
        });

      // wrap private form data into form element (if desired) to allow autofill by browsers
      let $virtual_form = $('<form action="javascript:" method="post" class="inline"/>');
      $virtual_form.on('submit', function (evt) {
        console.log("prevent submission of virtual form");
        evt.preventDefault();
      });
      let $closestVirtualForm = $(this).find('.js-security-form-virtual-form');
      if ($closestVirtualForm.length === 0)
        $closestVirtualForm = $(this).next('.js-security-form-virtual-form');
      if ($closestVirtualForm.length !== 0)
        $closestVirtualForm.wrap($virtual_form);

      // ensure that Safari sees the login form by rendering it with a non-javascript action (which is replaced before actual submit)
      if (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && !navigator.userAgent.match('CriOS')) {
        let $virtualForm = $closestVirtualForm.parent();
        $virtualForm.attr('action', '#');
        $virtualForm.on('submit', function (evt) {
          $(this).attr('action', 'javascript:');
          evt.preventDefault();
          console.log("prevented submission of virtual form");
        });
      }

      // issue submit if user presses enter inside a form element
      $(this).find('input[data-js-security], select[data-js-security], button[data-js-security]').keypress(
        function (event) {
          if (event.which === 13) {
            $(submit_form).submit();
            return false;
          }
        });

      // add support for submit-button-specific form actions
      $(this).find('button[data-submit-action]').each(
        function () {
          $(this).click(
            function (evt) {
              $(submit_form).attr('action', $(this).attr('data-submit-action'));
              $(that).removeAttr('data-parsley-validate');
              $(that).find('#id_submit_url_changed').val(true).trigger('change');
              $(submit_form).submit();
            });
        });

      $(this).addClass('js-security-form-processed');
    });

  // add support for encrypted attributes at arbitrary locations (syntax:
  // {"attr_name": {"ciphertext": "...", "key_id": "..."}})
  $dom_element.find('[data-js-security-encrypted-attr]').addBack('[data-js-security-encrypted-attr]').each(
    function () {
      let data = $.parseJSON($(this).attr('data-js-security-encrypted-attr'));
      for (const [attr, value] of Object.entries(data)) {
        try {
          $(this).attr(attr, crypto_manager.process_entity({encrypted_string: value}));
        } catch (e) {
          console.error(e);
        }
      }
    });

  // add support for template attributes at arbitrary locations (syntax: {"attr_name": "Text with {data-attribute-value}."})
  $dom_element.find('[data-js-security-template-attr]').addBack('[data-js-security-template-attr]').each(
    function () {
      const data = $.parseJSON($(this).attr('data-js-security-template-attr'));
      for (let [attr, {template}] of Object.entries(data)) {
        // find substitutions
        let substitutions = {};
        const re = /{((data-[a-z-]+)((:[a-z0-9_-]+=[a-z0-9_.-]+)*))}/gi;
        let m;
        do {
          m = re.exec(template);
          if (m) {
            let attr_value = $(this).attr(m[2]);

            if (attr_value) {
              const re2 = /:([a-z0-9_-]+)=([a-z0-9_.-]+)/gi;
              let m2;
              do {
                m2 = re2.exec(m[3]);
                if (m2) {
                  attr_value = attr_value.replace(m2[1], m2[2]);
                }
              } while (m2);
            }

            substitutions[m[0]] = attr_value;
          }
        } while (m);
        for (let pattern in substitutions) {
          const repl = substitutions[pattern];
          if (repl)
            template = template.replace(new RegExp(pattern, 'g'), substitutions[pattern] || '');
          else
            template = template.replace(new RegExp(pattern + ' ?', 'g'), '');
        }
        template = template.trim();

        try {
          $(this).attr(attr, template);
        } catch (e) {
          console.error(e);
        }
      }
    });
});
