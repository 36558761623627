import $ from 'jquery';

(function($) {
	//adapted from https://stackoverflow.com/q/499126
	$.fn.extend({
		setCursorPosition : function(pos) {
			return this.each(function() {
				try {
					if(this.setSelectionRange) {
						this.setSelectionRange(pos, pos);
					} else if(this.createTextRange) {
						var range = this.createTextRange();
						range.collapse(true);
						if(pos < 0) {
							pos = $(this).val().length + pos;
						}
						range.moveEnd('character', pos);
						range.moveStart('character', pos);
						range.select();
					}
				} catch(e) {
					// firefox, e.g., raises an exception if the element is not visible, so we fail silently in that case
					if(e.name == 'NS_ERROR_FAILURE')
						console.error(e);
					else
						throw e;
				}
			});
		}
	});
})($);