import React from "react";
import {UserAvatarWithTooltip} from "../user/Avatar";


export default class DocumentUploaderAvatar extends UserAvatarWithTooltip {
  getTooltip() {
    const {userId, gender, email, title, salutation, is_staff, readableId, is_viewer} = this.props;
    if (!userId) {
      return "Der Nutzer, der dieses Dokument hochgeladen hat, existiert nicht mehr.";
    }

    if (is_viewer) {
      return "Das Dokument wurde von mir selbst hochgeladen.";
    }

    let userSalutation = salutation;
    if (title) {
      userSalutation = title + ' ' + salutation;
    }
    switch (gender) {
      case 'm':
        userSalutation = 'Herrn ' + userSalutation;
        break;
      case 'f':
        userSalutation = 'Frau ' + userSalutation;
        break;
    }
    let userLabel = userSalutation ? (userSalutation + (email ? ' (' + email + ')' : '')) : email;

    if (userLabel && is_staff) {
      return "Das Dokument wurde " + (gender === 'f' ? "von der Mitarbeiterin" : "vom Mitarbeiter") + " " + userLabel + " hochgeladen.";
    } else if (userLabel) {
      return "Das Dokument wurde von " + userLabel + " hochgeladen.";
    } else if (is_staff) {
      return "Das Dokument wurde von " + (gender === 'f' ? "Mitarbeiterin" : "Mitarbeiter") + " #" + readableId + " hochgeladen.";
    } else if (readableId) {
      return "Das Dokument wurde von Nutzer #" + readableId + " hochgeladen.";
    } else {
      return "";
    }
  }
}
