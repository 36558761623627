import {combineReducers} from "@reduxjs/toolkit";
import mimeTypesReducer from "./mime-types";
import orderReducer from "./order";

export default {
  extras: combineReducers({
    ...mimeTypesReducer,
    ...orderReducer,
  }),
};
