import React from "react";

export default ({href, onClick, glyphicon, icon, children, action, ...props}) => (
  <li onClick={onClick} data-document-action={action}>
    <a className="menuItem" href={href} {...props}>
      {glyphicon && <><span className={"glyphicon glyphicon-" + glyphicon} aria-hidden="true"/>&nbsp;</>}
      {icon ? <>{icon}<> </>
      </> : null}
      <span>{children}</span>
    </a>
  </li>
);
