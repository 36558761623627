import {put, spawn, take} from 'redux-saga/effects';
import {requireConfirmation, confirm, cancel} from "./confirmation-slice";

function* handleModalConfirmations() {
  while (true) {
    const action = yield take([requireConfirmation]);
    const {confirmAction, cancelAction} = action.payload;

    const {type} = yield take([confirm, cancel]);
    if (type === confirm.type) {
      if (confirmAction) {
        yield put(confirmAction);
      }
    } else if (type === cancel.type) {
      if (cancelAction) {
        yield put(cancelAction);
      }
    } else {
      throw new Error(`unexpected action type: ${type}`);
    }
  }
}

export default function* confirmationSaga() {
  yield spawn(handleModalConfirmations);
}
