import documentReducers from './document-slice';

export default {
  ...documentReducers,
};

export * from './document-sagas';
export * from './document-slice';

export {default as saga} from './document-sagas';
