import {createAction, createSelector, createSlice} from "@reduxjs/toolkit";
import {getSessionUser} from "../../session";
import uuidv4 from 'uuid/v4';
import {renameProperty} from '../../../utils/rename-property';
import {getSelectedIdsByListingId, getVisibleIdsForListing} from "../../ui/listing";
import {getEntities} from "../entities-selectors";

import memoize from "lodash.memoize";

const documentSlice = createSlice({
  name: 'document',
  initialState: {
    blobUrlsById: {},
    byId: {},
    contentsById: {},
    deletedIds: [],
    documentSetsById: {},
    draftIdsByCaseId: {},
    draftsById: {},
    recentlyUploadedIds: [],
  },
  reducers: {
    clearDocumentSet(state, action) {
      const {setId} = action.payload;
      state.documentSetsById[setId] = [];
    },
    addDocumentToSet(state, action) {
      const {setId, documentId} = action.payload;
      if (state.documentSetsById[setId] === undefined) {
        state.documentSetsById[setId] = [];
      }
      state.documentSetsById[setId].push(documentId);
    },
    setDocument(state, action) {
      const {id, ...other} = action.payload;
      state.byId[id] = {id, ...other};
    },
    setDocuments(state, action) {
      const documents = action.payload || [];
      for (const {id, ...other} of documents) {
        state.byId[id] = {id, ...other};
      }
    },
    updateDocument(state, action) {
      const {id, ...other} = action.payload;
      const document = state.byId[id];
      Object.assign(document, other);
    },
    setContent(state, action) {
      const {id, ...other} = action.payload;
      state.contentsById[id] = {id, ...state.contentsById[id], ...other};
    },
    setBlobUrl(state, action) {
      const {documentId, blobUrl} = action.payload;
      state.blobUrlsById[documentId] = blobUrl;
    },
    clearContent(state, action) {
      const {id, ...other} = action.payload;
      delete state.contentsById[id];
    },
    deletedDocument(state, action) {
      const {documentId, caseId, ...other} = action.payload;
      delete state.byId[documentId];
      delete state.contentsById[documentId];
      delete state.blobUrlsById[documentId];
      state.deletedIds.push(documentId);

      // TODO: solve this via selector instead
      state.recentlyUploadedIds = state.recentlyUploadedIds.filter(exId => exId !== documentId);
    },
    createDocumentDraft: {
      reducer(state, action) {
        const {documentId} = action.payload;
        state.draftsById[documentId] = {
          ...state.draftsById[documentId],
        };
      },
      prepare(documentId = undefined) {
        if (documentId === undefined) {
          documentId = 'draft/' + uuidv4();
        }

        return {
          payload: {
            documentId,
          }
        }
      },
    },
    modifyDocumentDraft(state, action) {
      const {documentId, ...other} = action.payload;

      const previousDraft = state.draftsById[documentId];
      const newDraft = {...previousDraft, ...other};
      state.draftsById[documentId] = newDraft;

      const oldCase = previousDraft['case'], newCase = newDraft['case'];
      if (newCase !== oldCase) {
        if (oldCase !== undefined) {
          state.draftIdsByCaseId[oldCase] = state.draftIdsByCaseId[oldCase].filter(id => id !== documentId);
        }
        if (newCase) {
          if (state.draftIdsByCaseId[newCase] === undefined) {
            state.draftIdsByCaseId[newCase] = [];
          }
          state.draftIdsByCaseId[newCase] = [documentId, ...state.draftIdsByCaseId[newCase]];
        }
      }
    },
    deletedDocumentDraft(state, action) {
      const {documentId} = action.payload;
      const draft = state.draftsById[documentId];

      if (!draft) {
        return;
      }

      const oldCase = draft.case;
      delete state.draftsById[documentId];

      if (oldCase !== undefined) {
        state.draftIdsByCaseId[oldCase] = state.draftIdsByCaseId[oldCase].filter(id => id !== documentId);
      }
    },
    changeDocumentId(state, action) {
      const {documentId, newDocumentId} = action.payload;

      // Change draftIdsByCaseId.
      const draft = state.draftsById[documentId];
      if (draft) {
        const caseId = draft.case;
        if (caseId !== undefined) {
          state.draftIdsByCaseId[caseId] = state.draftIdsByCaseId[caseId].map(existingId =>
            existingId === documentId ? newDocumentId : existingId
          );
        }
      }

      // Change remaining mappings.
      [
        state.blobUrlsById,
        state.contentsById,
        state.draftsById,
      ].forEach(obj => {
        renameProperty(obj, documentId, newDocumentId);
      });

      // Change document sets.
      for (const documentSet of Object.values(state.documentSetsById)) {
        const index = documentSet.indexOf(documentId);
        if (index !== -1) {
          documentSet[index] = newDocumentId;
        }
      }
    },
    uploaded(state, action) {
      const {documentId} = action.payload;

      const draft = state.draftsById[documentId];
      if (draft && draft.case) {
        state.draftIdsByCaseId[draft.case] = state.draftIdsByCaseId[draft.case].filter(draftId => draftId !== documentId);
      }

      delete state.draftsById[documentId];

      state.recentlyUploadedIds = [documentId, ...state.recentlyUploadedIds];
    },
  },
});

export const {
  setDocument,
  setDocuments,
  updateDocument,
  setContent,
  setBlobUrl,
  clearContent,
  deletedDocument,
  createDocumentDraft,
  modifyDocumentDraft,
  deletedDocumentDraft,
  changeDocumentId,
  uploaded,
  addDocumentToSet,
  clearDocumentSet,
} = documentSlice.actions;

export const needDocument = createAction('document/need');
export const fetchDocument = createAction('document/fetch');

export const fetchPage = createAction('document/fetchPage');

export const setDocumentBlob = createAction('document/blob/set');

export const uploadDocument = createAction('document/upload');

export const saveDocument = createAction('document/save');

export const viewDocument = createAction('document/showView');
export const hideDocument = createAction('document/hideView');
export const prefetchDocument = createAction('document/prefetch');

export const markDocumentRead = createAction('document/markRead');
export const markDocumentUnread = createAction('document/markUnread');
export const modifyAndPersistDocument = createAction('document/modifyAndPersist');

export const needParsedDocument = createAction('document/needParsedDocument');
export const replyToDocument = createAction('document/reply');

export const displayed = createAction('document/displayed');

export const deleteDocument = createAction('document/delete');
export const deleteDocumentDraft = createAction('document/deleteDraft');

export const startUpload = createAction('document/acquireUploadSlot');
export const endUpload = createAction('document/releaseUploadSlot');

export default {
  documents: documentSlice.reducer,
};

export const getDocuments = createSelector(
  getEntities,
  entities => entities.documents,
);

export const getDocumentsById = createSelector(
  getDocuments,
  documents => documents.byId,
);

export const getDocumentDraftsById = createSelector(
  getDocuments,
  documents => documents.draftsById,
);

export const getDocumentDraftIdsByCaseId = createSelector(
  getDocuments,
  documents => documents.draftIdsByCaseId,
);

const getSetsById = createSelector(
  getDocuments,
  documents => documents.documentSetsById,
);

export const getRecentlyUploadedIds = createSelector(
  getDocuments,
  documents => documents.recentlyUploadedIds,
);

const defaultDocument = {};

export const getDocument = id => createSelector(
  getDocumentsById,
  documents => documents[id] || defaultDocument,
);

const defaultDocumentDraft = {};

export const getDocumentDraft = id => createSelector(
  getDocumentDraftsById,
  drafts => drafts[id] || defaultDocumentDraft,
);

export const getDocumentOrDraft = id => createSelector(
  getDocumentsById,
  getDocumentDraftsById,
  (documents, drafts) => documents[id] || drafts[id] || defaultDocumentDraft,
);

const defaultDraftDocumentIds = [];

export const getDraftDocumentIdsForCase = memoize(caseId => createSelector(
  getDocumentDraftIdsByCaseId,
  draftIdsByCaseId => draftIdsByCaseId[caseId] || defaultDraftDocumentIds,
));

export const getDraftDocumentIdsWithoutCase = createSelector(
  getDocumentDraftsById,
  documentDraftsById => Object.entries(documentDraftsById).filter(
    ([id, document]) => document.case === undefined
  ).map(([id, document]) => id),
);

export const getAllDraftDocumentIds = createSelector(
  getDocumentDraftsById,
  draftIdToDraft => Object.keys(draftIdToDraft),
)

export const getDocumentContentsById = state => state.entities.documents.contentsById;

export const getDocumentContent = id => createSelector(
  getDocumentContentsById,
  documentContents => ({...documentContents[id]}.content),
);

export const getDocumentBlobUrlsById = state => state.entities.documents.blobUrlsById;

export const getDocumentBlobUrl = id => createSelector(
  getDocumentBlobUrlsById,
  blobUrlsById => blobUrlsById[id],
);

export const shouldKeepDocumentContent = id => createSelector(
  getDocumentContentsById,
  documentContents => ({...documentContents[id]}.keep),
);

export const isDocumentRead = id => createSelector(
  getDocument(id),
  document => document.viewer_read,
);

export const isUploadedBySessionUser = id => createSelector(
  getDocument(id),
  getSessionUser,
  (document, sessionUserId) => document.uploader === sessionUserId,
);

export const canViewInline = id => createSelector(
  getDocument(id),
  document => document.type === 'text/plain' && document.size < 100 * 1024,
);

export const documentPlaintextSize = id => createSelector(
  getDocument(id),
  getDocumentDraft(id),
  (document, draft) => (document.size !== undefined) ? (
    Number.isInteger(document.size) ? Math.max(0, document.size - 28) : document.size
  ) : (
    draft.size
  ),
);

export const getDeletedDocumentIds = state => state.entities.documents.deletedIds;

export const isDocumentDeleted = id => createSelector(
  getDeletedDocumentIds,
  deletedIds => deletedIds.includes(id),
);

export const isJustUploaded = id => createSelector(
  getRecentlyUploadedIds,
  recentlyUploadedIds => recentlyUploadedIds.includes(id),
);

export const isReadyForUpload = id => createSelector(
  getDocument(id),
  getDocumentDraft(id),
  (document, documentDraft) => document.id === undefined && documentDraft.case !== undefined,
);

export const getRecentlyUploadedIdsByCaseId = memoize(caseId => createSelector(
  getRecentlyUploadedIds,
  getDocumentsById,
  (recentlyUploadedIds, documentById) => recentlyUploadedIds.filter(id => (documentById[id] || {}).case === caseId),
));

export const isUploadSuccessful = memoize(caseId => createSelector(
  getDraftDocumentIdsForCase(caseId),
  getRecentlyUploadedIdsByCaseId(caseId),
  (draftDocumentIds, recentlyUploadedIds) => draftDocumentIds.length === 0 && recentlyUploadedIds.length > 0,
));

export const getDocumentIdsForListing = memoize(listingId => createSelector(
  getVisibleIdsForListing(listingId),
  getDeletedDocumentIds,
  getRecentlyUploadedIds,
  (visibleIds, deletedIds, recentlyUploadedIds) => visibleIds.filter(id =>
    !deletedIds.includes(id) && !recentlyUploadedIds.includes(id)
  ),
));

export const getSelectedDocumentIdsForListing = memoize(listingId => createSelector(
  getSelectedIdsByListingId(listingId),
  getDeletedDocumentIds,
  (selectedIds, deletedIds) => selectedIds.filter(id =>
    !deletedIds.includes(id)
  ),
));

export const getFilteredSelectedDocumentIdsForListing = memoize(filterFunction => memoize(listingId => createSelector(
  getSelectedDocumentIdsForListing(listingId),
  getDocumentsById,
  (selectedIds, documentsById) => selectedIds.filter(id =>
    documentsById[id] && filterFunction(documentsById[id])
  ),
)));

export const getVisibleSelectedDocumentIdsForListing = memoize(listingId => createSelector(
  getSelectedDocumentIdsForListing(listingId),
  getVisibleIdsForListing(listingId),
  (selectedIds, visibleIds) => visibleIds.filter(id => selectedIds.includes(id)),
));

export const getDocumentIdsForSet = memoize(setId => createSelector(
  getSetsById,
  getDocumentsById,
  getDocumentDraftsById,
  (setsById, documentsById, draftsById) => (setsById[setId] || []).filter(id => documentsById[id] && !draftsById[id]),
));

export const getDocumentIdsForCaseAndSet = memoize(caseId => memoize(setId => createSelector(
  getDocumentIdsForSet(setId),
  getDocumentsById,
  (documentIds, documentById) => documentIds.filter(id => (documentById[id] || {}).case === caseId),
)));

export const getDraftDocumentIdsForSet = memoize(setId => createSelector(
  getSetsById,
  getDocumentDraftsById,
  (setsById, draftsById) => (setsById[setId] || []).filter(id => draftsById[id]),
));
