import {put, select, takeEvery, takeLeading} from 'redux-saga/effects';
import {createSimpleAPICall} from "api";
import {fetchMimeTypes, hasFetchedMimeTypes, needMimeTypes, setMimeTypes} from "./mime-types-slice";

const watchFetchMimeTypes = createSimpleAPICall({
  apiName: 'listMimeTypes',
  successAction: ({result}) => setMimeTypes(result),
  lookupField: null,
});

function* watchNeedMimeTypes(action) {
  if (!(yield select(hasFetchedMimeTypes))) {
    yield put(fetchMimeTypes({}));
  }
}

export default function* mimeTypesSaga() {
  yield takeEvery(needMimeTypes, watchNeedMimeTypes);

  yield takeLeading(
    fetchMimeTypes,
    watchFetchMimeTypes,
  );
}
