import {all} from 'redux-saga/effects';
import {saga as mimeTypesSaga} from './mime-types';
import {saga as orderSaga} from './order';

export default function* uiSaga() {
  yield all([
    mimeTypesSaga(),
    orderSaga(),
  ]);
}
