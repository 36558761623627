import React, {useRef} from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import {UserAvatarContainer} from "../../user/Avatar";
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NaturalTime} from "../../data/time";
import ReactToPrint from "react-to-print";
import {Tooltip} from "@material-ui/core";
import {Unshrinkable} from "../../layout/helpers/Unshrinkable";
import {DecryptedText} from "../../data/text";
import {Dialog} from "../../layout/modal/Dialog";
import {DialogTitle} from "../../layout/modal/DialogTitle";
import DocumentContent from "../document-content-redux";
import {DocumentError} from "../index";

const styles = theme => ({
  icon: {
    marginRight: theme.spacing(2),
    float: 'left',
    fontSize: '2.4em',
  },
  title: {
    color: theme.palette.primary.main,
  },
  subtitle: {
    whiteSpace: 'nowrap',
    float: 'left',
  },
  description: {
    margin: 0,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0),
  },
  content: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: 400,
  },
  printButton: {
    position: 'absolute',
    left: theme.spacing(1),
  }
});

export default withStyles(styles)(props => {
  const {
    documentId,
    classes,
    onClose, reply, onSave,
    documentUploaderId, documentError, documentContent,
    name, description, uploaded_at,
    open,
  } = props;

  const ref = useRef(null);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="text-document-dialog-title"
      ref={ref}
    >
      <DialogTitle disableTypography onClose={() => onClose()} className={classes.title}>
        <Typography variant="h5">
          <FontAwesomeIcon icon={['fas', 'file-alt']} className={classes.icon}/>
          <DecryptedText text={name}/>
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          <Unshrinkable>
            hochgeladen von <span className="user-badge-outer-sm"><UserAvatarContainer userId={documentUploaderId}/></span>
            {uploaded_at ? (<NaturalTime date={uploaded_at} inSentence={true}/>) : null}
          </Unshrinkable>
        </Typography>
      </DialogTitle>

      {description ? (
        <DialogTitle disableTypography className={classes.description}>
          <Typography variant="h6" data-document-element="description">
            <DecryptedText text={description}/>
          </Typography>
        </DialogTitle>
      ) : null}

      <DialogContent dividers className={classes.content}>
        {documentError ? (
          <DocumentError documentId={documentId}/>
        ) : (
          <DocumentContent documentId={documentId}/>
        )}
      </DialogContent>

      <DialogActions>
        <ReactToPrint
          trigger={() => (
            <Tooltip title={"Drucken"} arrow>
              <IconButton className={classes.printButton} disabled={!documentContent}>
                <FontAwesomeIcon icon={['fas', 'print']}/>
              </IconButton>
            </Tooltip>
          )}
          content={() => ref.current}
        />
        {reply ? (
          <Button
            onClick={reply}
            color="primary"
            startIcon={<FontAwesomeIcon icon={['fas', 'reply']}/>}
            disabled={!documentContent}
          >
            Antworten
          </Button>
        ) : null}
        {onSave ? (
          <Button
            variant="contained"
            onClick={onSave}
            color="primary"
            startIcon={<FontAwesomeIcon icon={['fas', 'download']}/>}
          >
            Herunterladen
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
});
