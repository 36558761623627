import {all} from 'redux-saga/effects';
import {saga as confirmationSaga} from './confirmation';
import {saga as helpSaga} from './help';
import {saga as listingSaga} from './listing';
import {saga as messageWriterSaga} from './message-writer';
import {saga as progressSaga} from './progress';
import {saga as unloadBlockerSaga} from './unload-blocker';

export default function* uiSaga() {
  yield all([
    confirmationSaga(),
    helpSaga(),
    listingSaga(),
    messageWriterSaga(),
    progressSaga(),
    unloadBlockerSaga(),
  ]);
}
