import React from "react";
import {connect} from "react-redux";
import {getURL} from "../../features/ui/navigation";
import {getDecrypted} from "../../features/e2ee";
import {getProgress} from "../../features/ui/progress";
import {
  canViewInline,
  getDocument,
  getDocumentBlobUrl,
  isDocumentRead,
  prefetchDocument,
  saveDocument,
  viewDocument
} from "../../features/entities/document";
import DocumentDownloadLink from "./document-download-link-view";

const mapStateToProps = (state, {documentId}) => {
  const document = getDecrypted(getDocument(documentId))(state);

  if (document.case === undefined) {
    return {};
  }

  return {
    blobUrl: getDocumentBlobUrl(documentId)(state),
    url: getURL('document', {case: document.case, document: documentId})(state),
    name: document.name,
    canViewInline: canViewInline(documentId)(state),
    decrypted: getProgress('decryptDocument', documentId)(state).success,
    saved: getProgress(saveDocument.type, documentId)(state).success,
    isRead: isDocumentRead(documentId)(state),
  };
};

const mapDispatchToProps = (dispatch, {documentId, caseId}) => ({
  save: () => dispatch(saveDocument({documentId, caseId})),
  view: () => dispatch(viewDocument({documentId, caseId})),
  prefetch: () => dispatch(prefetchDocument({documentId, caseId})),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {canViewInline} = stateProps;
  const {save, view, ...otherDispatchProps} = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,

    onClick: canViewInline ? view : save,

    ...otherDispatchProps,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DocumentDownloadLink);
