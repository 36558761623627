import {put, select, spawn, take} from 'redux-saga/effects';
import {addUnloadBlocker, hasUnloadBlocker, removeUnloadBlocker} from "./unload-blocker-slice";
import {endProgress, startProgress} from "../progress";

function* watchProgress() {
  while (true) {
    const action = yield take([startProgress, endProgress]);

    switch (action.type) {
      case startProgress.type:
        if (action.payload.blockUnload) {
          yield put(addUnloadBlocker(action.payload));
        }
        break;

      case endProgress.type:
        if (yield select(hasUnloadBlocker(action.payload))) {
          yield put(removeUnloadBlocker(action.payload));
        }
        break;
    }
  }
}

export default function* unloadBlockerSaga() {
  yield spawn(watchProgress);
}
