import {FAILED_TO_RECEIVE_HELP_TEXT, RECEIVE_HELP_TEXT, REQUEST_HELP_TEXT} from './help-constants';

export const helpText = (helpText = {}, action) => {
  const {type, data} = action;

  switch (type) {
    case REQUEST_HELP_TEXT:
      return {
        ...helpText,
        isFetching: true
      };

    case RECEIVE_HELP_TEXT:
      return {
        ...helpText,
        ...data,
        isFetching: false,
      };

    case FAILED_TO_RECEIVE_HELP_TEXT:
      return {
        ...helpText,
        isFetching: false
      };

    default:
      return helpText;
  }
};

export const helpTexts = (helpTexts = {}, action) => {
  switch (action.type) {
    case REQUEST_HELP_TEXT:
    case RECEIVE_HELP_TEXT:
    case FAILED_TO_RECEIVE_HELP_TEXT:
      return {
        ...helpTexts,
        [action.id]: helpText(helpTexts[action.id], action)
      };

    default:
      return helpTexts;
  }
};

// Export root reducers.
export default {
  helpTexts
};
