import BulkUploaderSummary from "./bulk-uploader-summary-view";
import {connect} from "react-redux";
import {getCase} from "../../../features/entities/case";
import {
  getDocumentIdsForCaseAndSet,
  getDraftDocumentIdsForSet,
  uploadDocument
} from "../../../features/entities/document";
import {getProgress} from "../../../features/ui/progress";
import {getView} from "../../../features/ui/view";
import {getDecrypted} from "../../../features/e2ee";

const mapStateToProps = (state, props) => {
  const {startedUpload, caseId} = getView('bulkUploader', 'main')(state);
  const {case_number: caseNumber} = getDecrypted(getCase(caseId))(state);
  const uploadedDocumentIds = getDocumentIdsForCaseAndSet(caseId)('bulkUploader')(state);


  const isWaiting = startedUpload && (caseNumber !== undefined);

  const draftDocumentIds = getDraftDocumentIdsForSet('bulkUploader')(state);
  const isComplete = (draftDocumentIds.length === 0) && startedUpload && uploadedDocumentIds.length > 0;

  let failedUploads = 0;
  for (const draftId of draftDocumentIds) {
    const progress = getProgress(uploadDocument.type, draftId);
    if (progress.error || progress.abort) {
      failedUploads += 1;
    }
  }

  const isFailed = (failedUploads > 0);
  const isInProgress = (draftDocumentIds.length > 0 && startedUpload && failedUploads < draftDocumentIds.length);

  return {
    caseNumber,
    isWaiting,
    isComplete,
    isInProgress,
    isFailed,
  };
}

export default connect(
  mapStateToProps,
)(BulkUploaderSummary);
