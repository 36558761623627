import React from "react";
import {createUploadFilesClickEvent} from "../upload-events";
import Dropzone from "../dropzone-view";
import DocumentsTable from "../documents-table-redux";

export default function BulkUploaderDocuments(
  {
    dropzoneIsVisible,
    uploadFiles,
    documentIds,
    draftDocumentIds,
    ...props
  }
) {
  return (
    <>
      <div className="panel panel-default" style={{
        marginTop: '5px',
        marginLeft: '0px',
        marginBottom: '5px',
        marginRight: '0px'
      }}>
        <DocumentsTable
          listingId='document-drafts'
          dropzone={null}
          disableOrdering
          emptyContent={<Dropzone isVisible={!dropzoneIsVisible} uploadFiles={uploadFiles}/>}
          {...props}
          documentIds={documentIds}
          draftDocumentIds={draftDocumentIds}
          recentlyUploadedIds={[]}
        />
        {dropzoneIsVisible ? (
          <table className="table nomargin">
            <Dropzone isVisible={dropzoneIsVisible} uploadFiles={uploadFiles}/>
          </table>
        ) : null}
      </div>
      {(!dropzoneIsVisible && (draftDocumentIds.length > 0 || documentIds.length > 0)) ? (
        <div className="text-right hidden-if-dragging dropzone" style={{marginTop: 0, marginRight: -4}}>
          <button className="btn btn-default btn-sd" onClick={createUploadFilesClickEvent(uploadFiles)}>
            Weitere Dateien vom Computer auswählen...
          </button>
          &nbsp;<p style={{color: 'rgb(162, 162, 162)', paddingTop: 5}}>
          <span>...oder einfach hier hineinziehen.</span></p>
        </div>
      ) : null}
    </>
  );
}
