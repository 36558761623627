import React from "react";

const Decrypting = () => "(entschlüsseln...)";
const DecryptionFailed = () => <span style={{color: 'red'}}>(Entschlüsselung fehlgeschlagen)</span>;
const Plaintext = ({text}) => text;

const decryptingComponent = <Decrypting/>;
const decryptionFailedComponent = <DecryptionFailed/>;

export default (
  {
    text,
    decrypting = decryptingComponent,
    decryptionFailed = decryptionFailedComponent,
    onRender = () => ({}),
  }
) => {
  if (text === undefined) {
    return decrypting;
  }

  if (Number.isNaN(text)) {
    return decryptionFailed;
  }

  onRender();

  return <Plaintext text={text}/>;
};
