import {getUI} from "../ui-selectors";
import {createSelector} from "@reduxjs/toolkit";

const getMessageWriters = createSelector(
  getUI,
  ui => ui.messageWriters,
);

const defaultMessageWriterData = {};

export const getMessageWriterData = id => createSelector(
  getMessageWriters,
  messageWriters => messageWriters[id] || defaultMessageWriterData,
);
