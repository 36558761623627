import React from "react";
import DocumentMenuItems from './document-menu-items-view';

export default class DocumentDetailsMenu extends React.Component {
  render() {
    return (
      <>
        <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{
          minWidth: '40px',
          marginLeft: '5px'
        }}>
          <span className="hidden-xs">Verwalten </span><span className="caret"/>
        </button>
        <ul className="dropdown-menu dropdown-menu-right">
          <DocumentMenuItems {...this.props}/>
        </ul>
      </>
    );
  }
}
