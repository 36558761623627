import {arrayBufferToForgeBuffer} from "../packages/binary-data-helpers";
import {createBuffer} from 'node-forge/lib/util';

export const ChunkedBlobReader = function (blob, chunkSize) {
  let blobSize = blob.size;
  chunkSize = chunkSize || 128 * 1024;
  let offset = 0;

  return {
    nextChunk() {
      let startOffset = offset;
      let endOffset = Math.min(offset + chunkSize, blobSize);
      offset = endOffset;

      return new Promise(function (resolve, reject) {
        // get chunk
        let chunkBlob = blob.slice(startOffset, endOffset);

        // read it
        let fileReader = new FileReader();
        fileReader.onload = function (evt) {
          // reject if there is an error
          if (evt.target.error !== null) {
            reject(evt.target.error);
            return;
          }

          let result = evt.target.result;
          if (result instanceof ArrayBuffer) {
            // convert ArrayBuffer to forge buffer
            let buffer = arrayBufferToForgeBuffer(result);
            if (buffer.length() !== endOffset - startOffset) {
              reject('unexpected buffer size');
              return;
            }
            resolve(buffer);
          } else {
            // convert binary string to forge buffer
            let buffer = createBuffer(result);
            if (buffer.length() !== endOffset - startOffset) {
              reject('unexpected buffer size');
              return;
            }
            resolve(buffer);
          }
        };
        fileReader.onerror = function (evt) {
          // reject if there is an error
          reject(evt.target.error);
        };
        try {
          // we want a binary string as result
          fileReader.readAsBinaryString(chunkBlob);
        } catch (e) {
          // if the browser does not support it, request an array buffer and convert it manually
          fileReader.readAsArrayBuffer(chunkBlob);
        }
      });
    },

    hasNextChunk() {
      return offset < blobSize;
    },
  };
};
