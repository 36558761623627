import {connect} from "react-redux";
import {displayed, documentPlaintextSize, getDocumentContent} from "../../features/entities/document";
import DocumentContent from "./document-content-view";
import {getDecrypted} from "../../features/e2ee";

const mapStateToProps = (state, {documentId}) => ({
  content: getDecrypted(getDocumentContent(documentId))(state),
  size: documentPlaintextSize(documentId)(state),
});

const mapDispatchToProps = (dispatch, {documentId}) => ({
  onRender: () => dispatch(displayed({documentId})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentContent);
