import React from 'react';
import DocumentsTable from "./documents-table-redux";

export default ({show, caseId}) => (
  show ? (
    <div className="panel panel-primary" style={{
      marginTop: '5px',
      marginLeft: '0px',
      marginBottom: '5px',
      marginRight: '5px'
    }}>
      <div className="panel-heading">
        <strong>Neu hochgeladene Dokumente</strong>
      </div>
      <DocumentsTable caseId={caseId} listingId={caseId} dropzone={null} disableOrdering/>
    </div>
  ) : null
);
