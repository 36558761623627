import {forEachMatchingElement, uimanager} from 'ui-manager';

import React from 'react';
import {store} from 'stores';
import {setSession} from "../features/session";

uimanager.add(el =>
  forEachMatchingElement(el, "[data-session]", node => {
    const session = JSON.parse(node.dataset.session);
    store.dispatch(setSession(session));
  })
);
