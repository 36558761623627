import $ from 'jquery';
import 'imports-loader?jQuery=jquery!jquery-ui';

import Parsley from 'imports-loader?jQuery=jquery!parsleyjs';
import 'imports-loader?jQuery=jquery!parsleyjs/dist/i18n/de';
import {uimanager} from 'ui-manager';

(function ($) {
  // reconfigure Parsley

  Parsley.options.successClass = 'has-success parsley';
  Parsley.options.errorClass = 'has-error mdc-text-field--invalid parsley';
  Parsley.options.errorsWrapper = '<div class="mdc-text-field-helper-line"></div>';
  Parsley.options.errorTemplate = '<div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg"></div>';
  Parsley.options.classHandler = function (_el) {
    const $mdcTextField = _el.$element.closest('.mdc-text-field');
    if ($mdcTextField)
      return $mdcTextField;
    else
      return _el.$element.closest('.form-group');
  };
  Parsley.options.errorsContainer = function (_el) {
    let $element = _el.$element;

    // For MDC text fields, the parent of div.mdc-text-field is a suitable container.
    let $mdcTextField = $element.closest('.mdc-text-field');
    if ($mdcTextField.length !== 0) {
      return $mdcTextField.parent();
    }

    return $element.closest('.form-group');
  };
  Parsley.options.validationThreshold = 0;

  Parsley.on('field:error', function () {
    let $element = this.$element;
    $element.each(function () {
      const mdcField = this.MDCTextField || this.MDCSelect;
      if (mdcField) {
        mdcField.valid = false;
        mdcField.useNativeValidation = false;

        // Remove non-parsley helper text.
        $element.closest('.mdc-text-field').next('.mdc-text-field-helper-line').each(function () {
          if (!$(this).attr('id') || !$(this).attr('id').startsWith('parsley-id-')) {
            $(this).remove();
          }
        });
        $element.closest('.mdc-select').next('.mdc-select-helper-text').each(function () {
          if (!$(this).attr('id') || !$(this).attr('id').startsWith('parsley-id-')) {
            $(this).remove();
          }
        });

      }
    });
  });
  Parsley.on('field:success', function () {
    this.$element.each(function () {
      const mdcField = this.MDCTextField || this.MDCSelect;
      if (mdcField) {
        mdcField.valid = true;
        mdcField.useNativeValidation = true;
      }
    });
  });

  // set appropriate language if available
  let lang = $('html').attr('lang');
  if (lang) {
    if (lang === 'de-de')
      lang = 'de';
    try {
      Parsley.setLocale(lang);
    } catch (e) {
      console.error('Language ' + lang + ' not available for Parsley');
    }
  }
})($);

uimanager.add(function (el) {
  const $dom_element = $(el);
  $('[data-parsley-validate=1]').each(function () {
    let my_parsley = $(this).parsley();
    $(this).on('reset', function () {
      my_parsley.reset();
    });
  });
});

export {Parsley};
