import React from "react";
import {getDocument, modifyAndPersistDocument} from "../../features/entities/document";
import {connect} from "react-redux";
import {getDecrypted} from "../../features/e2ee";
import {getProgress} from "../../features/ui/progress";
import DocumentDescription from "./document-description-view";

export default connect(
  (state, {description, documentId}) => {
    const {can_update, is_just_uploaded} = getDecrypted(getDocument(documentId))(state);
    const {inProgress, error} = getProgress(modifyAndPersistDocument.type, documentId)(state);

    let errorMessage;
    switch (error) {
      case 401:
        errorMessage = "Sie können die Beschreibung nicht ändern, da Ihre Sitzung abgelaufen ist. Loggen Sie sich neu ein.";
        break;
      case 403:
        errorMessage = "Sie sind nicht berechtigt, Änderungen an diesem Dokument durchzuführen. (Beachten Sie, dass Änderungen an der Beschreibung nur kurz nach dem Upload erlaubt sind.)";
        break;
      default:
        if (error) {
          errorMessage = error;
        }
    }

    return {
      description,
      canUpdate: can_update && is_just_uploaded,
      pendingUpdate: inProgress,
      error: errorMessage,
    };
  },
  (dispatch, {documentId, caseId}) => {
    return {
      onSetDescription: description => {
        dispatch(modifyAndPersistDocument({documentId, caseId, description}));
      },
    };
  },
)(DocumentDescription);
