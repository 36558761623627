import React, {createRef} from 'react';


export default class Pagination extends React.Component {
  constructor() {
    super();

    this.el = createRef();
  }

  render() {
    const {currentPage, totalPages, setPage, error} = this.props;

    if (currentPage === undefined) {
      return <ul className='pagination' ref={this.el}/>;
    }

    if (currentPage === 1 && totalPages === 1) {
      return null;
    }

    const createChangePageFunction = page => evt => {
      evt.preventDefault();
      if (page !== null) {
        setPage(page);
      }
      return false;
    };

    return (
      <ul className='pagination'>
        {currentPage > 1 ? (
          <li>
            <a href='#' onClick={createChangePageFunction(currentPage - 1)}>
              <span className="glyphicon glyphicon-chevron-left"/>
            </a>
          </li>
        ) : null}
        {new Array(totalPages).fill().map((_, i) => {
          const page = i + 1;

          let showPageNumber = (
            (page === 1 || page === totalPages || Math.abs(currentPage - page) <= 4)
            ||
            ((page === 2 || page === totalPages - 1) && Math.abs(currentPage - page) === 5)
          );

          if (!showPageNumber) {
            if (Math.abs(currentPage - page) === 5) {
              return (
                <li className="disabled" key={page}>
                  <a href="#" onClick={createChangePageFunction(null)}>...</a>
                </li>
              );
            } else {
              return null;
            }
          }

          return (
            (page === currentPage && !error) ? (
              <li key={page} className='active'>
                <a>
                  Seite {page}
                </a>
              </li>
            ) : (page === currentPage && error) ? (
              <li key={page} className=''>
                <a href='#' onClick={createChangePageFunction(page)}>
                  Seite {page}
                </a>
              </li>
            ) : (
              <li key={page}>
                <a href='#' onClick={createChangePageFunction(page)}>
                  {page}
                </a>
              </li>
            )
          )
        })}
        {currentPage < totalPages ? (
          <li>
            <a href='#' onClick={createChangePageFunction(currentPage + 1)}>
              <span className="glyphicon glyphicon-chevron-right"/>
            </a>
          </li>
        ) : null}
      </ul>
    );
  }

  addExistingContent() {
    const {existingContent, currentPage} = this.props;

    if (currentPage !== undefined) {
      return;
    }

    const el = this.el.current;

    if (existingContent && el) {
      try {
        el.append(...existingContent.children);
      } catch (e) {
        // Workaround for IE11.
        for (const child of [...existingContent.children]) {
          el.appendChild(child);
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.addExistingContent();

  }

  componentDidMount() {
    this.addExistingContent();
  }
}
