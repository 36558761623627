import {createAction, createSlice} from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: 'order',
  initialState: {},
  reducers: {
    placedOrder(state, action) {
      return action.payload;
    },
  },
});

export const {placedOrder} = orderSlice.actions;

export const placeOrder = createAction('extras/placeOrder');

export default {
  order: orderSlice.reducer,
};
