import BulkUploaderCaseNumber from "./bulk-uploader-case-number-view";
import {connect} from "react-redux";
import {getCase} from "../../../features/entities/case";
import {getView} from "../../../features/ui/view";
import {getDecrypted} from "../../../features/e2ee";

const mapStateToProps = (state, props) => {
  const {caseId} = getView('bulkUploader', 'main')(state);
  const {case_number: caseNumber} = getDecrypted(getCase(caseId))(state);

  return {
    caseNumber,
  };
}

export default connect(
  mapStateToProps,
)(BulkUploaderCaseNumber);
