import React from 'react';
import {DecryptedText} from "../../data/text";

export default ({isWaiting, isComplete, isInProgress, isFailed, caseNumber}) => (
  <>
    {isComplete ? (
      <div className="modal-body alert-success" style={{textAlign: 'center', marginBottom: 0}}>
        <h2>Die Dokumente wurden in die Akte <DecryptedText text={caseNumber}/> hochgeladen.</h2>
      </div>
    ) : isInProgress ? (
      <div className="modal-body alert-warning" style={{textAlign: 'center', marginBottom: 0}}>
        <h2>Upload in Akte <DecryptedText text={caseNumber}/> läuft...</h2>
      </div>
    ) : isFailed ? (
      <div className="modal-body alert-danger" style={{textAlign: 'center', marginBottom: 0}}>
        <h2>Fehler! Upload in Akte <DecryptedText text={caseNumber}/> war unvollständig!</h2>
      </div>
    ) : isWaiting ? (
      <div className="modal-body alert-warning" style={{textAlign: 'center', marginBottom: 0}}>
        <h2>Warte auf Dokumente für Akte <DecryptedText text={caseNumber}/>...</h2>
      </div>
    ) : null}
  </>
);
