import React from "react";

export default ({className, orderingKey, name, ordering, changeOrdering, style}) => (
  <th
    tabIndex={changeOrdering ? 0 : undefined}
    className={changeOrdering ? className + " tablesorter-header tablesorter-headerDesc bootstrap-header" : undefined}
    onClick={changeOrdering ? (() => changeOrdering(ordering, orderingKey)) : undefined}
    style={style}
  >
    <div className='tablesorter-wrapper' style={{position: 'relative', height: '100%', width: '100%'}}>
      <div className='tablesorter-header-inner'>
        {name}
        {!changeOrdering ? (
          null
        ) : ordering.includes(orderingKey) ? (
          <i className='tablesorter-icon glyphicon glyphicon-chevron-up'/>
        ) : ordering.includes('-' + orderingKey) ? (
          <i className='tablesorter-icon glyphicon glyphicon-chevron-down'/>
        ) : (
          <i className='tablesorter-icon bootstrap-icon-unsorted'/>
        )}
      </div>
    </div>
  </th>
);
