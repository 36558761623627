import './index.scss';
import './icons';

import {MDCRipple} from '@material/ripple/index';
import {MDCFormField} from '@material/form-field/index';
import {MDCTextField} from '@material/textfield/index';
import {MDCTextFieldIcon} from '@material/textfield/icon';
import {MDCSelect} from '@material/select/index';
import {MDCCheckbox} from '@material/checkbox/index';
import {MDCFloatingLabel} from '@material/floating-label/index';
import {MDCSwitch} from '@material/switch/index';
import {MDCTextFieldHelperText} from '@material/textfield/helper-text';
import {MDCSelectHelperText} from '@material/select/helper-text';
import {MDCList} from '@material/list/index';
import {MDCTabBar} from '@material/tab-bar/index';

import {forEachMatchingElement, uimanager} from 'ui-manager';

export function initMDCComponents(el) {
  forEachMatchingElement(el, '.mdc-text-field', node => {
    const mdcTextField = new MDCTextField(node);
    node.querySelectorAll('input, textarea').forEach(node => {
      node.MDCTextField = mdcTextField;
    });
  });
  forEachMatchingElement(el, '.mdc-button', node => {
    new MDCRipple(node);
  });
  forEachMatchingElement(el, '.mdc-text-field__icon', node => {
    new MDCTextFieldIcon(node);
  });
  forEachMatchingElement(el, '.mdc-text-field-helper-text', node => {
    new MDCTextFieldHelperText(node);
  });
  forEachMatchingElement(el, '.mdc-select', node => {
    const mdcSelect = new MDCSelect(node);
    node.querySelectorAll('select').forEach(node => {
      node.MDCSelect = mdcSelect;
    });
  });
  forEachMatchingElement(el, '.mdc-select-helper-text', node => {
    new MDCSelectHelperText(node);
  });
  forEachMatchingElement(el, '.mdc-floating-label', node => {
    new MDCFloatingLabel(node);
  });
  forEachMatchingElement(el, '.mdc-switch', node => {
    new MDCSwitch(node);
  });
  forEachMatchingElement(el, '.mdc-checkbox', node => {
    const checkbox = new MDCCheckbox(node);
    const formFieldNode = node.closest('.mdc-form-field');
    const formField = new MDCFormField(formFieldNode);
    formField.input = checkbox;
  });
  forEachMatchingElement(el, '.mdc-list', node => {
    const list = new MDCList(node);
    list.listElements.map((listItemEl) => new MDCRipple(listItemEl));
  });
  forEachMatchingElement(el, '.mdc-tab-bar', node => {
    new MDCTabBar(node);
  });
}

uimanager.add(initMDCComponents);
