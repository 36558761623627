import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getUI} from "../ui-selectors";

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    urls: {},
  },
  reducers: {
    setURLs(state, action) {
      Object.assign(state.urls, {
        ...action.payload.reduce((acc, data) => (
            {
              ...acc,
              [data.name]: data.url_template,
            }
          ),
          {},
        ),
      });
    },
  },
});

export const {setURLs} = navigationSlice.actions;

export default {
  navigation: navigationSlice.reducer,
};

const getNavigation = createSelector(
  getUI,
  ui => ui.navigation,
);

export const getURLsById = createSelector(
  getNavigation,
  navigation => navigation.urls,
);

const placeholderRegexp = new RegExp(/!([A-Z_]+)!/, 'g');

export const getURL = (name, args = {}, kwargs = {}) => createSelector(
  getURLsById,
  urlsById => {
    let url = urlsById[name];
    if (!url) {
      return undefined;
    }

    let success = true;
    url = url.replace(placeholderRegexp, (_, match) => {
      const replacement = args[match.toLowerCase()];
      if (replacement === undefined) {
        console.error(`missing argument ${match} for URL template ${name}`);
        success = false;
      }
      return replacement;
    });

    const qs = Object.keys(kwargs).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(kwargs[k])).join('&');
    if (qs) {
      url += '?' + qs;
    }

    return success ? url : undefined;
  },
);
