import {takeEvery} from 'redux-saga/effects';
import {createSimpleAPICall} from "api";
import {reportClientError} from "./telemetry-actions";

const watchReportError = createSimpleAPICall({
  apiName: 'reportClientError',
  createBody: action => action.payload,
  successAction: () => {
  },
});

export default function* telemetrySaga() {
  yield takeEvery(reportClientError, watchReportError);
}
