import $ from 'jquery';

(function ($) {
  // jQuery plugin to prevent double submission of forms
  // adapted from http://stackoverflow.com/a/4473801
  $.fn.preventDoubleSubmission = function () {
    // Plugin must not be activated more than once per form.
    if ($(this).data('preventDoubleSubmission')) {
      return;
    }

    $(this).on('submit', function (e) {
      let $form = $(this);

      if ($form.data('submitted') === true) {
        // Previously submitted - don't submit again
        console.warn('skip repeated submit action');
        e.preventDefault();
      } else {
        // Mark it so that the next submit can be ignored
        $form.data('submitted', true);
      }
    });

    $(this).data('preventDoubleSubmission', true);

    // Keep chainability
    return this;
  };
})($);
