import React from 'react';

export default ({caseIsEmpty, canUpload, showDropzone}) => (
  caseIsEmpty ? (
    <tr className="no-hover">
      <td colSpan="9">
        <div className="col-sm-8 col-sm-offset-2">
          <h2>Die Akte ist derzeit leer.</h2>
          {(canUpload && showDropzone) ? (<>
            <p className="spacer lead">
              Sie können Dokumente zu dieser Akte hinzufügen, indem Sie auf
              {' '}<a className="btn btn-primary btn-sm" onClick={showDropzone}>
              <span className="glyphicon glyphicon-paperclip" aria-hidden="true"/> Dokumente hinzfuügen
            </a>{' '}
              klicken.
            </p>
            <p className="lead text-gray">
              Alternativ können Sie die Dokumente einfach hier hineinziehen.
            </p>
          </>) : null}
        </div>
      </td>
    </tr>
  ) : (
    <tr className="no-hover">
      <td colSpan="9">
        <div className="col-sm-8 col-sm-offset-2">
          <h2>Diese Ansicht enthält keine Dokumente.</h2>
        </div>
      </td>
    </tr>
  )
);
