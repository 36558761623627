import $ from 'jquery';

// check for compatiblity
var is_compatible = true;
// require Blob support
if (window.Blob === undefined) {
  is_compatible = false;
  $(document).ready(function () {
    $('.compatibility-warning-blob').removeClass('hidden');
  });
}
// require cookies
if (!navigator.cookieEnabled) {
  is_compatible = false;
  $(document).ready(function () {
    $('.compatibility-warning-cookies').removeClass('hidden');
  });
}
// require sessionStorage
try {
  sessionStorage.setItem('test', '1');
  if (sessionStorage.getItem('test') !== '1')
    throw "sessionStorage.getItem failed";
  sessionStorage.removeItem('test');
} catch (e) {
  is_compatible = false;
  $(document).ready(function () {
    $('.compatibility-warning-sessionstorage').removeClass('hidden');
  });
}
// customize sessionStorage warning for safari
if (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && !navigator.userAgent.match('CriOS'))
  $('.compatibility-warning-safari').removeClass('hidden');

// show compatibility warning if necessary
if (!is_compatible)
  $(document).ready(function () {
    $('.compatibility-warning').removeClass('hidden');
  });

export {is_compatible};
