import {takeEvery} from 'redux-saga/effects';
import {placedOrder, placeOrder} from "./order-slice";
import {createSimpleAPICall} from "../../../api";

const watchPlaceOrder = createSimpleAPICall({
  apiName: 'createOrder',
  createBody: action => (action.payload.order),
  successAction: ({result}) => placedOrder(result),
});

export default function* orderSaga() {
  yield takeEvery(placeOrder, watchPlaceOrder);
}
