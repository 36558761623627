import {forEachMatchingElement, uimanager} from 'ui-manager';

import React, {createRef} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Tab, Tabs, Tooltip, Typography} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views/src';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {theme} from 'ui/theme';

import './css/style.css';

import {MuiThemeProvider} from '@material-ui/core/styles';

export class WrapDOM extends React.Component {
  /**
   * Helper component to wrap an existing DOM node and use it as react component.
   *
   * Existing DOM nodes can be assigned the .react-hidden class to be made invisible until they are processed by react.
   * This class will be removed from the DOM node during rendering.
   */
  constructor() {
    super();

    this.el = createRef();
  }

  render() {
    const {node, span, ul, ...props} = this.props;
    if (span) {
      return <span ref={this.el} {...props}/>;
    } else if (ul) {
      return <ul ref={this.el} {...props}/>;
    }
    return <div ref={this.el} {...props}/>;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const wrappedNode = this.props.node;
    wrappedNode.classList.remove('react-hidden');

    const el = this.el.current;
    if (el) {
      el.appendChild(wrappedNode);
      uimanager.process(el);
    }
  }

  componentDidMount() {
    this.componentDidUpdate();
  }
}

function CloneProps(props) {
  /**
   * Helper render props component for spreading props.
   */
  const {children, ...other} = props;
  return children(other);
}

class TabContainer extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render = () => (
    <Typography component="div" style={{padding: 15}}>
      {this.props.children}
    </Typography>
  );
}

class TabArea extends React.Component {
  constructor(props) {
    super(props);

    const activeIndex = props.tabs.findIndex(tab => tab.active);
    if (activeIndex !== -1)
      this.state.value = activeIndex;
  }

  state = {
    value: 0,
  };

  handleTabChange = (event, value) => {
    this.setState({value});
  };

  handleSwipeChange = (value) => {
    this.handleTabChange(null, value);
  };

  render() {
    const {tabs} = this.props;
    const {value} = this.state;

    // Make sure tooltip around tab is rendered correctly.
    const tooltipTabStyle = {flexGrow: 1, flexShrink: 1, textAlign: "center"};

    return (
      <>
        <Tabs
          value={value}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {tabs.map(tab => tab.tooltip ? (
            // Render tooltip around tab in a way that it is visible even if the tab is disabled.
            <CloneProps key={tab.id}>
              {tabProps => (
                <Tooltip title={tab.tooltip} style={tooltipTabStyle} arrow>
                  <div><Tab label={tab.label} icon={tab.icon} disabled={tab.disabled} {...tabProps}/></div>
                </Tooltip>
              )}
            </CloneProps>
          ) : (
            // Render tab without tooltip.
            <Tab key={tab.id} label={tab.label} icon={tab.icon} disabled={tab.disabled}/>
          ))}
        </Tabs>
        <SwipeableViews
          axis='x'
          index={value}
          onChangeIndex={this.handleSwipeChange}
          ignoreNativeScroll={true}
          disabled={tabs.filter(tab => !tab.disabled).length <= 1}
        >
          {tabs.map(tab => (
            <TabContainer key={tab.id} disabled={tab.disabled}>
              {tab.content}
            </TabContainer>)
          )}
        </SwipeableViews>
      </>
    );
  }
}

uimanager.add(el =>
  forEachMatchingElement(el, '[data-react-component="tabs"]', node => {
    // Collect tabs.
    const tabs = [...node.querySelectorAll('[data-react-tab]')].map(tabNode => {
      const {faIcon, ...props} = JSON.parse(tabNode.dataset.reactTab);
      return {
        content: <WrapDOM node={tabNode}/>,
        icon: faIcon && <FontAwesomeIcon icon={faIcon} size='2x'/>,
        ...props,
      };
    });

    // Render tabs into tab area.
    ReactDOM.render(<MuiThemeProvider theme={theme}><TabArea tabs={tabs}/></MuiThemeProvider>, node);
  })
);
