import {cancel, fork, take} from 'redux-saga/effects';

// https://stackoverflow.com/a/53465495
export const takeLatestDeep = (pattern, identifier, fn, ...args) => fork(function* () {
  const tasks = {};

  while (true) {
    const action = yield take(pattern);
    const id = identifier(action);

    if (tasks[id]) {
      yield cancel(tasks[id]);
    }

    tasks[id] = yield fork(fn, ...args.concat(action));
  }
});

export const takeLeadingDeep = (pattern, identifier, fn, ...args) => fork(function* () {
  const tasks = {};

  while (true) {
    const action = yield take(pattern);
    const id = identifier(action);

    const task = tasks[id];
    if (task && task.isRunning()) {
      continue;  // Ignore action since task is still running.
    }

    tasks[id] = yield fork(fn, ...args.concat(action));
  }
});

export const takeLatestDeepForThrottling = (pattern, identifier, ms, fn, ...args) => fork(function* () {
  const tasks = {};

  while (true) {
    const action = yield take(pattern);
    const id = identifier(action);

    const existingTask = tasks[id];
    if (existingTask) {
      if (Date.now() - existingTask.startTime < ms) {
        yield cancel(existingTask.task);
      } else {
        delete tasks[id];
      }
    }

    tasks[id] = {
      startTime: Date.now(),
      ...tasks[id],
      task: yield fork(fn, ...args.concat(action)),
    };
  }
});
