import $ from 'jquery';

import './js/documentupload.js';
import './js/mavodb.js';

import 'ui-typeahead';

//process DOM
import {uimanager} from 'components/ui-manager';

uimanager.add(function (element) {
  const $element = $(element);

  // Instantiate MavoDB state machines
  $element.find("[data-mavodb-data]").addBack("[data-mavodb-data]").each(function () {
    $(this).mavodb();
  });

  // Instantiate document-upload state machines
  $element.find("[data-documentupload-data]").addBack("[data-documentupload-data]").each(function () {
    let that = this;

    $(this).documentupload();

    // FIXME: the code below is a mess
    let case_matcher = function findMatches(search_query, cb) {

      let $mavodb = $('[data-mavodb=cases]');
      let ids = $mavodb.mavodb('get_ids_by_search_query', search_query);

      let matches = [];

      for (let i in ids) {
        // return no more than 10 results
        if (i >= 10)
          break;

        let id = ids[i];
        let data = $mavodb.mavodb('get_by_id', id);
        matches.push({
          case_number: data.case_number,
          title: data.title,
          case_slug: id,
        });
      }

      cb(matches);
    };

    $(that).find('.search').typeahead({
      minLength: 1,
      hint: true,
      highlight: true
    }, {
      name: 'cases',
      display: 'case_number',
      source: case_matcher,
      templates: {
        empty: '<span class="text-danger" style="margin-left:10px;margin-right:10px;"><strong>Keine passenden Akten gefunden.</strong></span>',
        suggestion: function (data) {
          if (data.title)
            return $('<span></span>').text(data.case_number).append($('<span></span>').html(' &ndash; ')).append($('<span class="text-info"></span>').text(data.title));
          else
            return $('<span></span>').text(data.case_number);
        }
      }
    });

    $(that).find('.search').bind('typeahead:select', function (jquery, suggestion) {
      // TODO: error handling
      $(that).documentupload('fire', 'set_case_url', suggestion.case_slug);
    });

    // if there is only one item, select it on enter
    $(that).find('.search').on('keyup', function (e) {
      if (e.which === 13) {
        if ($(this).siblings(".tt-menu").find(".tt-selectable").length === 1)
          $(this).siblings(".tt-menu").find(".tt-selectable").trigger('click');
      }
    });
  });
});
