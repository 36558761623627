import {actionChannel, put, spawn, take} from 'redux-saga/effects';
import {crypto_manager} from "../../../components/mavo-crypto";
import {decrypt, decrypted} from "./crypto-fragments-slice";

function* decryptionManager() {
  const decryptActionsChan = yield actionChannel([decrypt]);
  while (true) {
    const action = yield take(decryptActionsChan);

    const {payload: {cryptoFragmentId, ...payload}} = action;
    try {
      const plaintext = crypto_manager.process_entity(payload);
      yield put(decrypted({cryptoFragmentId, plaintext}));
    } catch (e) {
      yield put(decrypted({cryptoFragmentId, error: e.message}));
    }
  }
}

export default function* e2eeSaga() {
  yield spawn(decryptionManager);
}
