import {forEachMatchingElement, uimanager} from 'ui-manager';

import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {store} from 'stores';
import {theme} from "../ui/theme";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "@material-ui/core";
import "components/icons/fontawesome";

uimanager.add(el =>
  forEachMatchingElement(el, "[data-react-fa-icon]", node => {
    const props = JSON.parse(node.dataset.reactFaIcon);
    let icon = <FontAwesomeIcon {...props}/>;
    if (props.tooltip) {
      icon = <Tooltip title={props.tooltip} arrow><span>{icon}</span></Tooltip>;
    }

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          {icon}
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);
