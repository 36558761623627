import {put, select, takeEvery} from 'redux-saga/effects';
import {fetchUser, getUser, needUser, setUser} from "./user-slice";
import {takeLeadingDeep} from "../../../components/effects";
import {createSimpleAPICall} from "api";

const watchFetchUser = createSimpleAPICall({
  apiName: 'retrieveUser',
  successAction: ({result}) => setUser(result),
});

function* watchNeedUser(action) {
  const {id} = action.payload;
  const user = (yield select(getUser(id)));
  if (!user || !user.id) {
    yield put(fetchUser({id}));
  }
}

export default function* userSaga() {
  yield takeEvery(needUser, watchNeedUser);

  yield takeLeadingDeep(
    fetchUser,
    action => action.payload.id,
    watchFetchUser,
  );
}
