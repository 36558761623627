import {connect} from "react-redux";
import DocumentStatus from "./document-status-view";
import {
  getDocumentOrDraft,
  isDocumentRead,
  isJustUploaded,
  isReadyForUpload,
  isUploadedBySessionUser
} from "../../features/entities/document";
import {getProgress} from "../../features/ui/progress";

export default connect(
  (state, {documentId}) => {
    const uploadProgress = getProgress('uploadDocument', documentId)(state),
      encryptionProgress = getProgress('encryptDocument', documentId)(state);
    const isUploadFailed = encryptionProgress.error || uploadProgress.error;

    const {id, status} = getDocumentOrDraft(documentId)(state);
    const isDraft = (id === undefined);

    return {
      isRead: isDraft || isDocumentRead(documentId)(state),
      isUploadedBySessionUser: isDraft || isUploadedBySessionUser(documentId)(state),
      isJustUploaded: isJustUploaded(documentId)(state),
      isReadyForUpload: isReadyForUpload(documentId)(state),
      isUploadFailed,
      status,
    };
  },
)(DocumentStatus);
