import React from 'react';

import {connect} from "react-redux";
import {getDraftDocumentIdsForCase, getRecentlyUploadedIdsByCaseId} from "../../features/entities/document";
import RecentlyUploadedDocumentsTable from "./recently-uploaded-documents-table-view";

export default connect(
  (state, {caseId}) => {
    const hasRecentlyUploadedDocuments = getRecentlyUploadedIdsByCaseId(caseId)(state).length > 0;
    const hasDraftDocuments = getDraftDocumentIdsForCase(caseId)(state).length > 0;

    return {
      show: hasDraftDocuments || hasRecentlyUploadedDocuments,
    };
  },
)(RecentlyUploadedDocumentsTable);
