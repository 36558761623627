import React from "react";

export default ({error, onRetry, onClick}) => (error ? (
  <div className="alert alert-danger clickable-exclude" style={{marginTop: '8px', marginBottom: '2px'}} onClick={onClick}>
    <p>
      <strong>Fehler:</strong> {error}
      {onRetry ? (
        <>
          <> </>
          <button type="button" className="btn btn-xs btn-default" onClick={onRetry}>
            <span className="glyphicon glyphicon-refresh"/> Nochmal versuchen
          </button>
        </>
      ) : null}
    </p>
  </div>
) : null);
