import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "@material-ui/core";
import React from "react";

export default ({tooltip, type = 'info', checkmarks = 0, placement='left', children}) => {
  const badge = (
    <span className={"label label-" + type}>
      {children} {
      (checkmarks === 2) ? (
        <FontAwesomeIcon icon={['fas', 'check-double']}/>
      ) : (checkmarks >= 0) ? (
        <>
          {[...Array(checkmarks)].map((_, idx) => <FontAwesomeIcon key={idx} icon={['fas', 'check']}/>)}
        </>
      ) : null}
    </span>
  );

  if (tooltip) {
    return <Tooltip title={tooltip} placement={placement} arrow>{badge}</Tooltip>;
  }

  return badge;
};
