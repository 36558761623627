import {withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import Typography from "@material-ui/core/Typography";
import classNames from 'classnames';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '& ~ *': {
      marginRight: 50,
    }
  },
});

export const DialogTitle = withStyles(styles)(props => {
  const {children, classes, className, onClose, disableTypography, ...other} = props;

  let wrappedChildren = children;
  if (!disableTypography) {
    wrappedChildren = (
      <Typography variant="h2" className={classes.dialogTitle}>
        {children}
      </Typography>
    );
  }

  const titleClasses = classNames(
      classes.root,
      className,
    );

  return (
    <MuiDialogTitle disableTypography className={titleClasses} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={['fas', 'times']} style={{minWidth: '1em'}}/>
        </IconButton>
      ) : null}
      {wrappedChildren}
    </MuiDialogTitle>
  );
});
