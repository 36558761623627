import {call, put} from 'redux-saga/effects';
import {withProgress} from "../features/ui/progress";
import {callAPI} from "./api-client";
import {crypto_manager} from "../components/mavo-crypto";

const API_ERROR_MESSAGES = {
  500: "Ein unerwarteter Fehler ist aufgetreten.",
  503: "Die Anfrage ist aufgrund von Wartungsarbeiten kurzzeitig nicht möglich. Bitte versuchen Sie es in wenigen Augenblicken erneut.",
  default: "Die Anfrage an den Server ist fehlgeschlagen.",
};

/**
 * Create simple saga for simple API call.
 * @param apiName Name of API to be called (e.g., 'retrieveUser').
 * @param successAction Action to be called on the retrieved object.
 * @param errorAction Action to be called on retrieval failure.
 * @param useProgress Defines whether progress shall be tracked. If a function is provided, it is used as argument
 *   actionToProgress for withProgress.
 * @param createPayload Callable that creates payload for the API call from a provided action.
 * @param createBody Callable that creates body for the API call from a provided action.
 * @param lookupField Name of ID field in action payload.
 * @param throwErrors Whether errors should be thrown or caught in progress (only applicable if useProgress is true).
 * @returns {function(...[*]=)}
 */
export const createSimpleAPICall = (
  {
    apiName,
    successAction,
    errorAction,
    useProgress = true,
    createPayload = (action, lookupField) => (lookupField ? {id: action.payload[lookupField]} : {}),
    createBody = (action, lookupField) => undefined,
    lookupField = 'id',
    throwErrors = false,
  }
) => {
  let wrapped = function* (action) {
    const payload = yield createPayload(action, lookupField);
    const data = {requestBody: yield createBody(action, lookupField), serverVariables: {}};
    let response;

    try {
      response = yield call(callAPI, {operationId: apiName, payload, data});
      //response = yield call((yield api)[apiName], payload, data);
    } catch (e) {
      const {response} = e;
      let message, formErrors;
      if (response && response.obj) {
        const status = response.status;
        message = response.obj.detail;
        if (!message) {
          message = API_ERROR_MESSAGES[(status in API_ERROR_MESSAGES) ? status : 'default'];
        }
        if (status === 400) {
          formErrors = response.obj;
        }
      } else {
        console.error(e);
        message = `Keine Verbindung zum Server: ${e}`;
      }
      const error = new Error(message);
      error.originalError = e;
      error.status = status;
      error.formErrors = formErrors;
      console.error(`${apiName} failed:`, error);

      if (errorAction !== undefined) {
        yield put(errorAction({...payload, message, error}));
      }

      throw error;
    }

    // Parse wrapped keys:
    const {wrappedKeys, ...result} = response.obj || {};
    if (wrappedKeys) {
      crypto_manager.process_entity({wrapped_keys: wrappedKeys});
    }

    if (successAction !== undefined) {
      yield put(successAction({...payload, result}));
    } else {
      return result;
    }
  };

  if (useProgress) {
    wrapped = withProgress(
      useProgress instanceof Function ? (
        useProgress
      ) : (
        action => ({
          type: action.type,
          id: lookupField ? action.payload[lookupField] : '',
        })
      ),
      throwErrors,
    )(wrapped);
  }

  return wrapped;
};
