import {connect} from "react-redux";
import {PopOverButton} from "../popover/PopOverButton";
import React from "react";
import {getHelpText, showHelpText} from "../../features/ui/help";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default connect(
  (state, {url, icon}) => {
    const helpText = getHelpText(url)(state);

    const content = helpText.title ? (
      <div className="mdc-typography">
        <h6 className="mdc-typography--headline6">
          <FontAwesomeIcon icon={helpText.icon}/> {helpText.title}
        </h6>
        <div className="mdc-typography--body2">
          <div dangerouslySetInnerHTML={{__html: helpText.html}}/>
        </div>
      </div>
    ) : helpText.html ? (
      <div dangerouslySetInnerHTML={{__html: helpText.html}}/>
    ) : (
      <div className="mdc-typography" style={{color: 'darkred'}}>
        <h6 className="mdc-typography--headline6"><FontAwesomeIcon icon={['far', 'frown']}/> Entschuldigung</h6>
        <div className="mdc-typography--body2">
          <p>Es gibt gerade Schwierigkeiten beim Abruf des Hilfetexts.</p>
        </div>
      </div>
    );

    return {
      content,
      icon: icon || <FontAwesomeIcon icon={['far', 'question-circle']}/>,
      loading: helpText.isFetching,
    };
  },
  (dispatch, {url}) => ({
    onOpen: () => dispatch(showHelpText(url, url)),
  })
)(PopOverButton);
