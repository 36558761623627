import {call, put, select, takeEvery} from 'redux-saga/effects';
import {failedToReceiveHelpText, receiveHelpText, requestHelpText} from "./help-actions";
import {getHelpText} from "./help-selectors";
import {SHOW_HELP_TEXT} from "./help-constants";

function* fetchHelpText(action) {
  const {id, apiURL} = action;
  yield put(requestHelpText(id, apiURL));
  try {
    const response = yield call(fetch, apiURL);
    const json = yield response.json();
    yield put(receiveHelpText(id, json));
  } catch (error) {
    yield put(failedToReceiveHelpText(id, error));
  }
}

function* fetchHelpTextIfNeeded(action) {
  const helpText = (yield select(getHelpText(action.id)));
  if (!helpText.html) {
    const {id, apiURL} = action;
    yield call(fetchHelpText, {id, apiURL});
  }
}

export default function* helpSaga() {
  yield takeEvery(SHOW_HELP_TEXT, fetchHelpTextIfNeeded);
}
