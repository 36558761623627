import {delay, put} from 'redux-saga/effects';
import {MESSAGE_WRITER_INPUT_CHANGED} from "./message-writer-constants";
import {messageWriterSetField} from "./message-writer-actions";
import {takeLatestDeep} from "../../../components/effects";

function* handleInputChange({messageId, field, payload}) {
  yield delay(500);
  yield put(messageWriterSetField(messageId, field, payload));
}

export default function* messageWriterSaga() {
  yield takeLatestDeep(
    MESSAGE_WRITER_INPUT_CHANGED,
    action => action.field,
    handleInputChange,
  );
}
