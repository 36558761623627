import {createMuiTheme} from "@material-ui/core";

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#428bca',
    },
    secondary: {
      main: '#f44336',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        backgroundColor: 'black',
      },
      arrow: {
        color: 'black',
      }
    },
  }
});
