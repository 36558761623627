import React from "react";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default ({save}) => (
  <Button
    onClick={save}
    variant="contained"
    color="primary"
    startIcon={<FontAwesomeIcon icon={['fas', 'download']}/>}
    style={{display: 'inline'}}
  >
    Download
  </Button>
);
