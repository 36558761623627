import React from "react";

export default class UpdateDescription extends React.Component {
  handleChange = event => {
    this.props.onChange(event.target.value);
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.onSave();
  };

  render = () => {
    const {description} = this.props;

    return (
      <form className="input-group input-group-sm clickable-exclude" onSubmit={this.handleSubmit} style={{
        width: '100%',
        paddingTop: '5px',
      }} onClick={evt => evt.stopPropagation()}>
        <input autoFocus className="form-control" placeholder="Beschreibung ändern..." type="text" maxLength="227" value={description || ''} onChange={this.handleChange}/>
        <div className="input-group-btn">
          <button type="submit" className="btn btn-primary hidden-xs">Speichern</button>
        </div>
      </form>
    );
  }
}
