import mimeTypesReducers from './mime-types-slice';

export default {
  ...mimeTypesReducers,
};

export * from './mime-types-sagas';
export * from './mime-types-slice';

export {default as saga} from './mime-types-sagas';
