import React from "react";
import {Unshrinkable} from "../layout/helpers/Unshrinkable";
import {StatusBadge} from "../layout";


export default class DocumentStatus extends React.Component {
  render() {
    const {isRead, isUploadedBySessionUser, isJustUploaded, isReadyForUpload, isUploadFailed, status} = this.props;

    let badges = [];

    if (isJustUploaded) {
      badges.push(
        <StatusBadge
          checkmarks={1}
          type='success'
          tooltip="Sie haben das Dokument erfolgreich hochgeladen."
        >
          hochgeladen
        </StatusBadge>
      );
    } else if (isUploadFailed) {
      badges.push(
        <StatusBadge
          type='danger'
          tooltip="Das Dokument konnte nicht hochgeladen werden."
        >
          Upload fehlgeschlagen
        </StatusBadge>
      );
    } else if (isReadyForUpload && !status) {
      badges.push(
        <StatusBadge
          type='warning'
          tooltip="Das Dokument ist bereit zum Upload."
        >
          uploadbereit
        </StatusBadge>
      );
    }

    if (isUploadedBySessionUser) {
      switch (status) {
        case 'received':
          badges.push(
            <StatusBadge
              checkmarks={2}
              type='success'
              tooltip="Ihr Dokument wurde von allen anderen Nutzern gesehen."
            >
              <span className="hidden-xs">von allen </span>gesehen
            </StatusBadge>
          );
          break;

        case 'rec_some':
          badges.push(
            <StatusBadge
              checkmarks={1}
              type='success'
              tooltip="Ihr Dokument wurde von mindestens einem anderen Nutzer gesehen."
            >
              <span className="hidden-xs">von einigen </span>gesehen
            </StatusBadge>
          );
          break;

        case 'notified':
          badges.push(
            <StatusBadge
              checkmarks={2}
              tooltip="Das Dokument wurde von Ihnen hochgeladen. Alle Nutzer wurden benachrichtigt."
            >
              <span className="hidden-xs">alle </span>benachrichtigt
            </StatusBadge>
          );
          break;

        case 'notif_some':
          badges.push(
            <StatusBadge
              checkmarks={1}
              tooltip="Das Dokument wurde von Ihnen hochgeladen. Einige Nutzer wurden bereits benachrichtigt."
            >
              <span className="hidden-xs">einige </span>benachrichtigt
            </StatusBadge>
          );
          break;

        case 'willnotify':
          badges.push(
            <StatusBadge
              tooltip="Das Dokument wurde von Ihnen hochgeladen. Andere Nutzer werden in Kürze benachrichtigt."
            >
              Benachrichtigung geplant
            </StatusBadge>
          );
          break;

        case 'new':
          if (!isJustUploaded) {
            badges.push(
              <StatusBadge
                tooltip="Das Dokument wurde von Ihnen hochgeladen. Bisher wurde niemand darüber benachrichtigt."
              >
                neu
              </StatusBadge>
            );
          }
          break;
      }
    }

    if (!isUploadedBySessionUser && isRead) {
      badges.push(
        <StatusBadge
          type='success'
          tooltip="Sie haben das Dokument bereits gelesen."
        >
          gelesen
        </StatusBadge>
      );
    }

    if (!isRead) {
      badges.push(
        <StatusBadge
          type='warning'
          tooltip={isUploadedBySessionUser ? (
            "Sie haben Ihr Dokument als nicht gelesen markiert."
          ) : (
            "Sie haben das Dokument noch nicht gelesen."
          )}
        >
          ungelesen
        </StatusBadge>
      );
    }

    return (
      <Unshrinkable>
        {badges.map((badge, idx, arr) => (
          <React.Fragment key={idx}>
            {badge}
            {(idx < arr.length - 1) ? (
              <>&nbsp;</>
            ) : null}
          </React.Fragment>
        ))}
      </Unshrinkable>
    );
  }
}
