import {createSlice} from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: 'config',
  initialState: {},
  reducers: {
    setConfig(state, action) {
      Object.assign(state, {
        ...action.payload.reduce((acc, data) => (
            {
              ...acc,
              ...data.settings,
            }
          ),
          {},
        ),
      });
    },
  },
});

export const {setConfig} = configSlice.actions;

export default {
  config: configSlice.reducer,
};

export const getConfig = state => state.config;
