import {forEachMatchingElement, uimanager} from 'ui-manager';

import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {store} from 'stores';

import {HelpPopOverButton} from '../components/help';
import {theme} from "../ui/theme";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

uimanager.add(el =>
  // Instantiate help-button components.
  forEachMatchingElement(el, "[data-help-button]", node => {
    const {helpURL, tabIndex, loadTimeoutMs = 2500, faIcon} = JSON.parse(node.dataset.helpButton);
    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <HelpPopOverButton
            icon={faIcon && <FontAwesomeIcon icon={faIcon}/>}
            url={helpURL}
            loadTimeoutMs={loadTimeoutMs}
            tabIndex={tabIndex}
          />
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);
