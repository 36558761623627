import {connect} from "react-redux";
import {getProgress} from "../../../features/ui/progress";
import {getDecrypted} from "../../../features/e2ee";
import {
  getDocument,
  getDocumentContent,
  hideDocument,
  replyToDocument,
  saveDocument
} from "../../../features/entities/document";
import {getView} from "../../../features/ui/view";
import TextDocumentViewer from "./text-document-viewer-view";
import {getUser, needUser} from "../../../features/entities/user";
import {loadLazily} from "packages/redux-helpers";
import {canUpload} from "../../../features/entities/case";

const mapStateToProps = () => {
  return (state, {id}) => {
    const downloadProgress = getProgress('downloadDocument', id)(state),
      decryptionProgress = getProgress('decryptDocument', id)(state),
      saveProgress = getProgress(saveDocument.type, id)(state);

    const document = getDecrypted(getDocument(id))(state);

    const documentUploader = document && getDecrypted(getUser(document.uploader))(state);

    const {name, description, uploaded_at, slug} = document;
    const caseId = document.case;

    return {
      name, description, uploaded_at, slug,
      documentId: id,
      documentContent: getDecrypted(getDocumentContent(id))(state),
      documentUploader: document && getDecrypted(getUser(document.uploader))(state),
      documentUploaderId: document && document.uploader,
      documentUploaderLoaded: documentUploader && documentUploader.id,
      documentError: downloadProgress.error || decryptionProgress.error || saveProgress.error,
      open: getView('viewDocument', id)(state).visible,
      canReply: caseId !== undefined ? canUpload(caseId)(state) : false,
    };
  };
};

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onClose: () => dispatch(hideDocument({documentId: id})),
    onSave: () => dispatch(saveDocument({documentId: id})),
    reply: () => dispatch(replyToDocument({documentId: id})),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {canReply} = stateProps;
  const {reply, ...otherDispatchProps} = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,

    reply: canReply ? reply : undefined,

    ...otherDispatchProps,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(loadLazily({
  documentUploaderLoaded: {
    sourcePropNames: ['document'],
    loader: (dispatch, {documentUploaderId}) => documentUploaderId && dispatch(needUser({id: documentUploaderId})),
  },
})(TextDocumentViewer));
