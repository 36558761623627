import {createAction, createSelector, createSlice} from "@reduxjs/toolkit";
import {getSessionUser} from "../../session";
import {getEntities} from "../entities-selectors";

const userSlice = createSlice({
  name: 'user',
  initialState: {
    byId: {},
  },
  reducers: {
    setUser(state, action) {
      const {id, ...other} = action.payload;
      state.byId[id] = {id, ...other};
    },
    fetchUser(state, action) {
      const {id} = action.payload;
      if (!state.byId[id]) {
        state.byId[id] = {};
      }
    },
  },
});

export const {setUser, fetchUser} = userSlice.actions;

export const needUser = createAction('user/needUser');

export default {
  users: userSlice.reducer,
};

export const getUsersById = createSelector(
  getEntities,
  entities => entities.users.byId,
);

export const getAnnotatedUsersById = createSelector(
  getUsersById,
  getSessionUser,
  (usersById, sessionUser) => Object.fromEntries(
    Object.entries(usersById).map(([id, user]) => [id, {
      ...user,
      readableId: (id || '').substr(0, 6),
      is_viewer: id === sessionUser,
    }]))
);

export const getUser = id => createSelector(
  getAnnotatedUsersById,
  annotatedUsers => annotatedUsers[id],
);
