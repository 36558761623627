import userReducers from './user-slice';

export default {
  ...userReducers,
};

export * from './user-sagas';
export * from './user-slice';

export {default as saga} from './user-sagas';
