import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getUI} from "../ui-selectors";

const unloadBlockerSlice = createSlice({
  name: 'unloadBlocker',
  initialState: [],
  reducers: {
    add(state, action) {
      const {type, id} = action.payload;
      state.push({type, id});
    },
    remove(state, action) {
      const {type, id} = action.payload;
      return state.filter(blocker => blocker.type !== type || blocker.id !== id);
    },
  },
});

export const {add: addUnloadBlocker, remove: removeUnloadBlocker} = unloadBlockerSlice.actions;

export default {
  unloadBlockers: unloadBlockerSlice.reducer,
};

export const getUnloadBlockers = createSelector(
  getUI,
  ui => ui.unloadBlockers,
);

export const hasUnloadBlockers = createSelector(
  getUnloadBlockers,
  unloadBlockers => unloadBlockers.length > 0,
);

export const hasUnloadBlocker = ({id, type}) => createSelector(
  getUnloadBlockers,
  unloadBlockers => unloadBlockers.filter(blocker => blocker.type === type && blocker.id === id).length > 0,
);
