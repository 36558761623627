/* Generic (abstract) statemachine widget, serves as base for other widgets
 *
 *  This code is based on the screenshare.de screenshot state machine implementation
 *
 *  Copyright 2016 Dominik Leibenger
 */

import $ from 'jquery';

$.widget("solesoftware.statemachine", {
  options: {
    default_ajax_api_call_timeout: 30000, // default timeout for AJAX requests

    default_delay: 1, // delay (ms) between state changes, useful for debugging purposes and for responsiveness
    user_convenience_delay: 500, // artificial delay (ms) for user-triggered actions like password verification and retry (this is for the user's convenience, as it ensures that the user sees that anything is actually done)
    next_delay: undefined
    // one-time artificial delay for next state transition (overrides default delay)
  },

  /*
     * Name of this state machine as used in statemachine-specific data attributes, e.g., data-statemachine-action.
     */
  name: 'statemachine',

  /*
     * State machine that specifies all possible states and transitions
     */

  _states: {
    // Start state
    start: {
      initial: true,
      // action : '_action_to_be_executed',
      transitions: {
        // dummy_transition: 'start'
      },
      async_transitions: {
        // async_transitions are self transitions that do not actually affect the state,
        // but execute an associated action (e.g., the action of the specified target state) anyway
      },
      views: [
        // 'class-to-be-shown-1', 'class-to-be-shown-2'
      ]
    }
  },

  /*
     * Specify asynchronous self-transitions that are executable in any state (if fired, the state remains unchanged, but the corresponding action is executed in the context of this statemachine)
     */

  async_transitions: {
    async_dummy_transition: {
      // action : '_async_action_to_be_executed'
    }
  },

  // Current state
  _state: undefined,

  /*
     * State machine logic
     */

  _ensure_state: function () {
    // Switch into initial state if not already done so.
    if (this._state === undefined) {
      this._transition_counter = 0;

      // Find start state
      for (var state in this._states)
        if (this._states[state].initial)
          this._state = state;
      this.element.trigger('update_state', {
        stateMachine: this,
        new_state: this._state
      });
    }
  },

  _can_fire: function (transition) {
    this._ensure_state();
    return this._states[this._state].transitions && this._states[this._state].transitions[transition] !== undefined;
  },

  _fire: function (transition, sync, arg1, arg2, arg3) {
    this._ensure_state();

    // Check whether this is an asynchronous transition and fire it if necessary.
    var async_transition = this.async_transitions[transition] || (this._states[this._state].async_transitions && this._states[this._state].async_transitions[transition]);
    if (async_transition) {
      var action = async_transition.action || this._states[async_transition].action;
      if (action !== undefined) {
        try {
          // Do not react to transitions fired by async action except errors
          var fire = function (transition, arg1, arg2, arg3) {
            if (transition == 'error')
              return that._fire(transition, sync, arg1, arg2, arg3);
          }

          console.log(this.name + ': ' + 'fire action of async transition ' + transition);
          this[action](fire, arg1, arg2, arg3);
        } catch (e) {
          if (sync)
            throw e;
          else {
            console.warn(this.name + ': ' + 'action of async transition ' + transition + ' failed');
            console.warn(e);
          }
        }
      }
      return true; // true = async transition has fired
    }

    // Fire transition. If new state is reached, run its associated function.

    var old_state = this._state;

    try {
      var successor_state = this._states[this._state].transitions[transition];
      if (successor_state === undefined) {
        if (transition === 'error') {
          console.error(this.name + ': ' + 'missing error handler for state ' + this._state);
          this._state = 'UNCAUGHT_ERROR';
        }
        throw 'cannot fire transition ' + transition + ' in state ' + this._state;
      }
      this._state = successor_state;
    } catch (e) {
      if (sync)
        throw e;
      else {
        console.warn(this.name + ': ' + e);
        return;
      }
    }

    var new_state = this._state;

    console.debug(this.name + ': ' + 'fire transition ' + transition + ' (' + old_state + ' -> ' + new_state + ')');

    this.element.trigger('update_state', {
      stateMachine: this,
      old_state: old_state,
      new_state: new_state
    });

    // Determine appropriate convenience delay before the new state's action should run.

    var delay = this.options.default_delay;
    if (this.options.next_delay) {
      delay = this.options.next_delay;
      this.options.next_delay = undefined;
    }

    // Run action after delay, but only if no transition has fired in the meantime.

    this._transition_counter += 1;

    var that = this;

    var action_fn = (function (sync, arg1, arg2, arg3, transition_counter) {
      var fire = function (transition, arg1, arg2, arg3) {
        if (that._transition_counter != transition_counter) {
          console.warn(that.name + ': ' + 'drop transition ' + transition + ' because another transition has already fired while being in the state that fired the transition');
          return;
        }

        return that._fire(transition, sync, arg1, arg2, arg3);
      }

      return function () {
        if (that._transition_counter != transition_counter) {
          console.warn(that.name + ': ' + 'skip planned action because another transition has fired in the meantime');
          return;
        }
        var action = that._states[that._state].action;
        if (action !== undefined) {
          try {
            console.debug(that.name + ': ' + 'fire action of state ' + new_state);
            that[action](fire, arg1, arg2, arg3);
          } catch (e) {
            try {
              fire('error', e);
            } catch (e2) {
              // never mind, we are failing anyway
            }

            if (sync)
              throw e;
            else
              console.error(e);
          }
        }
      };
    })(sync, arg1, arg2, arg3, this._transition_counter);

    if (sync)
      action_fn();
    else
      setTimeout(action_fn, delay);

    return false; // false = normal transition has fired
  },

  /*
     * Initialization and other plugin-related functions.
     */

  _create: function () {
    var that = this;

    /*
         * Bind some view event handlers.
         */

    this.element.bind('update_state', (event, data) => {
      if (data.stateMachine && data.stateMachine !== this)
        return;

      event.stopPropagation();
      this._update_state(event, data);
    });

    /*
         * Fire initial transition if specified.
         */
    var initial_transition = this.element.attr('data-' + this.name + '-initial-transition');
    if (initial_transition)
      this.fire(initial_transition);

    /*
         * Initialize view.
         */

    this._init_view();

    console.log(this.name + ': ' + 'created');

    // Make sure that initial views are shown.
    this._ensure_state();
  },

  _destroy: function () {
    console.debug(this.name + ': ' + 'destroy');

    // switch back to start state when widget is destroyed to ensure that view is reset
    this.element.trigger('update_state', {
      stateMachine: this,
      old_state: this._state,
      new_state: 'start' // FIXME: determine initial state
    });

    console.log(this.name + ': ' + 'destroyed');
  },

  /*
     * Public functions.
     */

  fire: function (action, arg1, arg2, arg3) {
    return this._fire(action, false, arg1, arg2, arg3);
  },

  fire_sync: function (action, arg1, arg2, arg3) {
    return this._fire(action, true, arg1, arg2, arg3);
  },

  can_fire: function (action) {
    return this._can_fire(action);
  },

  state: function () {
    this._ensure_state();
    return this._state;
  },

  /*
     * Event handlers for view updates.
     */

  _init_view: function () {
    // Initializes views.
    var that = this;

    // initialize buttons that are associated with state machine actions
    var click_handler = function (event) {
      // that.options.next_delay = that.options.user_convenience_delay;
      var actions = $(this).attr('data-' + that.name + '-action').split(',');
      var result = true;
      for (var action_id in actions) {
        if (!that.fire(actions[action_id], $(this).attr('data-' + that.name + '-action-argument'), $(this).attr('data-' + that.name + '-action-argument2'), $(this).attr('data-' + that.name + '-action-argument3')))
          result = false;
      }
      return result;
    };
    this.element.on('click', ':not(form)[data-' + this.name + '-action]', click_handler);
    this.element.on('submit', 'form[data-' + this.name + '-action]', click_handler);

    var firstclick_handler = function (event) {
      // that.options.next_delay = that.options.user_convenience_delay;
      return that.fire($(this).attr('data-' + that.name + '-first-action'), $(this).attr('data-' + that.name + '-action-argument'), $(this).attr('data-' + that.name + '-action-argument2'), $(this).attr('data-' + that.name + '-action-argument3'));
    };
    this.element.on('click', ':not(form)[data-' + this.name + '-first-action]', firstclick_handler);
    this.element.on('submit', 'form[data-' + this.name + '-first-action]', firstclick_handler);

    var rightclick_handler = function (event) {
      that.element.off('contextmenu', rightclick_handler);
      // that.options.next_delay = that.options.user_convenience_delay;
      return that.fire($(this).attr('data-' + that.name + '-first-rightclick-action'), $(this).attr('data-' + that.name + '-first-rightclick-action-argument'), $(this).attr('data-' + that.name + '-first-rightclick-action-argument2'), $(this).attr('data-' + that.name + '-first-rightclick-action-argument3'));
    };
    this.element.on('contextmenu', '[data-' + this.name + '-first-rightclick-action]', rightclick_handler);
  },

  _update_state: function (event, data) {
    // Hides views associated to the previous state and shows the ones associated to the current state.
    var new_state = data.new_state && this._states[data.new_state];
    var new_views = new_state && new_state.views;

    // If new state is not associated with views, keep the previous ones
    if (new_views === undefined)
      return;

    // Determine required modifications
    var modifications = {};
    if (this.current_views)
      for (var i = 0; i < this.current_views.length; i++)
        modifications[this.current_views[i]] = false;
    for (var i = 0; i < new_views.length; i++)
      modifications[new_views[i]] = true;

    // Apply modifications
    for (var key in modifications) {
      var classname = '.' + key;
      if (modifications[key]) {
        this.element.find(classname).removeClass('hidden').trigger('isVisible');
      } else {
        this.element.find(classname).addClass('hidden');
      }
    }

    // Remember current views so that they can be hidden when the next state is reached
    this.current_views = new_views;

    // Focus an element if specified
    if (new_state.focus_view)
      $('.' + new_state.focus_view).focus();
  }
});
