import forge from 'node-forge/lib/forge';
import {createBuffer} from 'node-forge/lib/util';
import {createCipher, createDecipher} from 'node-forge/lib/cipher';
import $ from 'jquery';
import 'imports-loader?jQuery=jquery!jquery-ui';
import 'imports-loader?jQuery=jquery!jquery-ui/ui/effects/effect-shake.js';
import {DecryptionFailedError, MissingKeyError} from './errors';

import {ChunkedBlobReader} from '../../utils/chunked-blob-reader';
import {
  base64ToBytes,
  bytesToBase64,
  bytesToString,
  bytesToUint8Array,
  stringToBytes,
  uint8ArrayToBytes,
  uint8ArrayToForgeBuffer
} from "../../packages/binary-data-helpers";
import {packEncryptedBytes, unpackEncryptedBytes} from "../../packages/e2ee/crypto/data";

import log from 'loglevel';

require('node-forge/lib/random');
require('node-forge/lib/cipher');
require('node-forge/lib/cipherModes');
require('node-forge/lib/aes');
require('node-forge/lib/sha256');
require('node-forge/lib/pbkdf2');

const IV_LENGTH = 12;
const TAG_LENGTH = 16;

const CIPHERTEXT_MIME_TYPE = 'binary/octet-stream';

export const crypto_manager = {
  // internal data structures
  _keys: {},

  // session storage key indices
  _session_storage_key_ids: {
    'session_key': true
  },

  // public interface

  maybe_store_key(key_id, key) {
    // store some keys in session storage if possible
    if (key_id in this._session_storage_key_ids || (key_id && key_id.lastIndexOf('session:', 0) === 0)) {
      if (typeof (Storage) !== "undefined") {
        console.log("store in session storage: " + key_id);
        sessionStorage.setItem('key:' + key_id, bytesToBase64(key));
      } else {
        // TODO: user should see a message if she cannot use the service due to lack of localStorage
      }
    }
  },

  add_key(key_id, key) {
    // add key to key manager, possibly overwriting existing key
    console.debug(`add to crypto manager: ${key_id}`);
    this._keys[key_id] = key;

    this.maybe_store_key(key_id, key);
  },

  add_random_key(key_id) {
    // add new randomly generated key under key_id
    // (if key_id is undefined, a random key_id is used and returned)
    if (key_id === undefined)
      key_id = bytesToBase64(forge.random.getBytesSync(16));

    // do not generate the key earlier than it is actually required so that
    // we can collect some more entropy in the meantime
    this._keys[key_id] = {
      random_key: true
    };

    return key_id;
  },

  add_encrypted_key(key_id, encrypted_key, access_key_id) {
    // add encrypted key to key manager unless decrypted key does already exist
    let key = this._keys[key_id];
    if (typeof key === 'string' || key instanceof String)
      return;

    this._keys[key_id] = {
      encrypted_key: encrypted_key,
      access_key_id: access_key_id
    };
  },

  copy_key(key_id, copy_from_key_id) {
    // copy key from old key id to new key id
    console.debug(`assign key: ${key_id} <- ${copy_from_key_id}`);
    this._keys[key_id] = {
      key_id: copy_from_key_id
    };
    if (this.has_key(key_id))
      this.maybe_store_key(key_id, this.get_key(key_id));
  },

  clear() {
    console.log("clear crypto manager");
    crypto_manager._keys.length = 0;

    // also clear sessionStorage if available
    if (typeof (Storage) !== "undefined") {
      console.log("clear session storage");
      sessionStorage.clear();
    }
  },

  clear_generic_session_key() {
    if (this._keys['session_key']) {
      console.debug("remove from session storage: session_key");
      delete this._keys['session_key'];

      if (typeof (Storage) !== "undefined") {
        sessionStorage.removeItem('key:session_key');
      }
    }
  },

  has_key(key_id) {
    this.load_key(key_id);
    return key_id in this._keys;
  },

  load_key(key_id) {
    let key = this._keys[key_id];

    // try to read key from session storage if we have not found it
    if (key === undefined && typeof (Storage) !== "undefined") {
      key = sessionStorage.getItem('key:' + key_id);
      if (key) {
        key = base64ToBytes(key);
        this.add_key(key_id, key);
      }
    }

    return key;
  },

  get_key(key_id, allow_error) {
    let key = this.load_key(key_id);

    // return if we still have not found anything
    if (!key)
      this._trigger_error(new MissingKeyError(key_id), allow_error);

    // return if we have found a plaintext key
    if (typeof key === 'string' || key instanceof String)
      return key;

    // otherwise we might have a reference to another key
    if (key.key_id) {
      this._keys[key_id] = this.get_key(key.key_id);
      return this._keys[key_id];
    }

    // otherwise we might have an encrypted key that we need to decrypt first
    if (key.encrypted_key && key.access_key_id) {
      this._keys[key_id] = undefined; // just to prevent an endless loop if we have a cycle

      try {
        key = this.decrypt_bytes(key.access_key_id, key.encrypted_key, allow_error);
      } catch (e) {
        // if decryption fails, we throw a missing key exception (see below)
      }
    }
    // or we might have to generate a random key
    else if (key.random_key) {
      console.debug(`generate random key: ${key_id}`);
      key = forge.random.getBytesSync(32);
    }

    // store the key that has just been determined and return it
    if (typeof key === 'string' || key instanceof String) {
      this.add_key(key_id, key);
      return key;
    }

    this._trigger_error(new MissingKeyError(key_id), allow_error);
  },

  wrap_key(key_id, access_key_id) {
    return this.encrypt_bytes(access_key_id, this.get_key(key_id));
  },

  decrypt_string(key_id, ciphertext, allow_error, charset = 'utf8') {
    const decryptedBytes = this.decrypt_bytes(key_id, ciphertext, allow_error);
    if (charset === 'utf8') {
      return bytesToString(decryptedBytes);
    } else if (charset === 'us-ascii') {
      return decryptedBytes;
    } else {
      // For unknown charsets, try utf8 and fall back to ascii.
      try {
        return bytesToString(decryptedBytes);
      } catch (e) {
        return decryptedBytes;
      }
    }
  },

  encrypt_string(key_id, plaintext) {
    return this.encrypt_bytes(key_id, stringToBytes(plaintext));
  },

  decrypt_bytes(key_id, ciphertext, allow_error) {
    const key = this.get_key(key_id, allow_error);

    const decipher = createDecipher('AES-GCM', key);

    const content_buffer = createBuffer(ciphertext);
    decipher.start({
      iv: content_buffer.getBytes(IV_LENGTH),
      tag: content_buffer.getBytes(TAG_LENGTH)
    });
    decipher.update(content_buffer);
    if (!decipher.finish())
      this._trigger_error(new DecryptionFailedError(key_id), allow_error);
    return decipher.output.bytes();
  },

  encrypt_bytes(key_id, plaintext) {
    const key = this.get_key(key_id);

    const iv = forge.random.getBytesSync(IV_LENGTH);
    const cipher = createCipher('AES-GCM', key);
    cipher.start({
      iv: iv,
      tagLength: 8 * TAG_LENGTH
    });
    cipher.update(createBuffer(plaintext));
    cipher.finish();
    return iv + cipher.mode.tag.bytes() + cipher.output.bytes();
  },

  decrypt_stream(keyId, readerOrStream) {
    const key = crypto_manager.get_key(keyId);
    const reader = (readerOrStream instanceof ReadableStream) ? readerOrStream.getReader() : readerOrStream;

    const decipher = createDecipher('AES-GCM', key);

    const headerLength = IV_LENGTH + TAG_LENGTH;
    const headerBuffer = new ArrayBuffer(headerLength);
    const header = new Uint8Array(headerBuffer);
    let receivedHeaderBytes = 0;

    const chunkLength = 128 * 1024;

    return new ReadableStream({
      pull(controller) {
        const processChunks = ([chunk, ...chunks]) => {
          if (!chunk) {
            // If there is no chunk to process we are done.
            return;
          }

          // Decrypt chunk asynchronously and continue with remaining chunks afterwards.
          return new Promise((resolve, reject) => setTimeout(
            () => {
              try {
                // Decrypt chunk.
                decipher.update(uint8ArrayToForgeBuffer(chunk));

                const decryptedChunk = bytesToUint8Array(decipher.output.getBytes());
                controller.enqueue(decryptedChunk);

                resolve(processChunks(chunks));
              } catch (e) {
                reject(e);
              }
            },
          ));
        };

        return reader.read().then(({done, value}) => {
          if (!done && receivedHeaderBytes < headerLength) {
            // Extract IV and tag from first chunk(s) to start decryption.

            // Read header into header buffer.
            const chunkHeaderEnd = Math.min(headerLength - receivedHeaderBytes, value.length);
            header.set(value.slice(0, chunkHeaderEnd), receivedHeaderBytes);
            receivedHeaderBytes += chunkHeaderEnd;

            if (receivedHeaderBytes !== headerLength) {
              // We are still waiting on header data, so we cannot start decryption yet.
              return;
            }

            // Header buffer is full, start decryption.
            decipher.start({
              iv: uint8ArrayToBytes(header.slice(0, IV_LENGTH)),
              tag: uint8ArrayToBytes(header.slice(IV_LENGTH, IV_LENGTH + TAG_LENGTH)),
              tagLength: 8 * TAG_LENGTH,
            });

            // Process only the part after the header.
            value = value.slice(chunkHeaderEnd);
          } else if (done && !decipher.finish()) {
            // Data stream ended, but we cannot finish decryption yet.

            // TODO: crypto manager should only be triggered if decryption failed given complete data, not when the
            //   stream has ended prematurely.
            crypto_manager._trigger_error(new DecryptionFailedError(keyId));
            controller.error("decryption failed");
            return;
          }

          if (!done) {
            // Decrypt value in chunks.
            const chunks = [];
            for (let chunkOffset = 0; chunkOffset < value.length; chunkOffset += chunkLength) {
              chunks.push(value.slice(chunkOffset, chunkOffset + chunkLength));
            }
            return processChunks(chunks);
          } else {
            // After the data stream has ended and decryption has finalized, all remaining decrypted data is available.
            const lastDecryptedChunk = bytesToUint8Array(decipher.output.getBytes());
            if (lastDecryptedChunk.length > 0) {
              controller.enqueue(lastDecryptedChunk);
            }

            // Close stream.
            controller.close();
            reader.releaseLock();
          }
        }).catch(e => {
          controller.error(e);
          reader.releaseLock();
        });
      },
      cancel(reason) {
        reader.cancel(reason);
      },
    });
  },

  decrypt_blob(key_id, blob, type, progress_callback) {
    /* on input a key id and a Blob object containing a ciphertext, return a promise yielding a Blob containing the corresponding plaintext */
    return new Promise(function (resolve, reject) {
      // initialize decipher
      const key = crypto_manager.get_key(key_id);
      const decipher = createDecipher('AES-GCM', key);
      resolve(decipher);
    }).then(function (decipher) {
      // decrypt blob in chunks
      let loaded = 0, total = blob.size; // progress
      let plaintextChunkBlobs = [];
      const chunkedBlobReader = ChunkedBlobReader(blob);
      let isFirstChunk = true;
      const processChunk = function (chunk) {
        const chunkLength = chunk.length();
        const isLastChunk = !chunkedBlobReader.hasNextChunk();

        // extract iv and tag from first chunk to start decryption
        if (isFirstChunk) {
          isFirstChunk = false;

          if (chunkLength < IV_LENGTH + TAG_LENGTH)
            throw Error('chunk size for decryption must be at least ' + (IV_LENGTH + TAG_LENGTH) + ' bytes');
          decipher.start({
            iv: chunk.getBytes(IV_LENGTH),
            tag: chunk.getBytes(TAG_LENGTH),
            tagLength: 8 * TAG_LENGTH
          });
        }

        // decrypt chunk
        decipher.update(chunk);
        if (isLastChunk)
          if (!decipher.finish())
            crypto_manager._trigger_error(new DecryptionFailedError(key_id));
        const decryptedChunkArray = bytesToUint8Array(decipher.output.getBytes());
        plaintextChunkBlobs.push(new Blob([decryptedChunkArray], {type}));

        // inform progress_callback
        loaded += chunkLength;
        progress_callback(loaded, total);

        // continue with next chunk if existent
        if (!isLastChunk)
          return chunkedBlobReader.nextChunk().then(processChunk);

        // finalize plaintext
        return new Promise(function (resolve, reject) {
          // concatenate plaintext chunks
          resolve(new Blob(plaintextChunkBlobs, {type}));
        });
      };

      // start with first chunk
      return chunkedBlobReader.nextChunk().then(processChunk);
    });
  },

  encrypt_blob(key_id, blob, progress_callback) {
    /* on input a key id and a File object, return a promise yielding a Blob containing the corresponding ciphertext */
    return new Promise(function (resolve, reject) {
      // initialize cipher
      let key = crypto_manager.get_key(key_id);
      let iv = forge.random.getBytesSync(IV_LENGTH);
      let cipher = createCipher('AES-GCM', key);
      cipher.start({
        iv: iv,
        tagLength: 8 * TAG_LENGTH
      });
      resolve({cipher: cipher, iv: iv});
    }).then(function ({cipher, iv}) {
      // encrypt blob in chunks
      let loaded = 0, total = blob.size; // progress
      let ciphertextChunkBlobs = [];
      let chunkedBlobReader = ChunkedBlobReader(blob);
      let processChunk = function (chunk) {
        let chunkLength = chunk.length();
        let isLastChunk = !chunkedBlobReader.hasNextChunk();

        // encrypt chunk
        cipher.update(chunk);
        if (isLastChunk && !cipher.finish())
          throw Error('encryption failed');
        let encryptedChunkArray = bytesToUint8Array(cipher.output.getBytes());
        ciphertextChunkBlobs.push(new Blob([encryptedChunkArray], {type: CIPHERTEXT_MIME_TYPE}));

        // inform progress_callback
        loaded += chunkLength;
        progress_callback(loaded, total);

        // continue with next chunk if existent
        if (!isLastChunk)
          return chunkedBlobReader.nextChunk().then(processChunk);

        // finalize ciphertext
        return new Promise(function (resolve, reject) {
          // generate first ciphertext chunk
          let tag = cipher.mode.tag.bytes();
          if (tag === undefined || iv === undefined)
            throw Error('invalid tag or iv');
          let ciphertextMetadataArray = bytesToUint8Array(iv + tag);
          ciphertextChunkBlobs.unshift(new Blob([ciphertextMetadataArray], {type: CIPHERTEXT_MIME_TYPE}));

          // concatenate ciphertext chunks
          resolve(new Blob(ciphertextChunkBlobs, {type: CIPHERTEXT_MIME_TYPE}));
        });
      };

      // start with first chunk
      return chunkedBlobReader.nextChunk().then(processChunk);
    });
  },

  _trigger_error(error, allow_error) {
    if (!allow_error) {
      if (error.keyId && error.keyId.startsWith('session:')) {
        try {
          crypto_manager.missingSessionKey = true;
          $('.js-security-error').removeClass("hidden").find('.container').not(':animated').effect("shake");
        } catch (e) {
          console.error(e);
        }
        console.error(error);
      } else if (!crypto_manager.missingSessionKey) {
        log.error({msg: "crypto_manager._trigger_error", error, errorType: typeof error});
      } else {
        console.error(error);
      }
    }
    throw error;
  },

  process_entity(entity) {
    if (entity.wrapped_keys !== undefined) {
      // process list of wrapped keys
      const wrapped_keys = entity.wrapped_keys;
      for (let wrapped_key of wrapped_keys) {
        if (wrapped_key.key_id === undefined) {
          // FIXME: raise exception?
          continue;
        }

        if (wrapped_key.key !== undefined) {
          this.add_key(wrapped_key.key_id, base64ToBytes(wrapped_key.key));
        } else if (wrapped_key.encrypted_key !== undefined && wrapped_key.access_key_id !== undefined) {
          this.add_encrypted_key(wrapped_key.key_id, base64ToBytes(wrapped_key.encrypted_key), wrapped_key.access_key_id);
        } else if (wrapped_key.copy_from_key_id !== undefined) {
          this.copy_key(wrapped_key.key_id, wrapped_key.copy_from_key_id);
        }
      }
    } else if (entity.encrypted_string !== undefined) {
      // process ciphertext
      const data = entity.encrypted_string;
      let {key_id, ciphertext, charset} = data;
      if (ciphertext === undefined)
        ciphertext = data.data;
      let value;
      if (ciphertext) {
        value = this.decrypt_string(key_id, base64ToBytes(ciphertext), false, charset);
      } else if (data.plaintext) {
        value = data.plaintext;
      } else {
        value = '';
      }

      if (data.substitutions)
        for (let substitute_k in data.substitutions)
          if (value === substitute_k) {
            value = data.substitutions[substitute_k];
            break;
          }

      if (data.prefix)
        value = data.prefix + value;
      if (data.postfix)
        value = value + data.postfix;

      return value;
    } else if (entity.plaintext !== undefined) {
      return entity.plaintext;
    } else {
      throw {unknownEntity: entity};
    }
  },

  process_key_graph(key_graph, ignore_errors) {
    console.debug("process key graph...");

    // Process new keys.
    let new_keys = key_graph['new_keys'];

    function process_key(key_id) {
      let key_meta_data = new_keys[key_id];

      if (!crypto_manager.has_key(key_id)) {
        if (!key_meta_data?.root) {
          // Let's initialize a new key.
          crypto_manager.add_random_key(key_id);
        } else if (ignore_errors) {
          // This is fine for now. Somebody else will probably take care of key generation.
          console.debug('key id ' + key_id + ' missing in key graph; maybe it will be generated from form fields');
          return null;
        } else {
          // Uh oh. We really need this key!
          throw new Error("Missing key: " + key_id);
        }
      }

      return key_id;
    }

    let key_links = key_graph['new_key_links'];
    let key_links_count = key_links.length;
    for (let i = 0; i < key_links_count; i++) {
      let key_link = key_links[i];

      let source_key_id = key_link['source_key_id'];
      let target_key_id = key_link['target_key_id'];
      let access_data = key_link['access_data'];
      console.debug('process key link ' + source_key_id + '->' + target_key_id);

      // TODO: Check whether wrapped key conflicts with key present in crypto manager.

      if ('access_data' in key_link && !crypto_manager.has_key(target_key_id)) {
        console.debug('unpack ' + target_key_id + ' from ' + source_key_id);

        // Unpack access data.
        let {key_id: unpackedKeyId, ciphertext: unpackedCiphertext} = unpackEncryptedBytes(access_data);

        if (crypto_manager.has_key(source_key_id)) {
          // Add unpacked key to crypto manager so that we can decrypt data.
          crypto_manager.add_encrypted_key(target_key_id, unpackedCiphertext, unpackedKeyId);
        } else if (ignore_errors) {
          console.debug('unpack failed... this is fine');
        } else {
          throw new Error("Could not unpack access data from: " + key_link);
        }

      } else {
        // Ensure existence of source and target key.
        source_key_id = process_key(source_key_id);
        target_key_id = process_key(target_key_id);

        /* Create access data now. */
        if (source_key_id && target_key_id) {
          console.debug('wrap key ' + target_key_id + ' with ' + source_key_id);

          // Generate access data.
          let encrypted_target_key = crypto_manager.wrap_key(target_key_id, source_key_id);
          access_data = packEncryptedBytes({
            key_id: source_key_id,
            ciphertext: encrypted_target_key,
          });

          // Update key graph.
          key_link['access_data'] = access_data;
        } else if (ignore_errors) {
          console.debug('failed processing key link... will try again later');
        } else {
          throw new Error("Invalid key link: " + key_link);
        }
      }
    }

    return key_graph;
  }
};

$(document).ready(function () {
  // collect entropy whenever the user moves the mouse
  $(this).on('mousemove', function (e) {
    forge.random.collectInt(e.clientX, 16);
    forge.random.collectInt(e.clientY, 16);
  });
});
