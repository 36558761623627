import React from "react";
import {Tooltip} from "@material-ui/core";
import {connect} from "react-redux";
import {getDecrypted} from "../../features/e2ee";
import {getUser, needUser} from "../../features/entities/user";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Avatar from '@material-ui/core/Avatar';
import {withStyles} from "@material-ui/core/styles";
import classNames from 'classnames';
import {loadLazily} from "packages/redux-helpers";

const styles = theme => ({
  root: {
    //margin: 100,
    // padding: theme.spacing(2),
    // padding: '2px',
    //height: '2em',
    //width: '2em',
  },
});


export class UnstyledUserAvatar extends React.Component {
  // static props = {
  //   showText: false,
  //   user: undefined,
  //   noSelf: false,
  //   light: false,
  // };

  render() {
    const {id, gender, avatar_id, email, title, salutation, is_staff, readableId, is_viewer, showText, userId, hideTooltip, noSelf, dispatch, className = '', light, classes, theme, ...other} = this.props;

    // Render first character of salutation if accessible.
    const label = salutation ? salutation.charAt(0).toUpperCase() : null;

    let avatarLabel = (
      (label !== null && label.length > 0) ? (
        <>{label}</>
      ) : (
        <FontAwesomeIcon icon={
          is_staff ? (
            ['fas', 'user-tie']
          ) : id ? (
            ['fas', 'user']
          ) : (
            ['far', 'user']
          )
        }/>
      )
    );

    if (!showText) {
      avatarLabel = <span className='user-badge-outer-hover-only avatar-label-hidden-until-hover'>{avatarLabel}</span>;
    }

    const avatarClasses = classNames(
      classes.root,
      className,
      {
        avatar: true,
        'user-badge': true,
        ['user-' + ((is_viewer && !noSelf) ? 0 : avatar_id)]: true,
        light: light,
      },
    );

    return (
      <Avatar className={avatarClasses} style={{display: 'inline-flex', verticalAlign: 'middle'}} {...other}>
        {avatarLabel}
      </Avatar>
    );
  }
}

export const UserAvatar = withStyles(styles, {withTheme: true})(UnstyledUserAvatar);

export class UserAvatarWithTooltip extends React.Component {
  // static props = {
  //   user: undefined,
  //   hideTooltip: false,
  // };

  getTooltip() {
    const {gender, email, title, salutation, is_staff, readableId, is_viewer, hideTooltip} = this.props;
    if (hideTooltip) {
      return undefined;
    }

    //const {gender, email, title, salutation, is_staff, readableId, is_viewer} = user;

    if (is_viewer) {
      return "ich selbst";
    }

    let userSalutation = salutation;
    if (title) {
      userSalutation = title + ' ' + salutation;
    }
    switch (gender) {
      case 'm':
        userSalutation = 'Herr ' + userSalutation;
        break;
      case 'f':
        userSalutation = 'Frau ' + userSalutation;
        break;
    }
    let userLabel = userSalutation ? (userSalutation + (email ? ' (' + email + ')' : '')) : email;

    if (userLabel && is_staff) {
      return (gender === 'f' ? "Mitarbeiterin" : "Mitarbeiter") + " " + userLabel;
    } else if (userLabel) {
      return userLabel;
    } else if (is_staff) {
      return (gender === 'f' ? "Mitarbeiterin" : "Mitarbeiter") + " #" + readableId;
    } else if (readableId) {
      return "Nutzer #" + readableId;
    } else {
      return "unbekannter Nutzer";
    }
  }

  render() {
    const {tooltip = this.getTooltip(), tooltipPlacement = 'top', ...avatarProps} = this.props;

    if (!tooltip) {
      return (
        <UserAvatar {...avatarProps}/>
      );
    }

    return (
      <Tooltip title={tooltip || this.getTooltip()} placement={tooltipPlacement} arrow>
        <UserAvatar {...avatarProps}/>
      </Tooltip>
    );
  }
}

export const UserAvatarContainer = connect(
  (state, {userId}) => {
    const {id, gender, email, title, salutation, is_staff, readableId, is_viewer, avatar_id} = getDecrypted(getUser(userId))(state) || {};

    return {
      id, gender, email, title, salutation, is_staff, readableId, is_viewer, avatar_id,
    };
  },
)(loadLazily({
  id: {
    sourcePropNames: ['userId'],
    loader: (dispatch, {userId}) => userId && dispatch(needUser({id: userId})),
  },
})(UserAvatarWithTooltip));
