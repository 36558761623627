import {createBuffer, decode64, decodeUtf8, encode64, encodeUtf8} from 'node-forge/lib/util';
import {parse} from 'content-type';

/**
 * Convert Uint8Array into something that behaves like a forge buffer object.
 * @param array Uint8Array
 * @returns forge-buffer-compatible object
 */
export function uint8ArrayToForgeBuffer(array) {
  return {
    data: array,
    size: array.length,
    read: 0,
    length() {
      return this.size - this.read;
    },
    getBytes(n) {
      if (n === undefined || n > this.length()) {
        n = this.length();
      }
      let buffer = createBuffer();
      for (let i = 0; i < n; i++) {
        buffer.putByte(this.data[this.read + i]);
      }
      this.read += n;
      return buffer.bytes();
    },
    bytes(n) {
      const read = this.read;
      const result = this.getBytes(n);
      this.read = read;
      return result;
    },
  };
}

export const arrayBufferToForgeBuffer = buffer => uint8ArrayToForgeBuffer(new Uint8Array(buffer));

export const arrayBufferToBytes = buffer => arrayBufferToForgeBuffer(buffer).bytes();

export const bytesToUint8Array = str => Uint8Array.from(str, c => c.charCodeAt(0) & 0xFF);

export const uint8ArrayToBytes = array => uint8ArrayToForgeBuffer(array).getBytes();

export const stringToBytes = encodeUtf8;
export const bytesToString = decodeUtf8;

export const bytesToBase64 = encode64;
export const base64ToBytes = decode64;

/**
 * Load blob's content into a binary string.
 * @param blob
 * @returns Promise
 */
export const loadBlobBytes = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = evt => {
      let result = evt.target.result;
      try {
        if (result instanceof ArrayBuffer) {
          result = arrayBufferToBytes(result);
        }
        resolve(result);
      } catch (e) {
        reject(e);
      }
    };
    reader.onerror = function (evt) {
      reader.abort();
      reject(evt.target.error);
    };

    // readAsBinaryString is not supported by all browsers, so we fall back to readAsArrayBuffer in that case.
    try {
      reader.readAsBinaryString(blob);
    } catch (e) {
      reader.readAsArrayBuffer(blob);
    }
  });
};

/**
 * Load blob's content into a string.
 * @param blob
 * @returns Promise
 */
export const loadBlobString = blob => {
  // Determine character set.
  const {parameters} = parse(blob.type);
  const {charset} = parameters;
  const normalizedCharset = charset !== undefined ? charset.toLowerCase() : undefined;
  const isUTF8 = (normalizedCharset === 'utf8' || normalizedCharset === 'utf-8');

  // If charset is utf8, we might be able to use Blob's text() function.
  if (isUTF8 && blob.text) {
    return blob.text();
  }

  // Otherwise we load the binary string and convert it approriately.
  return loadBlobBytes(blob).then(bytes => {
    if (isUTF8) {
      return bytesToString(bytes);
    } else if (normalizedCharset === 'us-ascii') {
      return bytes;
    }

    // For unknown charsets, try utf8 and fall back to ascii.
    try {
      return bytesToString(bytes);
    } catch (e) {
      return bytes;
    }
  });
};

/**
 * Provide progress information for a ReadableStream.
 * @param readerOrStream ReadableStream object or reader.
 * @param progressCallback Callback to be called whenever new data is enqueued.
 * @param errorHandler Callback to be called whenever an error occurs.
 * @returns ReadableStream
 */
export function createProgressReadableStream(
  readerOrStream,
  progressCallback = progress => progress,
  errorHandler = err => err,
) {
  let reader = readerOrStream;
  let offset = 0;
  return new ReadableStream({
    start(controller) {
      if (reader instanceof ReadableStream) {
        reader = readerOrStream.getReader();
      }
    },
    pull(controller) {
      return reader.read().then(({done, value}) => {
        if (!done) {
          controller.enqueue(value);
          offset += value.length;
          progressCallback(offset);
        } else {
          controller.close();
          reader.releaseLock();
        }
      }).catch(e => {
        controller.error(e);
        reader.releaseLock();
        errorHandler(e);
      });
    },
    cancel(reason) {
      reader.cancel(reason);
    },
  });
}
