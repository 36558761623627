import React from 'react';
import ReactDOM from 'react-dom';

import {forEachMatchingElement, uimanager} from 'ui-manager';
import DecryptedText from "../components/data/text/decrypted-text-redux";
import {preprocess} from "../features/e2ee/crypto-fragments";
import {store} from "../stores";
import {Provider} from "react-redux";
import {theme} from "../ui/theme";
import {MuiThemeProvider} from "@material-ui/core/styles";

uimanager.add(el =>
  forEachMatchingElement(el, "[data-js-security-encrypted-content]", node => {
    const encryptedContent = JSON.parse(node.dataset.jsSecurityEncryptedContent);
    const cryptoFragment = preprocess({encrypted_string: encryptedContent}, store.dispatch);

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <DecryptedText cryptoFragment={cryptoFragment}/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);
