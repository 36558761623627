import React from 'react';
import DocumentListEmptyInfo from "./document-list-empty-info-view";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import {DocumentRowMenu, SelectedDocumentsMenu} from "./menu";
import BulkSelectCheckBox from './bulk-select-checkbox-view';
import OrderableTableHeader from './orderable-table-header-view';
import Dropzone from "./dropzone-redux";
import DocumentRow from "./document-row-redux";

export default props => {
  const {
    caseId, selectedIds, visibleSelectedIdsCount, isEverythingDisplayed, isEverythingVisibleSelected, isAnythingVisibleSelected, unselectPage, unselectAll,
    draftDocumentIds, recentlyUploadedIds, documentIds,
    caseIsEmpty, canUpload, showDropzone, dropzoneIsVisible,
    listingId,
    bulkActionActiveOrRecent,
    bulkSuccessful,
    bulkError,
    bulkProgressPercentage,
    bulkProgressSuccessful,
    bulkActionProgressMessageTemplate = "{{SUCCESSFUL}} von {{TOTAL}} Dokumenten verarbeitet",
    bulkActionTotalFailureMessageTemplate = "{{TOTAL}} Dokumente nicht verarbeitet",
    bulkProgressTotal,
    selectPage,
    dropzone,
    emptyContent,
    loadingFailed,
    update,
    uploadFiles,
    toggleItem,
  } = props;
  const selectedId = selectedIds.length === 1 ? selectedIds[0] : undefined;

  const visibleDocumentIds = [...draftDocumentIds, ...recentlyUploadedIds, ...documentIds];

  const progressMessage = bulkActionProgressMessageTemplate.replace(
    '{{SUCCESSFUL}}', bulkProgressSuccessful,
  ).replace(
    '{{TOTAL}}', bulkProgressTotal,
  );
  const totalFailureMessage = bulkActionTotalFailureMessageTemplate.replace(
    '{{TOTAL}}', bulkProgressTotal,
  );

  return (
    <table className="table table-hover tablesorter tablesorter-mavora documents-table nomargin"
           style={{marginBottom: 0}}>
      {(visibleDocumentIds.length > 0 || selectedIds.length > 0) ? (
        <thead>
        <tr
          className={"tablesorter-headerRow hoverable " + (
            loadingFailed ? (
              'alert-danger'
            ) : (selectedIds.length > 0 || bulkActionActiveOrRecent) ? (
              !bulkActionActiveOrRecent ? (
                'alert-info'
              ) : bulkSuccessful ? (
                'alert-success'
              ) : bulkError ? (
                'alert-danger'
              ) : (
                'alert-warning'
              )
            ) : ''
          )}
        >
          <th style={{padding: 0}}>
            {!loadingFailed ? (
              <BulkSelectCheckBox isSelected={isAnythingVisibleSelected} isPartiallySelected={!isEverythingVisibleSelected} onUnselect={unselectPage} onSelect={selectPage} className={selectedIds.length > 0 ? null : "hover-only"}/>
            ) : null}
          </th>
          <th className={(selectedIds.length > 0 || bulkActionActiveOrRecent) ? 'text-center' : null}>
            {loadingFailed ? (
              <>
                <span>Die Dokumentliste konnte nicht abgerufen werden. </span>
                {update ? (
                  <IconButton size="small" onClick={update}>
                    <FontAwesomeIcon icon={['fa', 'sync-alt']}/>
                  </IconButton>
                ) : null}
              </>
            ) : (selectedIds.length > 0 || bulkActionActiveOrRecent) ? (
              bulkActionActiveOrRecent ? (
                bulkSuccessful ? (
                  `${progressMessage} (${Math.ceil(bulkProgressPercentage * 100)}%)`
                ) : (bulkError && bulkProgressSuccessful > 0) ? (
                  `${progressMessage} (${bulkProgressTotal - bulkProgressSuccessful} fehlgeschlagen)`
                ) : bulkError ? (
                  `Vorgang fehlgeschlagen (${totalFailureMessage})`
                ) : (
                  `${progressMessage}... (${Math.ceil(bulkProgressPercentage * 100)}%)`
                )
              ) : (visibleSelectedIdsCount === selectedIds.length) ? (
                isEverythingDisplayed ? (
                  (selectedIds.length === 1) ? (
                    "1 Dokument ausgewählt"
                  ) : (
                    `${selectedIds.length} Dokumente ausgewählt`
                  )
                ) : (
                  (selectedIds.length === 1) ? (
                    "1 Dokument auf dieser Seite ausgewählt"
                  ) : (
                    `${selectedIds.length} Dokumente auf dieser Seite ausgewählt`
                  )
                )
              ) : (visibleSelectedIdsCount > 0) ? (
                `${selectedIds.length} Dokumente ausgewählt, davon ${visibleSelectedIdsCount} auf dieser Seite`
              ) : (
                <>
                  {(selectedIds.length === 1) ? (
                    "1 Dokument auf einer anderen Seite ausgewählt "
                  ) : (
                    `${selectedIds.length} Dokumente auf anderen Seiten ausgewählt `
                  )}
                  <Button
                    onClick={unselectAll}
                    color="primary"
                    style={{display: 'inline', padding: 0, paddingLeft: 4, paddingRight: 4, lineHeight: '1rem'}}
                    size='small'
                  >
                    Auswahl aufheben
                  </Button>
                </>
              )
            ) : (
              "Dokument"
            )}
          </th>
          <th style={(selectedIds.length > 0 || bulkActionActiveOrRecent || loadingFailed) ? {opacity: 0.3} : null}>Status</th>
          <OrderableTableHeader name='Größe' orderingKey='size' className='hidden-xs' style={(selectedIds.length > 0 || bulkActionActiveOrRecent || loadingFailed) ? {opacity: 0.3} : null} {...props}/>
          <OrderableTableHeader name='Hinzugefügt' orderingKey='creation_date' className='hidden-xs' style={(selectedIds.length > 0 || bulkActionActiveOrRecent || loadingFailed) ? {opacity: 0.3} : null} {...props}/>
          <th className="visible-xs" colSpan={2}/>
          <th style={{padding: 5, paddingTop: 3, paddingBottom: 3}}>
            {(selectedIds.length > 0 && !loadingFailed) ? (
              selectedId ? (
                <DocumentRowMenu {...props} documentId={selectedId} caseId={caseId}/>
              ) : (
                <SelectedDocumentsMenu caseId={caseId} listingId={listingId}/>
              )
            ) : null}
          </th>
        </tr>
        </thead>
      ) : null}

      <tbody>
      {dropzone !== undefined ? dropzone : <Dropzone caseId={caseId} uploadFiles={uploadFiles}/>}
      {visibleDocumentIds.length === 0 && !dropzoneIsVisible ? (
        emptyContent !== undefined ? emptyContent : (
          <DocumentListEmptyInfo caseIsEmpty={caseIsEmpty} canUpload={canUpload} showDropzone={showDropzone}/>
        )
      ) : null}
      {visibleDocumentIds.map((documentId, index) =>
        <DocumentRow
          key={documentId || index}
          documentId={documentId}
          listingId={listingId}
          toggleItem={toggleItem}
          showSelect={selectedIds.length > 0}
          loadingFailed={loadingFailed}
        />
      )}
      </tbody>
    </table>
  );
};
