import {createAction, createSlice} from "@reduxjs/toolkit";

const mailSlice = createSlice({
  name: 'mail',
  initialState: {
  },
  reducers: {
  },
});

export const {} = mailSlice.actions;

export const checkPendingMails = createAction('mail/checkPending');

export default {
  mails: mailSlice.reducer,
};
