import React from 'react';
import ReactDOM from 'react-dom';
import {NaturalTime} from '../components/data/time';
import {forEachMatchingElement, uimanager} from 'ui-manager';
import {theme} from "../ui/theme";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {Unshrinkable} from "../components/layout/helpers/Unshrinkable";

uimanager.add(el =>
  // Instantiate react-timeago components.
  forEachMatchingElement(el, ".time-ago", node => {
    const {date, inSentence} = node.dataset;
    ReactDOM.render(
      <MuiThemeProvider theme={theme}>
        <Unshrinkable><NaturalTime date={date} inSentence={inSentence}/></Unshrinkable>
      </MuiThemeProvider>,
      node,
    );
  })
);
