import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import {MDCMenuSurface} from "@material/menu-surface/component";
// This component depends on mdc ui.
import 'ui/materialdesign';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import clsx from "clsx";

class PopOverSurface extends React.Component {
  static propTypes = {
    content: PropTypes.node.isRequired,
    open: PropTypes.bool,
    quickOpen: PropTypes.bool,

    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    open: false,
    quickOpen: true,
  };

  componentDidMount() {
    const {open, quickOpen} = this.props;

    const mdcMenuSurface = new MDCMenuSurface(this.mdcMenuSurfaceDiv);
    mdcMenuSurface.setMenuSurfaceAnchorElement(this.anchorDiv);
    mdcMenuSurface.hoistMenuToBody();
    mdcMenuSurface.quickOpen = quickOpen;
    this.mdcMenuSurfaceDiv.addEventListener('MDCMenuSurface:closed', this.handleClose);
    this.mdcMenuSurface = mdcMenuSurface;
  }

  componentWillUnmount() {
    this.mdcMenuSurfaceDiv.removeEventListener('MDCMenuSurface:closed', this.handleClose);
    this.mdcMenuSurface.destroy();
  }

  componentDidUpdate() {
    const {open} = this.props;
    const {mdcMenuSurface} = this;
    if (mdcMenuSurface.isOpen() !== open) {
      if (open) {
        mdcMenuSurface.open();
      } else {
        mdcMenuSurface.close();
      }
    }
  }

  handleClose = () => {
    const {open, onClose} = this.props;

    // Trigger onClose event.
    if (open) {
      onClose();
    }
  };

  render() {
    const {content, children} = this.props;

    return (
      <div ref={el => this.anchorDiv = el}>
        {children}
        <div
          ref={el => this.mdcMenuSurfaceDiv = el}
          className="mdc-menu-surface"
          style={{
            marginTop: "-7px",
            padding: "15px",
            paddingTop: "5px",
            paddingBottom: "5px",
            maxWidth: "350px",
            zIndex: 10000,
          }}
        >
          <IconButton
            className="mdc-menu-surface--anchor"
            aria-label="Close"
            style={{position: 'absolute', right: '5px', top: '5px'}}
            onClick={this.handleClose}
          >
            <FontAwesomeIcon icon={['fas', 'times']} style={{minWidth: '1em'}}/>
          </IconButton>
          {content}
        </div>
      </div>
    )
  }
}

export class PopOverButton extends React.Component {
  static propTypes = {
    content: PropTypes.node.isRequired,
    icon: PropTypes.node.isRequired,
    loadTimeoutMs: PropTypes.number,

    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    tabIndex: 0,

    onOpen: () => {
    },
    onClose: () => {
    },
  };

  state = {
    open: false,
    loadingTimedOut: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {open} = this.state;
    const prevOpen = prevState.open;

    if (open && !prevOpen) {
      // Reset loading timeouts.
      clearTimeout(this.loadTimeout);
      this.setState({loadingTimedOut: false});

      // Make sure that surface is displayed after timeout, irrespective of whether loading has finished.
      const {loadTimeoutMs} = this.props;
      if (loadTimeoutMs) {
        this.loadTimeout = setTimeout(() => {
          this.setState({loadingTimedOut: true});
        }, loadTimeoutMs);
      }
    }

    // Trigger events.
    const {onOpen, onClose} = this.props;
    if (open && !prevOpen) {
      onOpen();
    } else if (!open && prevOpen) {
      onClose();
    }
  }

  render() {
    const {content, loading, label, icon, loadTimeoutMs, onOpen, onClose, className, ...props} = this.props;
    const {open, loadingTimedOut} = this.state;

    return (
      <PopOverSurface
        content={content}
        open={open && (!loading || loadingTimedOut)}
        onClose={() => this.setState({open: false})}
      >
        <IconButton
          className={clsx("mdc-menu-surface--anchor", className)}
          aria-label={label}
          onClick={() => this.setState(({open}) => ({open: !open}))}
          {...props}
        >
          {open && loading ? (
            <FontAwesomeIcon icon={['fas', 'spinner']} spin/>
          ) : (
            icon
          )}
        </IconButton>
      </PopOverSurface>
    );
  }
}
