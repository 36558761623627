import React from "react";
import DocumentMenuItems from './document-menu-items-view';

export default class DocumentRowMenu extends React.Component {
  render() {
    return (
      <div className="btn-group btn-group-sm" role="group">
        <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className="caret"/>
        </button>
        <ul className="dropdown-menu dropdown-menu-right">
          <DocumentMenuItems {...this.props}/>
        </ul>
      </div>
    );
  }
}
