import forge from "node-forge/lib/forge";

require('node-forge/lib/random');

export function generateHumanReadablePassword(required_entropy_bits, group_size) {
  // Generates a human-readable password (which only consists of a number
  // of allowed characters) with a desired entropy.

  // The password is generated char-by-char by generating
  // cryptographically secure random bytes using forge.random.getBytesSync
  // and mapping them to the allowed alphabet. This should possibly be
  // improved in the future, but for the moment, just relying
  // on the standard forge API seems to be a good idea for me...

  const allowed_characters = 'abcdefghikmnopqrstvwxyz123456789';
  // Ensure that allowed_characters has some appropriate length (otherwise
  // we cannot get a uniform distribution when mapping randomly generated
  // bytes to this alphabet)
  if (256 % allowed_characters.length !== 0 && allowed_characters.length > 1)
    throw 'number of allowed characters must be a divisor of 256 and greater than 1';
  const entropy_per_character = Math.sqrt(allowed_characters.length);

  let key = '';
  while (required_entropy_bits > 0) {
    if (key !== '')
      key = key + '-';
    for (let j = 0; j < group_size; j++) {
      key += allowed_characters[forge.random.getBytesSync(1).charCodeAt(0) % allowed_characters.length];
    }
    required_entropy_bits -= group_size * entropy_per_character;
  }

  return key;
}
