import {createAction, createSelector, createSlice} from "@reduxjs/toolkit";
import {getEntities} from "../entities-selectors";

const caseSlice = createSlice({
  name: 'case',
  initialState: {
    byId: {},
  },
  reducers: {
    setCase(state, action) {
      const {id, ...other} = action.payload;
      if (id === undefined) {
        return;
      }
      state.byId[id] = {id, ...other};
    },
    updateCase(state, action) {
      const {id, ...data} = action.payload;
      if (id === undefined) {
        return;
      }
      Object.assign(state.byId[id], data);
    },
  },
});

export const {setCase, updateCase} = caseSlice.actions;

export const needCase = createAction('case/needCase');
export const fetchCase = createAction('case/fetchCase');

export default {
  cases: caseSlice.reducer,
};

const getCases = createSelector(
  getEntities,
  entities => entities.cases,
);

export const getCasesById = createSelector(
  getCases,
  cases => cases.byId,
);

const defaultCase = {};

export const getCase = id => createSelector(
  getCasesById,
  cases => cases[id] || defaultCase,
);

export const isCaseEmpty = id => createSelector(
  getCase(id),
  caseData => caseData.documents_count === 0,
);

export const canUpload = id => {
  const getConcreteCase = getCase(id);
  return createSelector(
    getConcreteCase,
    myCase => myCase ? myCase.can_upload : false,
  );
};
