import {all} from 'redux-saga/effects';
import {saga as caseSaga} from './case';
import {saga as documentSaga} from './document';
import {saga as mailSaga} from './mail';
import {saga as userSaga} from './user';

export default function* uiSaga() {
  yield all([
    caseSaga(),
    documentSaga(),
    mailSaga(),
    userSaga(),
  ]);
}
