import React from "react";
import {connect} from "react-redux";
import {getDecrypted} from "../../features/e2ee";
import {getUser, needUser} from "../../features/entities/user";
import {loadLazily} from "packages/redux-helpers";
import DocumentUploaderAvatar from "./document-uploader-avatar-view";

const mapStateToProps = (state, {userId}) => {
  const {id, gender, email, title, salutation, is_staff, readableId, is_viewer, avatar_id} = getDecrypted(getUser(userId))(state) || {};

  return {
    id, gender, email, title, salutation, is_staff, readableId, is_viewer, avatar_id,
  }
};

export default connect(
  mapStateToProps,
)(loadLazily({
  id: {
    sourcePropNames: ['userId'],
    loader: (dispatch, {userId}) => userId && dispatch(needUser({id: userId})),
  },
})(DocumentUploaderAvatar));
