import React from "react";
import Typography from "@material-ui/core/Typography";
import {DecryptedText} from "../data/text";
import {MultiLineSkeleton} from "../layout/skeleton/MultiLineSkeleton";

const style = {whiteSpace: 'pre-line'};

export default ({content, size, onRender}) => (
  <Typography variant="body1" style={style} data-document-element="content">
    <DecryptedText
      text={content}
      decrypting={<MultiLineSkeleton size={size}/>}
      onRender={onRender}
    />
  </Typography>
);
