import React from 'react';
import ReactDOM from 'react-dom';

import {forEachMatchingElement, uimanager} from 'ui-manager';
import {store} from "../stores";
import {Provider} from "react-redux";
import {theme} from "../ui/theme";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {Tooltip} from "@material-ui/core";
import {WrapDOM} from "../components/ui-tabs";

uimanager.add(el =>
  forEachMatchingElement(el, "[data-toggle='tooltip']", node => {
    node.addEventListener('mouseenter', () => {
      // For any reason, mouseenter fires more than once despite once flag set.
      if (node.processed) {
        return;
      }
      node.processed = true;

      const placement = node.dataset.placement;
      const title = node.getAttribute('title');
      node.removeAttribute('title');

      const wrapper = document.createElement('span');
      while (node.firstChild) {
        wrapper.appendChild(node.firstChild);
      }

      ReactDOM.render(
        <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <Tooltip title={title} placement={placement} arrow>
              <WrapDOM node={wrapper} span/>
            </Tooltip>
          </MuiThemeProvider>
        </Provider>,
        node
      );

      node.removeAttribute('data-toggle');
    }, {once: true});
  })
);
