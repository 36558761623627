import {forEachMatchingElement, uimanager} from 'ui-manager';

import React from 'react';
import {store} from 'stores';
import {setUser} from "../features/entities/user";
import {setCase} from "../features/entities/case";
import {setDocument} from "../features/entities/document";
import {setURLs} from "../features/ui/navigation";
import {setConfig} from "../features/config";

uimanager.add(el =>
  // Preload data provided by [data-preload] attributes into redux store.
  forEachMatchingElement(el, "[data-preload]", node => {
    for(const {type, data} of JSON.parse(node.dataset.preload)) {
      if (type === 'user') {
        store.dispatch(setUser(data));
      } else if (type === 'case') {
        store.dispatch(setCase(data));
      } else if (type === 'document') {
        store.dispatch(setDocument(data));
      } else if (type === 'urls') {
        store.dispatch(setURLs(data));
      } else if (type === 'config') {
        store.dispatch(setConfig(data));
      } else {
        console.warn(`unsupported object type for preloading: ${type}`);
      }
    }
  })
);
