import React from "react";
import {connect} from "react-redux";
import {getProgress} from "../../features/ui/progress";
import ErrorMessage from "./document-error-view";
import {deleteDocument, saveDocument} from "../../features/entities/document";

export default connect(
  (state, {documentId}) => {
    const downloadProgress = getProgress('downloadDocument', documentId)(state),
      uploadProgress = getProgress('uploadDocument', documentId)(state),
      decryptionProgress = getProgress('decryptDocument', documentId)(state),
      encryptionProgress = getProgress('encryptDocument', documentId)(state),
      saveProgress = getProgress(saveDocument.type, documentId)(state),
      deletionProgress = getProgress(deleteDocument.type, documentId)(state);

    let relevantProgress = {};
    for(const progress of [
      encryptionProgress,
      uploadProgress,
      downloadProgress,
      decryptionProgress,
      saveProgress,
      deletionProgress,
    ]) {
      if(progress.error) {
        relevantProgress = progress;
      }
    }
    const {error, retryAction} = relevantProgress;

    return {
      error,
      retryAction,
    };
  },
  null,
  ({retryAction, ...stateProps}, {dispatch, ...dispatchProps}, ownProps) => {
    const onRetry = retryAction ? (() => dispatch(retryAction)) : undefined;
    return {
      ...ownProps,
      ...stateProps,
      onRetry,
      dispatch,
      ...dispatchProps,
    };
  },
)(ErrorMessage);
