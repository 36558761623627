import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Divider, MenuItem} from "../../menu";

export default (
  {
    markRead,
    markUnread,
    view,
    save,
    reply,
    performDeletion,

    // Stuff that has not yet been migrated to React.
    detailsURL,
    updateURL,
    transferURL,
    deleteURL,
    menuType,
  },
) => (
  <>
    {markRead ? (
      <MenuItem onClick={markRead} glyphicon='sunglasses'>Als gelesen markieren</MenuItem>
    ) : null}
    {markUnread ? (
      <MenuItem onClick={markUnread} glyphicon='sunglasses'>Als ungelesen markieren</MenuItem>
    ) : null}

    {(view || save) && (markUnread || markRead) ? (
      <Divider/>
    ) : null}
    {view ? (
      <MenuItem onClick={view} glyphicon='search'>Anzeigen</MenuItem>
    ) : null}
    {save ? (
      <MenuItem onClick={save} glyphicon='download'>Download</MenuItem>
    ) : null}

    {reply ? <>
      <Divider/>
      <MenuItem onClick={reply} icon={<FontAwesomeIcon icon={['fas', 'reply']}/>}>Antworten...</MenuItem>
    </> : null}

    {updateURL && <>
      <Divider/>
      <MenuItem href={updateURL} glyphicon='pencil'>Bearbeiten...</MenuItem>
    </>}

    {transferURL && <>
      <MenuItem href={transferURL} glyphicon='arrow-right'>Verschieben...</MenuItem>
    </>}

    {performDeletion ? <>
      <Divider/>
      <MenuItem onClick={performDeletion} glyphicon='trash'>Löschen</MenuItem>
    </> : null}

    {deleteURL && <>
      <Divider/>
      <MenuItem
        href='#'
        glyphicon='trash'
        action={menuType === 'row' && 'delete_document'}
        data-click-submit={menuType === 'detail' && '{"action": "delete"}'}
        data-toggle="confirmation"
        data-btn-ok-label="Löschen"
        data-btn-ok-class="btn-xs btn-danger"
        data-btn-cancel-label="Abbrechen"
        data-btn-cancel-class="btn-xs btn-default"
        data-title="Dokument wirklich unwiderruflich löschen?"
      >
        Löschen
      </MenuItem>
    </>}

    {detailsURL && (
      <>
        <Divider/>
        <MenuItem href={detailsURL} glyphicon='info-sign'>Details...</MenuItem>
      </>
    )}
  </>
);
