/*
 * Transfer session key across tabs to improve usability.
 *
 * Relies on localStorage events (i.e., when a tab is opened, the session key is stored in localStorage for a split
 * second, and immediately removed afterwards.
 *
 * Adopted from https://blog.guya.net/security/browser_session/sessionStorage.html and
 * https://stackoverflow.com/questions/20325763/browser-sessionstorage-share-between-tabs
 */
(function () {
  try {
    // Transfer session key across tabs, but only if the user is logged in in both tabs.

    // Determine session key id and abort if we fail to do so.
    let body = document.getElementsByTagName("body")[0];
    if (!body)
      return false;

    let sessionKeyId = body.dataset.sessionKeyId;
    if (!sessionKeyId)
      return false;

    window.addEventListener('storage', function (event) {
      if (event.key === `request_key:${sessionKeyId}`) {
        // Some tab asked for the session key -> send it.
        let sessionKey = sessionStorage.getItem(`key:${sessionKeyId}`);
        if (sessionKey) {
          console.debug(`Received request for session key ${sessionKeyId} from another tab. Provide it.`);
          localStorage.setItem(`key:${sessionKeyId}`, sessionKey);
          localStorage.removeItem(`key:${sessionKeyId}`);
        } else {
          console.debug(`Received request for session key ${sessionKeyId} from another tab. Cannot provide it.`);
        }
      } else if (event.key === `key:${sessionKeyId}` && event.newValue) {
        let previousSessionKey = sessionStorage.getItem(`key:${sessionKeyId}`);
        let providedSessionKey = event.newValue;

        if (providedSessionKey !== previousSessionKey) {
          // Session key is provided by another tab -> import it.
          console.debug(`Session key ${sessionKeyId} has been provided by another tab. Importing it...`);
          sessionStorage.setItem(`key:${sessionKeyId}`, providedSessionKey);

          // If we already had a session key different from the provided one, we need a refresh.
          if (previousSessionKey) {
            window.location.reload();
          }
        }
      }
    });

    if (!sessionStorage.length) {
      // Ask other tabs for session key.
      console.debug("Session storage is empty. Checking if another tab can provide the session key...");
      localStorage.setItem(`request_key:${sessionKeyId}`, Date.now().toString());
      localStorage.removeItem(`request_key:${sessionKeyId}`);
    }
  } catch (e) {
    console.error(e);
  }
})();
