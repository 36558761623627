import orderReducers from './order-slice';

export default {
  ...orderReducers,
};

export * from './order-sagas';
export * from './order-slice';

export {default as saga} from './order-sagas';
