import {call, delay, fork, put, race, select, take} from 'redux-saga/effects';
import {checkPendingMails} from "./mail-slice";
import {updateDocument, uploaded} from "../document";
import $ from "jquery";
import {crypto_manager, MissingKeyError} from "../../../components/mavo-crypto";
import {bytesToBase64} from "../../../packages/binary-data-helpers";
import Cookies from "js-cookie";
import {getSessionUser, setSession} from "../../session";

import log from 'loglevel';

const second = 1000;

const INITIAL_MAIL_CHECK_DELAY = 2 * second;
const MAIL_CHECK_INTERVAL = 60 * second;

class PermanentError extends Error {
  name = 'PermanentError';
}

class TemporaryError extends Error {
  name = 'TemporaryError';
}

function* checkForPendingMails() {
  let tasks = yield new Promise((resolve, reject) => {
    $.getJSON('/api/_emails/tasks/', data => {
      if (data.status !== "success") {
        reject(new TemporaryError(`failed to fetch email tasks from server (status: ${data.status})`));
      } else if (data.tasks && data.tasks.length > 0)
        resolve(data.tasks);
      else {
        resolve([]);
      }
    }).fail(xhr => {
      if (xhr.status === 401 || xhr.status === 403) {
        reject(new PermanentError("user is not authorized"));
      } else {
        reject(new TemporaryError(`failed to fetch email tasks from server (status code: ${xhr.status})`));
      }
    });
  });

  while (tasks.length > 0) {
    const processed_mails = [];

    // process tasks
    for (const task of tasks) {
      // Parse task.
      const {email, wrapped_keys, actions = []} = task;

      try {
        // Process wrapped keys.
        if (wrapped_keys) {
          crypto_manager.process_entity({wrapped_keys});
        }

        // Skip remainder of task unless a corresponding email is provided.
        if (!email) {
          continue;
        }

        // Sanity check.
        if (!email.key_id.startsWith('mail/')) {
          throw `unexpected key id: ${email.key_id}`;
        }

        // If we need to generate a new key for this email, do it.
        if (email.generate_key) {
          // Assign random email-specific key.
          email.key_id = crypto_manager.add_random_key(email.key_id);
        }

        // Perform substitutions.
        email['substitutes'] = {};
        for (const action of actions) {
          if (action.substitute) {
            // Determine plaintext substitution.
            const data = action.substitute;
            const old_string = data['old'];
            const new_string = crypto_manager.process_entity(data['new']);

            // Send encrypted result to server.
            email.substitutes[old_string] = bytesToBase64(crypto_manager.encrypt_string(email.key_id, new_string));
          } else {
            // Non-substitute-actions should be handled by crypto_manager.
            crypto_manager.process_entity(action);
          }
        }

        // Provide encrypted key to server if it has been generated.
        if (email.generate_key) {
          const {access_key_id} = email.generate_key;
          const key_id = email.key_id;

          // Send encrypted email-specific key to server.
          email.encrypted_key = {
            access_key_id,
            access_data: bytesToBase64(crypto_manager.wrap_key(key_id, access_key_id))
          };

          delete email.generate_key;
        }
      } catch (error) {
        if (crypto_manager.missingSessionKey) {
          throw error;
        }
        log.error({msg: "failed to process email task", task, error, errorType: typeof error});
        continue;
      }

      // IMPORTANT: The key the mail is encrypted with is transmitted to the server in plaintext.
      // This is because the server has to forward the plaintext mail to an SMTP server so that
      // it can be actually delivered.
      // The plaintext key, however, is NOT stored in the server's database; it is only kept in
      // memory until the mail has been delivered. To allow later access to the mail and to be
      // able to deal with server restarts, the encrypted key is stored in the server's database.
      email['plaintext_key'] = bytesToBase64(crypto_manager.get_key(email.key_id));

      processed_mails.push(task.email);
    }

    if (processed_mails.length === 0) {
      // Stop if there is nothing to return to the server.
      return;
    }

    // Send processed mails back to server and potentially receive more emails to process.
    tasks = yield new Promise((resolve, reject) => {
      $.ajax({
        url: '/api/_emails/tasks/',
        type: 'POST',
        data: JSON.stringify(processed_mails),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken')
        },
        processData: false,
        timeout: 30000,
        success: function (data) {
          if (data.status !== "success") {
            reject(new TemporaryError("server rejected processed mails"));
          } else if (data.tasks && data.tasks.length > 0) {
            // there are more tasks to process
            resolve(data.tasks);
          } else {
            resolve([]);
          }
        }
      }).fail(xhr => {
        if (xhr.status === 401 || xhr.status === 403) {
          reject(new PermanentError("user is not authorized"));
        } else {
          reject(new TemporaryError(`failed to push resolved emails to server (status code: ${xhr.status})`));
        }
      });
    });
  }
}

function* mailContentProviderSaga() {
  // Saga should only run if a user is logged in.
  while (true) {
    const sessionUser = yield select(getSessionUser);
    if (sessionUser) {
      break;
    }

    // Check again when the session state changes.
    yield take(setSession);
  }

  yield delay(INITIAL_MAIL_CHECK_DELAY);

  while (true) {
    try {
      yield call(checkForPendingMails);
    } catch (error) {
      if (error instanceof PermanentError) {
        console.warn("Stopping resolution of pending emails until refresh due to error: ", error);
        return;
      } else if (error instanceof TemporaryError) {
        console.warn("Resolution of pending emails failed: ", error);
      } else if (error instanceof MissingKeyError && crypto_manager.missingSessionKey) {
        console.warn("Resolution of pending emails failed due to missing session key: ", error);
      } else {
        throw error;
      }
    }

    // Check again on action or after timeout.
    yield race({
      action: take(checkPendingMails),
      timeout: delay(MAIL_CHECK_INTERVAL),
    });
  }
}

function* checkPendingMailsAfterDocumentUploadOrUpdate() {
  while (true) {
    yield take([uploaded, updateDocument]);
    yield put(checkPendingMails());
  }
}

export default function* mailSaga() {
  yield fork(mailContentProviderSaga);
  yield fork(checkPendingMailsAfterDocumentUploadOrUpdate);
}
