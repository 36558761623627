import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getUI} from "../ui-selectors";

const dragDropSlice = createSlice({
  name: 'dragDrop',
  initialState: {
    waitForDrop: false,
  },
  reducers: {
    dragEnter(state, action) {
      state.waitForDrop = true;
    },
    dragLeave(state, action) {
      state.waitForDrop = false;
    },
  },
});

export const {
  dragEnter,
  dragLeave,
} = dragDropSlice.actions;

export default {
  dragAndDrop: dragDropSlice.reducer,
};

const getDragDropState = createSelector(
  getUI,
  ui => ui.dragAndDrop,
);

export const isWaitingForDrop = createSelector(
  getDragDropState,
  dragDrop => dragDrop.waitForDrop,
)
