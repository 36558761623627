import React from "react";
import {connect} from "react-redux";
import {getDecrypted} from "../../../features/e2ee";
import DecryptedText from './decrypted-text-view';

export default connect(
  (state, {cryptoFragment}) => {
    const decryptedFragment = getDecrypted(cryptoFragment)(state);
    return {text: decryptedFragment};
  },
)(DecryptedText);
