import {createAction, createSelector, createSlice} from "@reduxjs/toolkit";
import {getE2EE} from "../e2ee-selectors";
import {postprocess} from "./crypto-fragments-utils";

const cryptoFragmentsSlice = createSlice({
  name: 'cryptoFragment',
  initialState: {
    plaintextById: {},
  },
  reducers: {
    decrypted(state, action) {
      const {cryptoFragmentId, plaintext, error} = action.payload;
      if (error) {
        if (state.plaintextById[cryptoFragmentId] === undefined) {
          state.plaintextById[cryptoFragmentId] = NaN;
        }
      } else {
        state.plaintextById[cryptoFragmentId] = plaintext;
      }
    },
  },
});

export const {decrypted} = cryptoFragmentsSlice.actions;

export const decrypt = createAction('cryptoFragment/decrypt');

export default {
  cryptoFragments: cryptoFragmentsSlice.reducer,
};

const getDecryptedFragments = createSelector(
  getE2EE,
  e2ee => e2ee.cryptoFragments,
)

export const getDecryptedFragmentsById = createSelector(
  getDecryptedFragments,
  cryptoFragments => cryptoFragments.plaintextById,
);

/**
 * Wrapper for selectors or objects that replaces confidential data such as encrypted strings by their corresponding
 * plaintexts if already available in the redux store.
 * @param dataOrSelector Data object or selector.
 */
export const getDecrypted = dataOrSelector => {
  if (dataOrSelector instanceof Function) {
    return state => getDecrypted(dataOrSelector(state))(state);
  }
  return createSelector(
    getDecryptedFragmentsById,
    decryptedFragments => postprocess(dataOrSelector, decryptedFragments).result,
  );
};
