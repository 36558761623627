import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MuiDialog from "@material-ui/core/Dialog";
import React from "react";

export const Dialog = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const {
    fullScreen = useMediaQuery(theme.breakpoints.down('sm')),
    maxWidth = 'md',
    children,
    ...other
  } = props;

  return (
    <MuiDialog
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      ref={ref}
      {...other}
    >
      {children}
    </MuiDialog>
  );
});
