import {decrypt, decrypted, preprocess} from "./crypto-fragments";

export const e2eeMiddleware = store => next => action => {
  const dispatch = store.dispatch;

  // Preprocess all actions, but exclude those generated by e2ee for performance reasons.
  if (action.type !== decrypt.type && action.type !== decrypted.type) {
    action = {...action, payload: preprocess(action.payload, dispatch)};
  }

  return next(action);
};
