import {FAILED_TO_RECEIVE_HELP_TEXT, RECEIVE_HELP_TEXT, REQUEST_HELP_TEXT, SHOW_HELP_TEXT} from "./help-constants";

export function showHelpText(id, apiURL) {
  return {type: SHOW_HELP_TEXT, id, apiURL};
}

export function receiveHelpText(id, data) {
  return {type: RECEIVE_HELP_TEXT, id, data};
}

export function requestHelpText(id, apiURL) {
  return {type: REQUEST_HELP_TEXT, id, apiURL};
}

export function failedToReceiveHelpText(id, error) {
  return {type: FAILED_TO_RECEIVE_HELP_TEXT, id, error};
}
