import {createAction, createSelector, createSlice} from "@reduxjs/toolkit";
import {getUI} from "../ui-selectors";

const progressSlice = createSlice({
  name: 'progress',
  initialState: {},
  reducers: {
    startProgress(state, action) {
      const {type, id, ...progress} = action.payload;
      if (state[type] === undefined) {
        state[type] = {};
      }
      state[type][id] = {
        progress: 0,
        total: 1,
        ...progress,
        started: true,
        inProgress: true,
      };
    },
    updateProgress(state, action) {
      const {type, id, ...updatedProgress} = action.payload;
      const progress = state[type][id];
      if (progress.inProgress) {
        Object.assign(progress, updatedProgress);
      }
    },
    resetProgress(state, action) {
      const {type, id} = action.payload;
      if (state[type] === undefined) {
        state[type] = {};
      }
      delete state[type][id];
    },
    endProgress(state, action) {
      const {type, id, ...updatedProgress} = action.payload;
      const progress = state[type][id];

      // Sanity checks.
      const isError = updatedProgress.error !== undefined;
      const isAbort = updatedProgress.abort;
      const isSuccess = updatedProgress.success;
      if (!isError && !isAbort && !isSuccess) {
        throw new Error(`progress ${type}/${id} cannot end with error, abort and success being false`);
      } else if (isSuccess && (isError || isAbort)) {
        throw new Error(`progress ${type}/${id} cannot end with success but error or abort being true`);
      }

      Object.assign(progress, {
        progress: isSuccess ? progress.total : progress.progress,
        inProgress: false,
        ...updatedProgress,
      });
    },
  },
});

export const {startProgress, updateProgress, resetProgress, endProgress} = progressSlice.actions;

export const reportProgress = createAction('progress/reportProgress');

export default {
  progress: progressSlice.reducer,
};

export const getProgresses = createSelector(
  getUI,
  ui => ui.progress,
);

const defaultProgress = {progress: 0};

export const getProgress = (type, id) => createSelector(
  getProgresses,
  progresses => progresses[type] && progresses[type][id] || defaultProgress,
);
