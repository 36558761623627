import React from "react";

import TimeAgo from 'react-timeago';
import germanStrings from 'react-timeago/lib/language-strings/de';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import dateFormat from 'dateformat';

const formatter = buildFormatter(germanStrings);
const plainTimestampFormatter = inSentence => (value, unit, suffix, epochMilliseconds) =>
  (inSentence ? "am " : "") + dateFormat(epochMilliseconds, "dd.mm.yyyy HH:MM");

export default class NaturalTime extends React.Component {
  static defaultProps = {
    hovered: false,
    title: '',
    inSentence: false,
    date: undefined,
  };

  state = {
    hovered: this.props.hovered || false,
  };

  hover = () => {
    const {onHover} = this.props;
    this.setState(
      {hovered: true},
      () => onHover && onHover(this.state.hovered),
    );
  };

  unhover = () => {
    const {onHover} = this.props;
    this.setState(
      {hovered: false},
      () => onHover && onHover(this.state.hovered),
    );
  };

  render() {
    const {date, title, inSentence} = this.props;
    const {hovered} = this.state;

    return (
      <TimeAgo
        date={date}
        formatter={hovered ? plainTimestampFormatter(inSentence) : formatter}
        title={title}
        onMouseEnter={() => this.hover()}
        onMouseLeave={() => this.unhover()}
      />
    );
  }
}
