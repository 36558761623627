import React from 'react';
import ReactDOM from 'react-dom';

import {forEachMatchingElement, uimanager} from 'ui-manager';
import {store} from "../stores";
import {Provider} from "react-redux";
import {theme} from "../ui/theme";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {ConfirmationDialog} from "../components/dialog";

uimanager.add(el =>
  forEachMatchingElement(el, "[data-confirmation-manager]", node => {
    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <ConfirmationDialog/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);
