import React from "react";

const B = 1;
const KiB = 1024 * B;
const MiB = 1024 * KiB;
const GiB = 1024 * MiB;
const TiB = 1024 * GiB;
const PiB = 1024 * TiB;

const toFixedLocale = (n, digits) => (
  n.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: digits})
);

export default ({value, digits = 1}) => (
  (!Number.isInteger(value)) ? (
    ""
  ) : (value < 0.9 * KiB) ? (
    toFixedLocale(value, 0) + " Bytes"
  ) : (value < 0.9 * MiB) ? (
    toFixedLocale(value / KiB, digits) + " KB"
  ) : (value < 0.9 * GiB) ? (
    toFixedLocale(value / MiB, digits) + " MB"
  ) : (value < 0.9 * TiB) ? (
    toFixedLocale(value / GiB, digits) + " GB"
  ) : (value < 0.9 * PiB) ? (
    toFixedLocale(value / TiB, digits) + " TB"
  ) : (
    toFixedLocale(value / PiB, digits) + " PB"
  )
);
