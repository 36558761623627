import React from "react";
import {connect} from "react-redux";
import {cancel, confirm, getVisibleConfirmation} from "../../features/ui/confirmation";
import ConfirmationDialog from './confirmation-dialog-view';

export default connect(
  (state, {}) => {
    const confirmationDialog = getVisibleConfirmation(state);
    if (!confirmationDialog) {
      return {};
    }
    return {
      ...confirmationDialog,
      show: true,
    };
  },
  (dispatch, {}) => {
    return {
      onConfirm: () => dispatch(confirm()),
      onCancel: () => dispatch(cancel()),
    };
  },
)(ConfirmationDialog);
