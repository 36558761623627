/* MavoDB widget
 *
 * Caches some data (e.g., about cases or clients) in sessionStorage to allow searching on and sorting of encrypted data.
 *
 *  Copyright 2017 Dominik Leibenger
 */

import {base64ToBytes} from "../../../packages/binary-data-helpers";
import './statemachine.js';
import $ from 'jquery';

import {crypto_manager} from 'mavo-crypto';

$.widget("solesoftware.mavodb", $.solesoftware.statemachine, {
  /*
     * Name of this state machine as used in statemachine-specific data attributes, e.g., data-statemachine-action.
     */
  name: 'mavodb',

  // options
  options: {},

  /*
     * State machine that specifies all possible states and transitions
     */

  _states: {
    // Start state
    no_data: {
      initial: true,
      transitions: {
        load_data: 'load_data',
        fetch_data: 'fetch_data'
      }
    },

    load_data: {
      action: '_action_load_data',
      transitions: {
        success: 'up_to_data',
        fetch_data: 'fetch_data',
        error: 'fetch_data'
      }
    },

    fetch_data: {
      action: '_action_fetch_data',
      transitions: {
        success: 'up_to_date',
        fetch_data: 'fetch_data',
        error: 'error'
      }
    },

    up_to_date: {
      action: '_action_up_to_date',
      transitions: {}
    },

    error: {
      action: '_action_error',
      transitions: {
        fetch_data: 'fetch_data'
      }
    }
  },

  /*
   * Initialization and other plugin-related functions.
   */

  _create: function () {
    let that = this;

    /*
     * Initialize data structure
     */

    // Data that does not contain any confidential information is stored here.
    this.data_public = $.parseJSON(this.element.attr('data-mavodb-data'));
    if (this.data_public === undefined)
      this.data_public = {};

    // Data only known to the service operator are stored here.
    let OperatorPrivateData = function () {
    };
    OperatorPrivateData.prototype = this.data_public;
    this.data_private_operator = new OperatorPrivateData();

    // Data only known to people in possession of the correct key are stored here.
    let ViewerPrivateData = function () {
    };
    ViewerPrivateData.prototype = this.data_private_operator;
    this.data_private_viewer = new ViewerPrivateData();

    // Data only known to the uploader is stored here.
    let OwnerPrivateData = function () {
    };
    OwnerPrivateData.prototype = this.data_private_viewer;
    this.data_private_owner = new OwnerPrivateData();

    /*
     * Extract information from data attributes and switch into appropriate state.
     */

    this.options.csrftoken = this.options.csrftoken || this.element.data('csrftoken');

    this._super();
  },

  /*
   * Actions.
   */

  _action_error: function (fire) {
    // retry after a while
    setTimeout(function () {
      fire('fetch_data');
    }, 10000);
  },

  _action_load_data: function (fire) {
    // load data from cache
    if (typeof (Storage) !== "undefined") {
      let session_key_id = $('body').attr('data-session-key-id');
      this.data_public.session_key_id = sessionStorage.getItem('cache:' + this.data_public.dbname + ':session_key_id');
      if (session_key_id !== this.data_public.session_key_id) {
        // clear session storage because it is outdated
        sessionStorage.removeItem('cache:' + this.data_public.dbname + ':data');
        sessionStorage.removeItem('cache:' + this.data_public.dbname + ':timestamp');
        if (session_key_id)
          sessionStorage.setItem('cache:' + this.data_public.dbname + ':session_key_id', session_key_id);
      }

      let data = sessionStorage.getItem('cache:' + this.data_public.dbname + ':data');
      if (data)
        this.data_private_viewer.data = $.parseJSON(data);
      this.data_public.timestamp = sessionStorage.getItem('cache:' + this.data_public.dbname + ':timestamp');
    } else {
      // without sessionStorage, we have to fetch the data on each request, but we can work with that...
    }

    // TODO: determine whether we have to fetch updates

    // fetch updates
    fire('fetch_data');
  },

  _action_fetch_data: function (fire) {
    let that = this;

    let url = this.data_public.api_url;
    if (this.data_public.timestamp)
      url = url + '?since=' + encodeURIComponent(this.data_public.timestamp);

    $.getJSON(url, function (data) {
      let new_data = undefined;

      if(!data) {
        throw "invalid (empty) response from server";
      } else if (data.data !== undefined) {
        that.data_private_viewer.data = {};
        new_data = data.data;
      } else if (data.changed_data !== undefined) {
        new_data = data.changed_data;
        if (that.data_private_viewer.data === undefined)
          throw "cannot process incremental database update";
      } else
        throw "invalid response from server";

      // process new data
      let success = true;
      for (let data_key in new_data)
        if (new_data.hasOwnProperty(data_key)) {
          try {
            let data_value = new_data[data_key];

            if (data_value) {
              // determine key_id used for encrypted field values
              let {key_id, wrapped_keys} = data_value;
              if (key_id)
                delete data_value.key_id;

              // process wrapped keys (if any)
              if (wrapped_keys) {
                crypto_manager.process_entity({wrapped_keys});
                delete data_value.wrapped_keys;
              }

              // decrypt encrypted fields
              let data_object = {};
              for (let field_name in data_value)
                if (data_value.hasOwnProperty(field_name)) {
                  let field_value = data_value[field_name];
                  if (field_name.indexOf('encrypted_') === 0) {
                    let plain_field_name = field_name.substring(10);

                    if (field_value) {
                      try {
                        data_object[plain_field_name] = crypto_manager.decrypt_string(key_id, base64ToBytes(field_value), true);
                      } catch (e) {
                        data_object[plain_field_name] = '';
                      }
                    } else
                      data_object[plain_field_name] = field_value;
                  } else
                    data_object[field_name] = field_value;
                }

              // store data object
              that.data_private_viewer.data[data_key] = data_object;
            } else {
              // process deletion
              if (data_key in that.data_private_viewer.data)
                delete that.data_private_viewer.data[data_key];
            }
          } catch (e) {
            console.error('mavodb: failed to decrypt ' + data_key);
            console.error(e);
            success = false;
          }
        }

      if (success) {
        // update timestamp
        that.data_public.timestamp = data.timestamp;

        // store data in cache
        if (typeof (Storage) !== "undefined") {
          try {
            sessionStorage.setItem('cache:' + that.data_public.dbname + ':data', JSON.stringify(that.data_private_viewer.data));
            sessionStorage.setItem('cache:' + that.data_public.dbname + ':timestamp', that.data_public.timestamp);
          } catch (e) {
            // retry in case of exceeded quota
            if (e.code === 22 || e.code === 1014) {
              sessionStorage.removeItem('cache:cases:data');
              sessionStorage.removeItem('cache:clients:data');
              sessionStorage.setItem('cache:' + that.data_public.dbname + ':data', JSON.stringify(that.data_private_viewer.data));
              sessionStorage.setItem('cache:' + that.data_public.dbname + ':timestamp', that.data_public.timestamp);
            }
          }
        } else {
          // without sessionStorage, we have to fetch the data on each request, but we can work with that...
        }

        fire('success');
      } else {
        fire('error');
      }
    }).fail(function () {
      fire('error');
    });
  },

  _action_up_to_date: function (fire) {
    // do nothing
  },

  has_column_value: function (column, search_value) {
    return this.get_id_by_column_value(column, search_value) !== null;
  },

  get_id_by_column_value: function (column, search_value) {
    if (!this.data_private_viewer.data || !search_value)
      return null;

    search_value = search_value.toLowerCase();

    let data = this.data_private_viewer.data;
    for (let key in data)
      if (data.hasOwnProperty(key)) {
        let value = data[key];

        if (value[column].toLowerCase() === search_value)
          return key;
      }

    return null;
  },

  get_ids_for_range: function (order_by, bottom, top) {
    // make sure we have all data available
    if (this._state !== 'up_to_date' && !this.data_private_viewer.data) {
      if (this._state !== 'fetch_data')
        this.fire_sync('fetch_data');
      throw 'database not ready';
    }

    // determine sorting order
    let sort_order = 'asc';
    if (order_by[0] === '-') {
      order_by = order_by.substring(1);
      sort_order = 'desc';
    }

    // determine value parser
    let parser = {
      'format': function (s) {
        return s;
      }
    };
    if (this.data_public.sorter && this.data_public.sorter[order_by])
      parser = $.tablesorter.getParserById(this.data_public.sorter[order_by]);

    // build array to sort...
    let list = [];
    let data = this.data_private_viewer.data;
    for (let key in data)
      if (data.hasOwnProperty(key)) {
        let value = data[key];
        list.push({
          'key': key,
          'sort_value': parser.format(value[order_by]),
          'value': value[order_by]
        });
      }
    // ...and sort it
    // TODO: this sorting is inconsistent to that performed by tablesorter for non-paginated tables
    list.sort(function (a, b) {
      return a.sort_value.localeCompare(b.sort_value);
    });
    if (sort_order === 'desc')
      list.reverse();

    // return request range of ids
    let results = [];
    for (let i = bottom; i <= top; i++)
      if (list[i] !== undefined)
        results.push(list[i].key);
    return results;
  },

  get_ids_by_search_query: function (search_query) {
    // if(max_results === undefined)
    // max_results = 10;

    // split search query into words
    let search_words = search_query.toLowerCase().split(' ');

    let ids = [];

    let data = this.data_private_viewer.data;
    for (let key in data)
      if (data.hasOwnProperty(key)) {
        let value = data[key];

        let found_all = true;
        for (let search_word_i in search_words) {
          let search_word = search_words[search_word_i];

          let found_word = false;
          for (let value_key in value)
            if (value.hasOwnProperty(value_key))
              if (value[value_key].toLowerCase().indexOf(search_word) !== -1)
                found_word = true;

          if (!found_word)
            found_all = false;
        }

        if (found_all) {
          ids.push(key);

          // if(ids.length >= max_results)
          // break;
        }
      }

    return ids;
  },

  get_by_id: function (id) {
    return this.data_private_viewer.data[id];
  }
});
