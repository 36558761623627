import {MESSAGE_WRITER_SET_FIELD} from "./message-writer-constants";

const messageWriter = (messageWriter = {}, action) => {
  const {type, field, payload} = action;
  switch (type) {
    case MESSAGE_WRITER_SET_FIELD:
      return {
        ...messageWriter,
        [field]: payload,
      };

    default:
      return messageWriter;
  }
};

const messageWriters = (messageWriters = {}, action) => {
  const {type, messageId} = action;
  switch (type) {
    case MESSAGE_WRITER_SET_FIELD:
      return {
        ...messageWriters,
        [messageId]: messageWriter(messageWriters[messageId], action),
      };

    default:
      return messageWriters;
  }
};

// Export root reducers.
export default {
  messageWriters
};
