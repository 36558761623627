import {select} from "redux-saga/effects";
import {getConfig} from "../../config";
import {getDecrypted} from "../../e2ee/crypto-fragments";
import {getCase} from "../case";
import {getDocumentOrDraft} from "./document-slice";
import {escapeStringForRegExp} from "../../../packages/regexp-utils";

// Define generic template placeholder layout: {{placeholder}}
const placeholderRe = new RegExp(/({{[A-Za-z_]+}})/, 'g');

// Define specific placeholders.
const FILENAME_PLACEHOLDER = '{{filename}}';
const CASE_NUMBER_PLACEHOLDER = '{{caseNumber}}';

// Replace unsupported characters with -.
const unsupportedCharacterSub = '-';
const unsupportedCharactersRe = new RegExp(/[/\\?%*:|"<>]/, 'g');
const removeUnsupportedCharacters = text => text.replace(unsupportedCharactersRe, unsupportedCharacterSub);

function* generateDocumentNameTemplateSubstitutions(name, documentId) {
  const {documentNameTemplate: template} = yield select(getConfig);
  if (!template) {
    // Nothing to do unless a template is specified.
    return {template};
  }

  // Determine appropriate substitutions for placeholders used in the template.
  let substitutions = {};
  try {
    const foundPlaceholders = template.match(placeholderRe) || [];
    for (const placeholder of foundPlaceholders) {
      switch (placeholder) {
        case FILENAME_PLACEHOLDER:
          // Use filename as-is, without modifications, and allow even unsupported characters (their support depends on
          // the user's OS).
          substitutions[placeholder] = name;
          break;

        case CASE_NUMBER_PLACEHOLDER:
          // Try to determine case number.
          const {case: case_id} = yield select(getDocumentOrDraft(documentId));
          const {case_number} = yield select(getDecrypted(getCase(case_id)));
          if (case_number === undefined) {
            throw `missing case number for document ${documentId}`;
          }
          substitutions[placeholder] = removeUnsupportedCharacters(case_number);
          break;

        default:
          // Fail if we observe an unknown placeholder.
          throw `invalid placeholder in document filename template: ${placeholder}`;
      }
    }
  } catch (e) {
    // If any error occurs, we better not rewrite the file name to avoid left-over placeholders.
    console.warn("Failed to use document filename template:", e);
    return {template};
  }

  return {template, substitutions};
}

/**
 * Given a rewritten document name, return the original name by removing stuff previously introduced by the template.
 */
export function* reverseRewriteDocumentName(name, documentId) {
  const {template, substitutions} = yield generateDocumentNameTemplateSubstitutions(name, documentId);
  if (!substitutions) {
    // No template is specified, thus nothing to (un)rewrite.
    return name;
  }

  // Try to extract file name by matching all other parts of the template.
  let hasObservedFilenamePlaceholder = false;
  const fileNamePartRe = new RegExp('^' + template.replace(placeholderRe, (_, match) => {
    // File name is the only dynamic part. Match it explicitly!
    if (match === FILENAME_PLACEHOLDER) {
      if (!hasObservedFilenamePlaceholder) {
        // Match first occurrence to any string.
        hasObservedFilenamePlaceholder = true;
        return '(.*)';
      } else {
        // Any subsequent occurrence must contain the same file name.
        return '\\1';
      }
    }

    // Other placeholders must match exactly.
    return escapeStringForRegExp(substitutions[match]);
  }) + '$');

  if (!hasObservedFilenamePlaceholder) {
    // File name seems to be missing in template, so we cannot extract it.
    return name;
  }

  // Return extracted file name if the template matched.
  const fileNameMatch = name.match(fileNamePartRe);
  if (fileNameMatch !== null) {
    const extractedFileName = fileNameMatch[1];
    if (extractedFileName !== '' && !extractedFileName.startsWith('.')) {
      return extractedFileName;
    }
  }

  // No match! Return the original file name instead.
  return name;
}

/**
 * Given a document name, rewrite it using the document name template if necessary.
 */
export function* rewriteDocumentName(name, documentId) {
  // First, check if the name is already in rewritten form (this might happen if a user re-uploads a previously
  // downloaded file). The name matches if we are able to extract a shorter file name.
  const extractedName = yield reverseRewriteDocumentName(name, documentId);
  if (extractedName.length < name.length) {
    // Name seems to comply with the specified template. Do not use template again as to avoid cascades.
    return name;
  }

  // Try to apply document name template.
  const {template, substitutions} = yield generateDocumentNameTemplateSubstitutions(name, documentId);
  if (substitutions) {
    return template.replace(placeholderRe, (_, match) => substitutions[match]);
  } else {
    // Either no non-trivial template is provided or an error occurred. In either case, continue with the original name.
    return name;
  }
}
