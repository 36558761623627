export const MissingKeyError = function (keyId) {
  this.message = "access to " + keyId + " is not possible";
  this.displayMessage = "Auf benötigte Schlüssel konnte nicht zugegriffen werden.";
  this.name = "MissingKeyError";
  this.keyId = keyId;
};

export const DecryptionFailedError = function (keyId) {
  this.message = "decryption failed with key id: " + keyId;
  this.displayMessage = "Entschlüsselung fehlgeschlagen";
  this.name = "DecryptionFailedError";
  this.keyId = keyId;
}
