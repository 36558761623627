import {getUI} from "../ui-selectors";
import {createSelector} from "@reduxjs/toolkit";

const getHelpTexts = createSelector(
  getUI,
  ui => ui.helpTexts,
);

const defaultHelpText = {};

export const getHelpText = id => createSelector(
  getHelpTexts,
  helpTexts => helpTexts[id] || defaultHelpText,
);
