import {combineReducers} from "@reduxjs/toolkit";
import caseReducer from "./case";
import documentReducer from "./document";
import mailReducer from "./mail";
import userReducer from "./user";

export default {
  entities: combineReducers({
    ...caseReducer,
    ...documentReducer,
    ...mailReducer,
    ...userReducer,
  }),
};
