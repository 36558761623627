import {createBuffer} from "node-forge/lib/util";
import {base64ToBytes, bytesToBase64, bytesToString, stringToBytes} from "../../binary-data-helpers";

const IV_LENGTH = 12;
const TAG_LENGTH = 16;

/**
 * Pack EncryptedBytes object into binary representation.
 */
export function packEncryptedBytes({key_id, ciphertext}) {
  let packedBuffer = createBuffer();
  packedBuffer.putBytes('(aes-gcm)'); // Scheme.
  packedBuffer.putByte(IV_LENGTH); // Nonce length.
  packedBuffer.putByte(TAG_LENGTH); // MAC length.
  packedBuffer.putBytes(stringToBytes(key_id));
  packedBuffer.putBytes(','); // Separator.
  packedBuffer.putBytes(ciphertext);
  return 'E' + bytesToBase64(packedBuffer.getBytes());
}

/**
 * Unpack bytes into EncryptedBytes object.
 */
export function unpackEncryptedBytes(packed) {
  // Unpack and verify type.
  if (packed[0] !== 'E')
    throw Error("unsupported packed representation");
  packed = packed.substring(1);

  const packedBuffer = createBuffer(base64ToBytes(packed));

  // Unpack and verify scheme.
  const scheme = packedBuffer.getBytes(9);
  if (scheme !== '(aes-gcm)')
    throw Error(`unsupported encryption scheme: ${scheme}`);

  // Unpack nonce and MAC lengths.
  const nonceLength = packedBuffer.getByte();
  if (nonceLength !== IV_LENGTH) {
    throw Error(`unsupported nonce length: ${nonceLength}`)
  }
  const macLength = packedBuffer.getByte();
  if (macLength !== TAG_LENGTH) {
    throw Error(`unsupported tag length: ${macLength}`)
  }

  // Unpack key ID.
  let packedRemainder = packedBuffer.getBytes();
  let sepOffset = packedRemainder.indexOf(',');
  const keyName = packedRemainder.substring(0, sepOffset);
  packedRemainder = packedRemainder.substring(sepOffset + 1);

  sepOffset = packedRemainder.indexOf(',');
  const keyRev = packedRemainder.substring(0, sepOffset);

  const keyId = bytesToString(keyName) + ',' + keyRev;

  // Unpack ciphertext.
  const ciphertext = packedRemainder.substring(sepOffset + 1);

  // Return result.
  return {
    key_id: keyId,
    ciphertext,
  };
}
