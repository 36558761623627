import {connect} from "react-redux";
import {getDecrypted} from "../../features/e2ee/crypto-fragments";
import {getCase, needCase} from "../../features/entities/case";
import {loadLazily} from "packages/redux-helpers";
import {DecryptedText} from "../data/text";

export default connect(
  (state, {caseId, field}) => {
    const myCase = getDecrypted(getCase(caseId))(state);
    return {
      text: myCase && myCase[field],
    };
  },
)(loadLazily({
  text: {
    sourcePropNames: ['caseId'],
    loader: (dispatch, {caseId}) => caseId && dispatch(needCase({id: caseId})),
  },
})(DecryptedText));
