import mailReducers from './mail-slice';

export default {
  ...mailReducers,
};

export * from './mail-sagas';
export * from './mail-slice';

export {default as saga} from './mail-sagas';
