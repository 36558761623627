import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getUI} from "../ui-selectors";

const createVisibilityReducer = visible => (state, action) => {
  const {type, id, ...other} = action.payload;

  if (state[type] === undefined) {
    state[type] = {};
  }
  const viewById = state[type];

  if (viewById[id] === undefined) {
    viewById[id] = {};
  }
  const view = viewById[id];

  Object.assign(view, {
    ...other,
    visible,
  });
};

const viewSlice = createSlice({
  name: 'view',
  initialState: {},
  reducers: {
    showView: createVisibilityReducer(true),
    hideView: createVisibilityReducer(false),
    setViewData(state, action) {
      const {type, id, visible, ...data} = action.payload;

      if (state[type] === undefined) {
        state[type] = {};
      }
      const viewById = state[type];

      if (viewById[id] === undefined) {
        viewById[id] = {};
      }
      const view = viewById[id];

      Object.assign(view, data);
    }
  },
});

export const {showView, hideView, setViewData} = viewSlice.actions;

export default {
  view: viewSlice.reducer,
};

export const getViewsByTypeAndId = createSelector(
  getUI,
  ui => ui.view,
);

const defaultView = {visible: false};

export const getView = (type, id) => createSelector(
  getViewsByTypeAndId,
  views => views[type] && views[type][id] || defaultView,
);
