import {connect} from "react-redux";
import {getURL} from "../../../features/ui/navigation";
import {
  canViewInline,
  deleteDocument,
  getDocument,
  isDocumentRead,
  markDocumentRead,
  markDocumentUnread,
  replyToDocument,
  saveDocument,
  viewDocument
} from "../../../features/entities/document";
import {requireConfirmation} from "../../../features/ui/confirmation";
import DocumentRowMenu from "./document-row-menu-view";
import {canUpload} from "../../../features/entities/case";

export default connect(
  (state, {documentId}) => {
    const url = (name, args, kwargs) => getURL(name, args, kwargs)(state);

    const document = getDocument(documentId)(state);
    const caseId = document.case;
    return {
      menuType: 'row',
      canDelete: document.can_delete,
      isRead: isDocumentRead(documentId)(state),
      detailsURL: document.id ? url('document', {case: caseId, document: documentId}) : undefined,
      updateURL: document.can_update && url('document_update', {
        case: caseId,
        document: documentId
      }, {
        next: location.pathname,
      }),
      transferURL: document.can_transfer && url('document_transfer', {case: caseId, document: documentId}),
      canViewInline: canViewInline(documentId)(state),
      exists: document.id !== undefined,
      canReply: caseId !== undefined ? canUpload(caseId)(state) : false,
    };
  },
  (dispatch, {documentId, caseId}) => {
    return {
      markRead: () => dispatch(markDocumentRead({documentId, caseId})),
      markUnread: () => dispatch(markDocumentUnread({documentId, caseId})),

      save: () => dispatch(saveDocument({documentId, caseId})),
      view: () => dispatch(viewDocument({documentId, caseId})),

      reply: () => dispatch(replyToDocument({documentId})),

      performDeletion: () => dispatch(requireConfirmation({
        title: "Dokument wirklich löschen?",
        message: "Mit diesem Vorgang wird das ausgewählte Dokument unwiderruflich gelöscht.",
        confirmCaption: "Dokument löschen",
        color: 'secondary',
        confirmAction: deleteDocument({documentId, caseId}),
      })),
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    const {isRead, canDelete, canViewInline, exists, canReply} = stateProps;
    const {
      markRead,
      markUnread,
      view,
      reply,
      performDeletion,
      ...otherDispatchProps
    } = dispatchProps;

    return {
      ...ownProps,
      ...stateProps,

      markRead: (exists && !isRead) ? markRead : undefined,
      markUnread: (exists && isRead) ? markUnread : undefined,

      view: canViewInline ? view : undefined,
      reply: (exists && canReply) ? reply : undefined,

      performDeletion: canDelete ? performDeletion : undefined,

      ...otherDispatchProps,
    };
  },
)(DocumentRowMenu);
