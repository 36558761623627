import {createAction, createSelector, createSlice} from "@reduxjs/toolkit";

const mimeTypesSlice = createSlice({
  name: 'mimeTypes',
  initialState: {
    bySuffix: {},
  },
  reducers: {
    setMimeTypes(state, action) {
      const {results} = action.payload;
      for (const {suffix, type} of results) {
        state.bySuffix[suffix] = type;
      }
    },
  },
});

export const {setMimeTypes} = mimeTypesSlice.actions;

export const needMimeTypes = createAction('extras/needMimeTypes');
export const fetchMimeTypes = createAction('extras/fetchMimeTypes');

export default {
  mimeTypes: mimeTypesSlice.reducer,
};

export const getMimeTypesBySuffix = state => state.extras.mimeTypes.bySuffix;

export const hasFetchedMimeTypes = createSelector(
  getMimeTypesBySuffix,
  mimeTypesBySuffix => Object.keys(mimeTypesBySuffix).length !== 0,
);

/**
 * Returns true if the given mime type is too generic.
 * @param type mimetype
 * @returns boolean whether mime type is too generic / ambiguous
 */
export function isAmbiguousMimeType(type) {
  type = type.toLowerCase();
  return (
    type === 'application/octet-stream' ||
    type === 'x-type/x-subtype' ||
    type === 'application/x-download' ||
    type === 'application/download'
  );
}

/**
 * Resolves a suffix to an appropriate mime type based on the browser's navigator.mimeTypes property.
 * @param fileNameSuffix
 * @returns {string,undefined} matching mime type
 */
export function getMimeTypeBySuffixFromBrowser(fileNameSuffix) {
  for (const {type, suffixes} of navigator.mimeTypes) {
    for (const suffix of suffixes.split(',')) {
      if (suffix === fileNameSuffix) {
        return type;
      }
    }
  }
}

/**
 * Resolves a file name into its suffix.
 * @param fileName
 * @returns {string}
 */
export function getSuffixFromFileName(fileName) {
  if (Number.isNaN(fileName)) {
    return;
  }
  const extRegexp = fileName.match(/\.[a-z0-9]+$/);
  if (extRegexp) {
    return extRegexp[0].substring(1);
  }
}

export const getMimeTypeByFileName = fileName => createSelector(
  getMimeTypesBySuffix,
  mimeTypesBySuffix => {
    const fileNameSuffix = getSuffixFromFileName(fileName);
    if (!fileNameSuffix) {
      return;
    }

    // Try to determine mime type given browser's list.
    let type = getMimeTypeBySuffixFromBrowser(fileNameSuffix);
    if (type) {
      return type;
    }

    // Otherwise use our own database.
    return mimeTypesBySuffix[fileNameSuffix];
  },
);

export const determineMimeType = ({fileName, type}) => state =>
  !isAmbiguousMimeType(type) ? type : getMimeTypeByFileName(fileName)(state) || type;

export const canDetermineMimeType = ({fileName, type}) => state =>
  !isAmbiguousMimeType(type) || (getMimeTypeByFileName(fileName)(state) !== undefined);
