import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getUI} from "../ui-selectors";

const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState: null,
  reducers: {
    requireConfirmation(state, action) {
      const {title = "Bestätigung", message, confirmCaption = "OK", cancelCaption = "Abbrechen", confirmAction, ...other} = action.payload;
      return {title, message, confirmCaption, cancelCaption, ...other};
    },
    confirm(state, action) {
      return null;
    },
    cancel(state, action) {
      return null;
    },
  },
});

export const {requireConfirmation, confirm, cancel} = confirmationSlice.actions;

export default {
  confirmation: confirmationSlice.reducer,
};

export const getVisibleConfirmation = createSelector(
  getUI,
  ui => ui.confirmation,
);
