import {createAction, createSelector, createSlice} from "@reduxjs/toolkit";
import {getUI} from "../ui-selectors";

import memoize from "lodash.memoize";

const createSelectReducer = defaultSelection => (state, action) => {
  const {listingId, id} = action.payload;

  let select = defaultSelection;

  const selectedIdsByListing = state.selectedIdsByListing;
  let selectedIds = selectedIdsByListing[listingId];
  if (selectedIds === undefined) {
    selectedIdsByListing[listingId] = selectedIds = [];
  }

  if (select === undefined) {
    select = !selectedIds.includes(id);
  }

  if (select) {
    if (!selectedIds.includes(id)) {
      selectedIds.push(id);
    }
  } else {
    if (selectedIds.includes(id)) {
      selectedIdsByListing[listingId] = selectedIds = selectedIds.filter(existingId => existingId !== id);
    }
  }
};

const listingSlice = createSlice({
  name: 'listing',
  initialState: {
    byId: {},
    filteredIdsByFilter: {},
    selectedIdsByListing: {},
  },
  reducers: {
    selectResult: createSelectReducer(true),
    deselectResult: createSelectReducer(false),
    toggleResult: createSelectReducer(),
    unselectAll(state, action) {
      const {listingId} = action.payload;
      delete state.selectedIdsByListing[listingId];
    },
    bulkSelect(state, action) {
      const {listingId, ids} = action.payload;

      const selectedIdsByListing = state.selectedIdsByListing;
      let selectedIds = selectedIdsByListing[listingId];
      if (selectedIds === undefined) {
        selectedIdsByListing[listingId] = selectedIds = [];
      }


      for (const id of ids) {
        if (!selectedIds.includes(id) && id !== null && id !== undefined) {
          selectedIds.push(id);
        }
      }
    },
    bulkUnselect(state, action) {
      const {listingId, ids} = action.payload;

      const selectedIdsByListing = state.selectedIdsByListing;
      const selectedIds = selectedIdsByListing[listingId];
      if (selectedIds === undefined) {
        return;
      }

      selectedIdsByListing[listingId] = selectedIds.filter(id => !ids.includes(id));
    },
    updateListing(state, action) {
      const {id, ...listing} = action.payload;
      if (state.byId[id] === undefined) {
        state.byId[id] = {id};
      }
      const hasCounterChanged = (listing.count !== undefined && state.byId[id].count !== listing.count);
      Object.assign(state.byId[id], {error: undefined, ...listing});

      const currentListing = state.byId[id];
      currentListing.currentPageSize = Math.min(
        currentListing.pageSize,
        currentListing.count !== null ? (
          currentListing.count - (currentListing.currentPage - 1) * currentListing.pageSize
        ) : (
          currentListing.pageSize
        ),
      );

      if (hasCounterChanged) {
        // Delete all filtered IDs except for the ones currently shown (they might have become inconsistent).
        const filterId = getFilterIdFromListing(state.byId[id]);
        state.filteredIdsByFilter = {
          [filterId]: state.filteredIdsByFilter[filterId],
        };
      }
    },
    announceFilteredIdsExcerpt(state, action) {
      const {results = [], previous, next, filterId, offset, cursor} = action.payload;

      if (state.filteredIdsByFilter[filterId] === undefined) {
        state.filteredIdsByFilter[filterId] = [];
      }

      const filteredIds = state.filteredIdsByFilter[filterId];

      if (filteredIds.length === 0) {
        //console.log(`store received ids as is because I didn't know anything about them before`);
        filteredIds.push({
          results: results.map(result => result.id),
          previous,
          next,
          offset,
          cursor,
        });
        return;
      }

      if (cursor) {
        console.log(`integrating received ids into ${filterId} via cursor ${cursor}`);
        let insertPosition, actualOffset;
        filteredIds.forEach((excerpt, excerptPosition) => {
          if (excerpt.next === cursor) {
            insertPosition = excerptPosition + 1;
            actualOffset = excerpt.offset + excerpt.results.length;
            //console.log(`found insertion candidate after ${excerptPosition}, yielding offset ${actualOffset}`);
          } else if (excerpt.previous === cursor) {
            insertPosition = excerptPosition;
            actualOffset = excerpt.offset - results.length;
            //console.log(`found insertion candidate before ${excerptPosition}, yielding offset ${actualOffset}`);
          }
        });
        if (insertPosition !== undefined) {
          //console.log(`inserting at ${insertPosition}, yielding offset ${actualOffset}`);
          filteredIds.splice(insertPosition, 0, {
            results: results.map(result => result.id),
            previous,
            next,
            offset: actualOffset,
          });
          return;
        }
      }

      // TODO: This is just a test. Filling ids into the existing state must only happen if either:
      //   - received ids overlap with part of the known state (this is usually not the case when results are provided
      //     by page number), or
      //   - results were fetched via a cursor (the cursor serves as anchor)
      // for (const excerpt of filteredIds) {
      //   const excerptEndOffset = excerpt.offset + excerpt.results.length;
      //   if (offset === excerptEndOffset) {
      //     excerpt.results = [...excerpt.results, ...results.map(result => result.id)];
      //     excerpt.next = next;
      //     return;
      //   }
      // }

      console.log(`re-initialize ${filterId}`);

      state.filteredIdsByFilter[filterId] = [{
        results: results.map(result => result.id),
        previous,
        next,
        offset,
      }];

      //state.visibleDocumentIdsByCaseId[caseId] = results.reduce((acc, doc) => [...(acc || []), doc.id], []);
    },
  },
  extraReducers: {
    ['document/changeDocumentId']: (state, action) => {
      const {documentId: resultId, newDocumentId: newResultId} = action.payload;

      for (const selectedIds of Object.values(state.selectedIdsByListing)) {
        const index = selectedIds.indexOf(resultId);
        if (index !== -1) {
          selectedIds[index] = newResultId;
        }
      }
    },
  },
});

export const {updateListing, announceFilteredIdsExcerpt, selectResult, unselectResult, bulkSelect, bulkUnselect, toggleResult, unselectAll} = listingSlice.actions;

export const bulkListingAction = createAction('listing/bulkAction');

export default {
  listings: listingSlice.reducer,
};

export const getListings = createSelector(
  getUI,
  ui => ui.listings.byId,
);

const defaultListing = {};

export const getListing = id => createSelector(
  getListings,
  listings => listings[id] || defaultListing,
);

export const getFilterIdFromListing = listing => Object.entries({...listing.meta, ordering: listing.ordering}).map(
  ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
).join(',');

export const getFilterIdFromListingId = listingId => createSelector(
  getListing(listingId),
  getFilterIdFromListing,
);

const defaultSelectedIds = [];

export const getSelectedIdsByListingId = listingId => createSelector(
  getUI,
  ui => ui.listings.selectedIdsByListing[listingId] || defaultSelectedIds,
);

export const getIdsByFilter = createSelector(
  getUI,
  ui => ui.listings.filteredIdsByFilter,
);

const defaultIds = [];

export const getFilteredIds = memoize(filterId => createSelector(
  getIdsByFilter,
  idsByFilter => idsByFilter[filterId] || defaultIds,
));

export const getFilteredIdsByListingId = memoize(listingId => createSelector(
  getFilterIdFromListingId(listingId),
  getIdsByFilter,
  (filterId, idsByFilter) => idsByFilter[filterId] || defaultIds,
));

export const getNumberOfCachedIdsInListing = memoize(listingId => createSelector(
  getListing(listingId),
  getFilteredIdsByListingId(listingId),
  (listing, ids) => {
    let count = 0;
    for (const excerpt of ids) {
      count += excerpt.results.length;
    }
    return count;
  }
));

export const getVisibleIdsForListing = memoize(listingId => createSelector(
  getListing(listingId),
  getFilteredIdsByListingId(listingId),
  (listing, ids) => {
    const {currentPage, pageSize, currentPageSize} = listing;

    const startOffset = (currentPage - 1) * pageSize;
    const endOffset = startOffset + currentPageSize;

    const resultIds = [];
    let offset = startOffset;

    let reachedStart = false,
      reachedEnd = false;

    for (const excerptIds of ids) {
      const excerptStartOffset = excerptIds.offset;
      const excerptEndOffset = excerptStartOffset + excerptIds.results.length;

      if (excerptIds.previous === null) {
        reachedStart = true;
      }

      if (excerptIds.next === null) {
        reachedEnd = true;
      }

      if (excerptEndOffset <= offset) {
        // This excerpt lies outside the desired range, but further excerpts might lie inside.
        continue;
      }

      if (excerptStartOffset >= endOffset) {
        // This and all subsequent excerpts lie outside the desired range,
        break;
      }

      // Fill with null results if necessary.
      while (offset < excerptStartOffset) {
        resultIds.push(null);
        offset += 1;
      }

      let currentOffset = excerptStartOffset - 1;
      for (const id of excerptIds.results) {
        currentOffset += 1;

        if (offset !== currentOffset) {
          continue;
        }

        if (offset === startOffset) {
          reachedStart = true;
        }

        resultIds.push(id);

        offset += 1;

        if (offset === endOffset) {
          reachedEnd = true;
        }

        if (offset === endOffset) {
          break;
        }
      }
    }

    if (!reachedStart || !reachedEnd) {
      // Fill with null results.
      while (offset < endOffset) {
        resultIds.push(null);
        offset += 1;
      }
    }

    return resultIds;
  },
));

export const isResultSelected = listingId => id => createSelector(
  getSelectedIdsByListingId(listingId),
  selectedIds => selectedIds.includes(id),
)
