import {dragEnter, dragLeave} from "./drag-and-drop-slice";

export function addEventHandlers(store, options = {}) {
  let {target} = options;

  if (target === undefined) {
    target = window;
  }

  if (target === undefined) {
    console.warning("Could not add drag-and-drop event listeners.");
    return;
  }

  let drags = 0;

  target.addEventListener('dragenter', event => {
    drags += 1;
    if (drags === 1) {
      store.dispatch(dragEnter());
    }
  });
  target.addEventListener('dragleave', event => {
    drags -= 1;
    if (drags === 0) {
      store.dispatch(dragLeave());
    }
  });
  target.addEventListener('drop', event => {
    drags = 0;
    store.dispatch(dragLeave());
  });

  return store;
}
