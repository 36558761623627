import {connect} from "react-redux";
import React, {useState} from "react";
import {getDisplayName} from "./redux-utils";
import _ from 'lodash';

/**
 * Wrapper for react components that provides lazy-loading of props.
 * @param mapMissingPropsToHandlers Mapping of props to handlers that shall be executed if the respective props are
 *   missing.
 * @param omitProps List of props that shall not be passed through.
 */
export const loadLazily = (mapMissingPropsToHandlers, omitProps) => Component => {
  omitProps = omitProps || [];

  const WrappedComponent = ({dispatch, ...props}) => {
    const passThroughProps = _.omit(props, ...omitProps);

    const [processedMissingProps, setProcessedMissingProps] = useState({});

    for (const [propName, missingPropHandler] of Object.entries(mapMissingPropsToHandlers)) {
      const {
        isMissing = prop => prop === undefined,
        loader,
        onlyOnce = false,  // Trigger loader at most once during the component's lifetime (i.e., do not monitor props for changes).
        precondition = () => true,
        sourcePropNames,
      } = missingPropHandler;

      // Do not do anything unless the props meet the precondition.
      if (!precondition(props)) {
        continue;
      }

      // Do not do anything for props that are not missing.
      if (!isMissing(props[propName])) {
        continue;
      }

      // Determine props on which lazy loading shall depend.
      const sourceProps = (sourcePropNames || []).reduce(
        (sourceProps, sourcePropName) => ({...sourceProps, [sourcePropName]: props[sourcePropName]}),
        {},
      );

      // The missing prop's loader must only be called once.
      const previous = processedMissingProps[propName] || {};
      if (previous.missingPropHandler === missingPropHandler) {
        if (onlyOnce) {
          // Loader has been called before, skip.
          continue;
        }

        // Check if any source prop has changed.
        let changed = false;
        for (const [sourcePropName, sourcePropVal] of Object.entries(sourceProps)) {
          if (previous.sourceProps[sourcePropName] !== sourcePropVal) {
            changed = true;
            break;
          }
        }

        if (!changed) {
          // Loader has been called before with identical source props, skip.
          continue;
        }
      }

      // Call missing props handler.
      processedMissingProps[propName] = {missingPropHandler, sourceProps};
      setProcessedMissingProps(processedMissingProps);

      // The actual call needs to be asynchronous as to not affect renderings that are in process.
      setTimeout(() => loader(dispatch, sourceProps));
    }

    return <Component {...passThroughProps} />;
  };

  WrappedComponent.displayName = `loadLazily(${getDisplayName(Component)})`;

  // Component.whyDidYouRender = true;

  return connect()(WrappedComponent);
};
