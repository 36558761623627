import {crypto_manager} from "../../../components/mavo-crypto";
import {decrypt} from "./crypto-fragments-slice";
import {bytesToBase64} from "../../../packages/binary-data-helpers";

import uuidv4 from 'uuid/v4';

export function preprocess(data, dispatch) {
  if (typeof data !== 'object') {
    return data;
  }

  if (data === null || Object.entries(data).length === 0) {
    return data;
  }

  if (data.cryptoFragmentId) {
    return data;
  }

  if (data.encrypted_string) {
    const annotatedData = {
      ...data,
      cryptoFragmentId: uuidv4(),
    };

    dispatch(decrypt(annotatedData));

    return annotatedData;
  }

  if (Array.isArray(data)) {
    return data.map(v => preprocess(v, dispatch));
  }

  return Object.assign(...Object.entries(data).map(([k, v]) => ({
    [k]: preprocess(v, dispatch)
  })));
}

export function postprocess(data, decryptedFragments) {
  if (typeof data !== 'object') {
    return {result: data};
  }

  if (data === null || Object.entries(data).length === 0) {
    return {result: data};
  }

  if (data.cryptoFragmentId) {
    return {result: decryptedFragments[data.cryptoFragmentId], changed: true};
  }

  let changed = false;
  const resultEntries = Object.entries(data).map(([k, v]) => {
    const processed = postprocess(v, decryptedFragments);
    if (processed.changed) {
      changed = true;
    }
    return {
      [k]: processed.result
    };
  });

  // Return original object unless we have changed anything.
  if (!changed) {
    return {result: data};
  }

  return {result: Object.assign(...resultEntries), changed: true};
}

export function encrypt(data, keyId) {
  if (data === null || data === '') {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(v => encrypt(v, keyId));
  }

  if (typeof data === 'object') {
    return Object.assign(...Object.entries(data).map(([k, v]) => ({
      [k]: encrypt(v, keyId)
    })));
  }

  return {
    encrypted_string: {
      key_id: keyId,
      data: bytesToBase64(crypto_manager.encrypt_string(keyId, data)),
      cipher: {
        algorithm: 'AES',
        mode: 'GCM',
        tag_length: 16,
        nonce_length: 12
      }
    }
  };
}
