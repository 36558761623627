import {createDocumentDraft, modifyDocumentDraft, setDocumentBlob, uploadDocument} from "../features/entities/document";
import {convertToBlob} from "../utils/file-transfer";

export const createDocumentDraftFromFile = ({file, blob, caseId, startUpload = false}, dispatch) => {
  // Convert provided file or blob into a blob that is safe for upload.
  const blobPromise = convertToBlob(file || blob);

  // Create document draft asynchronously.
  let promise = new Promise((resolve, reject) => {
    // Create empty document draft.
    const action = dispatch(createDocumentDraft());
    const draftId = action.payload.documentId;

    // Add file-related information.
    const metadata = file || blob;
    dispatch(modifyDocumentDraft({
      documentId: draftId,
      name: metadata.name,
      description: metadata.description,
      type: metadata.type,
      charset: metadata.charset,
      size: metadata.size,
      case: caseId,
    }));

    // Yield blob and draftId.
    blobPromise.then(blob => resolve({draftId, blob}));
  }).then(({draftId, blob}) => {
    // Dispatch blob to document saga.
    dispatch(setDocumentBlob({
      documentId: draftId,
      blob,
    }));

    return draftId;
  });

  if (startUpload) {
    promise = promise.then(draftId => {
      dispatch(uploadDocument({documentId: draftId}));
      return draftId;
    });
  }

  return promise;
};

export const createDocumentDraftsFromFiles = ({files = [], blobs = [], ...other}, dispatch) => {
  return Promise.all([
    ...([...files].map(file => createDocumentDraftFromFile({file, ...other}, dispatch))),
    ...([...blobs].map(blob => createDocumentDraftFromFile({blob, ...other}, dispatch))),
  ]);
};
