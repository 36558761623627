import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getUI} from "../ui-selectors";

const dropzoneSlice = createSlice({
  name: 'dropzone',
  initialState: {
    visibleForCaseIds: [],
  },
  reducers: {
    showUploadBox(state, action) {
      const {caseId} = action.payload;
      state.visibleForCaseIds.push(caseId);
    },
    hideUploadBox(state, action) {
      const {caseId} = action.payload;
      state.visibleForCaseIds = state.visibleForCaseIds.filter(id => id !== caseId);
    },
  },
});

export const {
  showUploadBox,
  hideUploadBox,
} = dropzoneSlice.actions;

export default {
  dropzone: dropzoneSlice.reducer,
};

const getDropZone = createSelector(
  getUI,
  ui => ui.dropzone,
);

const getCaseIdsWithVisibleUploadBoxes = createSelector(
  getDropZone,
  dropzone => dropzone.visibleForCaseIds,
);

export const isUploadBoxVisibleForCase = caseId => createSelector(
  getCaseIdsWithVisibleUploadBoxes,
  caseIds => caseIds.includes(caseId),
);
