import React from "react";
import ErrorMessage from "./document-error-view";
import UpdateDescription from "./update-description-view";

export default class DocumentDescription extends React.Component {
  state = {
    edit: false,
    draftDescription: undefined,
  };

  showEdit = event => {
    event.preventDefault();
    this.setState({edit: true});
  };

  changeDescription = draftDescription => {
    this.setState({draftDescription});
  };

  saveDescription = () => {
    const description = this.state.draftDescription !== undefined ? this.state.draftDescription : this.props.description;
    if (description !== this.props.description) {
      this.props.onSetDescription(description);
    }
    this.setState({edit: false});
  };

  render = () => {
    const {description, canUpdate, pendingUpdate, error} = this.props;
    const {edit, draftDescription} = this.state;

    if (!description && !canUpdate)
      return <></>;

    if (edit || error) {
      return <>
        <UpdateDescription description={draftDescription !== undefined ? draftDescription : description} onChange={this.changeDescription} onSave={this.saveDescription}/>
        {error && <ErrorMessage error={error} onClick={evt => evt.stopPropagation()}/>}
      </>;
    }

    return <>
      {canUpdate && !description && !pendingUpdate && (
        <small onClick={evt => evt.stopPropagation()}>
          <a href="#" onClick={this.showEdit} className="clickable-exclude hover-only">Beschreibung hinzufügen...</a>
        </small>
      )}
      {(description || pendingUpdate) && <>
        <br/>
        <div style={{marginTop: '3px'}}>
          <small onClick={evt => evt.stopPropagation()}>
            {(pendingUpdate ? "(Beschreibung wird gespeichert...)" :
              <span className='document-description'>{description}</span>)}
            {canUpdate && (<>
              &nbsp;<a href="#" onClick={this.showEdit} className="clickable-exclude hover-only">Bearbeiten</a>
            </>)}
          </small>
        </div>
      </>}
      {error && <ErrorMessage error={error} onClick={evt => evt.stopPropagation()}/>}
    </>;
  };
}
