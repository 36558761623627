import React from 'react';

import {connect} from "react-redux";
import {canUpload} from "../../features/entities/case";
import {isUploadBoxVisibleForCase, showUploadBox} from "../../features/ui/dropzone";
import UploadDocumentsButton from "./upload-documents-button-view";

export default connect(
  (state, {caseId}) => {
    const isVisible = !isUploadBoxVisibleForCase(caseId)(state);
    return {
      isVisible: isVisible && canUpload(caseId)(state),
    };
  },
  (dispatch, {caseId}) => {
    return {
      showDropzone: () => dispatch(showUploadBox({caseId})),
    };
  },
)(UploadDocumentsButton);
