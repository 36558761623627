import React from "react";

export default ({progress, total = 1, message = ''}) => {
  if (progress === undefined) {
    return null;
  }

  const percent = (total > 0) ? 100 * progress / total : 0;

  return (
    <div className="progress" style={{
      background: 'gray',
      position: 'absolute',
      left: '5px',
      right: '5px',
      top: '9px'
    }}>
      <div className="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax={total} style={{width: Math.round(percent) + '%'}}>
        <span className="text-left nowrap">{message} {Math.round(percent)}%</span>
      </div>
    </div>
  );
};
