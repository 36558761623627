import React from 'react';
import ReactDOM from 'react-dom';

import {forEachMatchingElement, uimanager} from 'ui-manager';
import {store} from "../stores";
import {Provider} from "react-redux";
import {theme} from "../ui/theme";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {TextDocumentWriter} from "../components/document/writer";
import {viewDocument} from "../features/entities/document";
import {updateListing} from "../features/ui/listing";
import {
  DocumentContent,
  DocumentDownloadButton,
  DocumentProgress,
  DocumentsTable,
  DocumentStatus,
  RecentlyUploadedDocumentsTable,
  UploadDocumentsButton
} from "../components/document";
import {Pagination} from "../components/paginator";
import {BulkUploaderDocuments} from "../components/document/bulk-uploader";
import {CaseUploadSummary} from "../components/case";
import BulkUploaderSummary from "../components/document/bulk-uploader/bulk-uploader-summary-redux";
import {createDocumentDraftsFromFiles} from "../events/document";
import BulkUploaderCaseNumber from "../components/document/bulk-uploader/bulk-uploader-case-number-redux";
import {DocumentDetailsMenu} from "../components/document/menu";

uimanager.add(el =>
  forEachMatchingElement(el, "div[data-document-details-menu]", node => {
    const {document_id, case_id} = JSON.parse(node.dataset.documentDetailsMenu);

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <DocumentDetailsMenu documentId={document_id} caseId={case_id}/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "div[data-document-download-button]", node => {
    const {document_id} = JSON.parse(node.dataset.documentDownloadButton);

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <DocumentDownloadButton documentId={document_id}/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-document-status]", node => {
    const {document_id} = JSON.parse(node.dataset.documentStatus);

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <DocumentStatus documentId={document_id}/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-document-content]", node => {
    const {document_id} = JSON.parse(node.dataset.documentContent);

    store.dispatch(viewDocument({documentId: document_id}));

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <DocumentContent documentId={document_id}/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-document-progress]", node => {
    const {document_id} = JSON.parse(node.dataset.documentProgress);

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <DocumentProgress documentId={document_id}/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-listing]", node => {
    const {id, currentPage, count, ...other} = JSON.parse(node.dataset.listing);
    const {caseId} = other;

    if (currentPage !== undefined) {
      store.dispatch([
        updateListing({
          id,
          meta: other,
          count,
          currentPage,
          ordering: ['-creation_date'],
          pageSize: 30,
          searchQuery: '',
        }),
      ]);
    }

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <CaseUploadSummary caseId={caseId} listingId={id}/>
          <DocumentsTable {...other} listingId={id}/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-bulk-uploader]", node => {
    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <BulkUploaderDocuments/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-bulk-uploader-summary]", node => {
    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <BulkUploaderSummary/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-bulk-uploader-case-number]", node => {
    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <BulkUploaderCaseNumber/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-pagination]", node => {
    const listingId = node.dataset.pagination;

    const listItems = [...node.querySelectorAll('ul li')];
    const existingContent = node.querySelector('ul');

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Pagination listingId={listingId} existingListItems={listItems} existingContent={existingContent}/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-recently-uploaded-documents-table]", node => {
    const caseId = node.dataset.recentlyUploadedDocumentsTable;

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <RecentlyUploadedDocumentsTable caseId={caseId}/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-upload-documents-button]", node => {
    const caseId = node.dataset.uploadDocumentsButton;

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <UploadDocumentsButton caseId={caseId}/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-document-message-writer]", node => {
    const {case_id} = JSON.parse(node.dataset.documentMessageWriter);

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <TextDocumentWriter caseId={case_id}/>
        </MuiThemeProvider>
      </Provider>,
      node
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-allow-document-upload]", node => {
    if (node.processed) {
      return;
    }
    node.processed = true;

    const {caseId} = JSON.parse(node.dataset.allowDocumentUpload);
    node.addEventListener('drop', evt => {
      const files = evt.dataTransfer.files;
      createDocumentDraftsFromFiles({files, caseId, startUpload: true}, store.dispatch).then(() => {
      });
    });
  })
);
