import {createSelector, createSlice} from "@reduxjs/toolkit";

const sessionSlice = createSlice({
  name: 'session',
  initialState: {},
  reducers: {
    setSession(state, action) {
      return action.payload;
    },
  },
});

export const {setSession} = sessionSlice.actions;

export default {
  session: sessionSlice.reducer,
};

export const getSession = state => state.session;

export const getSessionUser = createSelector(
  getSession,
  session => session.user,
);
