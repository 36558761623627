import Pagination from './pagination-view';
import {getListing, updateListing} from "../../features/ui/listing";
import {connect} from "react-redux";

export default connect(
  (state, {listingId}) => {
    const {currentPage, count, pageSize, error} = getListing(listingId)(state);
    const totalPages = Math.ceil(count / pageSize);

    return {
      currentPage,
      totalPages,
      error,
    };
  },
  (dispatch, {listingId}) => {
    return {
      setPage: page => dispatch(updateListing({id: listingId, currentPage: page})),
    };
  },
)(Pagination);
