import React from "react";
import {connect} from "react-redux";
import {getProgress} from "../../features/ui/progress";
import {deleteDocument} from "../../features/entities/document";
import DocumentProgress from "./document-progress-view";

const ENCRYPTION_PROGRESS_PART = 0.2;

export default connect(
  (state, {documentId, name}) => {
    const downloadProgress = getProgress('downloadDocument', documentId)(state),
      uploadProgress = getProgress('uploadDocument', documentId)(state),
      encryptionProgress = getProgress('encryptDocument', documentId)(state),
      decryptionProgress = getProgress('decryptDocument', documentId)(state),
      deletionProgress = getProgress(deleteDocument.type, documentId)(state);

    let message = name;
    if (message === undefined || Number.isNaN(message)) {
      message = 'Dokument';
    }

    if (downloadProgress.inProgress || decryptionProgress.inProgress
      || (downloadProgress.success && !decryptionProgress.started)
    ) {
      // Show progress bar only if download or decryption is in progress.
      const documentSize = downloadProgress.total || decryptionProgress.total;
      const total = 5 * documentSize;
      const progress = 4 * downloadProgress.progress + decryptionProgress.progress;

      if (downloadProgress.inProgress && decryptionProgress.inProgress && decryptionProgress.progress > 0) {
        message += ' wird heruntergeladen und entschlüsselt...';
      } else if (downloadProgress.inProgress) {
        message += ' wird heruntergeladen...';
      } else {
        message += ' wird entschlüsselt...';
      }

      return {
        message,
        progress,
        total,
      };
    } else if (uploadProgress.inProgress || encryptionProgress.inProgress
      || (encryptionProgress.success && !uploadProgress.started)
    ) {
      const relativeEncryptionProgress = encryptionProgress.total === 0 ? 1 : encryptionProgress.progress / encryptionProgress.total;
      const relativeUploadProgress = uploadProgress.total ? uploadProgress.progress / uploadProgress.total : 0;

      // Show progress bar only if upload or encryption is in progress.
      const total = Math.max(encryptionProgress.total, uploadProgress.total || 0) / ENCRYPTION_PROGRESS_PART;
      const progress = total * (
        ENCRYPTION_PROGRESS_PART * relativeEncryptionProgress
        + (1 - ENCRYPTION_PROGRESS_PART) * relativeUploadProgress
      );

      if (encryptionProgress.inProgress && uploadProgress.inProgress && uploadProgress.total > 0) {
        message += ' wird verschlüsselt und hochgeladen...';
      } else if (encryptionProgress.inProgress) {
        message += ' wird verschlüsselt...';
      } else {
        message += ' wird hochgeladen...';
      }

      return {
        message,
        progress,
        total,
      };
    } else if (deletionProgress.inProgress) {
      const progress = 0, total = 1;

      return {
        message: deletionProgress.inProgress ? message + ' wird gelöscht...' : null,
        progress,
        total,
      };
    } else {
      return {};
    }
  },
)(DocumentProgress);
