import {Checkbox} from "@material-ui/core";
import React from "react";

export default ({className, onSelect, onUnselect, isSelected, isPartiallySelected}) => (
  <div style={{marginLeft: -2, marginTop: -3, marginBottom: -3, marginRight: -7}} className={className}>
    <Checkbox
      checked={isSelected}
      indeterminate={isSelected && isPartiallySelected}
      onChange={isSelected ? onUnselect : onSelect}
      color="primary"
    />
  </div>
);
