import {connect} from "react-redux";
import {getView, hideView} from "../../../features/ui/view";
import TextDocumentWriter from "./text-document-writer-view";
import {getSessionUser} from "../../../features/session";
import {getMessageWriterData} from "../../../features/ui/message-writer";
import {messageWriterInputChanged} from "../../../features/ui/message-writer";

export default connect(
  (state, {}) => {
    const view = getView('writeDocument', '')(state);
    const messageWriterData = getMessageWriterData(view.messageId)(state);
    return {
      ...messageWriterData,
      documentUploaderId: getSessionUser(state),
      open: view.visible,
      messageId: view.messageId,
    };
  },
  (dispatch) => {
    return {
      onClose: () => dispatch(hideView({type: 'writeDocument', id: ''})),
      onSetMessage: (messageId, message) => dispatch(messageWriterInputChanged(messageId, 'message', message)),
      onSetDescription: (messageId, description) => dispatch(messageWriterInputChanged(messageId, 'description', description)),
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    const {messageId} = stateProps;
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      onSetMessage: message => dispatchProps.onSetMessage(messageId, message),
      onSetDescription: message => dispatchProps.onSetDescription(messageId, message),
    };
  }
)(TextDocumentWriter);
