import confirmationReducers from './confirmation-slice';

export default {
  ...confirmationReducers,
};

export * from './confirmation-sagas';
export * from './confirmation-slice';

export {default as saga} from './confirmation-sagas';
